import React, {useEffect, useContext, useState, useMemo, useRef} from 'react'
import {useForm, Controller} from 'react-hook-form'
import moment from 'moment'
import CodigoQr from '../../imagenes/codigoQR.png'
import {
  TEXTO_VISUAL,
  regexSoloLetrasYNumeros,
  NUMERO_CARACTERES_DOCUMENTO,
  ALERTAS,
} from '../../constantes'
import {Input, ListaDesplegable, ErroresInput} from '../inputs'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {ContextApplication, ContextoAsignarTurno} from '../../contexto'
import {BotonSimple} from '../Botones'
import {
  ObtenerGruposSanguineos,
  ObtenerGrupoSanguineoPorNombre,
  ObtenerTiposDeDocumento,
  ObtenerSexos,
  ObtenerIndicativos,
  ObtenerTiposDeTurno,
  ObtenerEmpresaPorId,
  AgregarEmpresasACache,
  FiltrarCiudadPorNombre,
  ObtenerDepartamentoPorId,
  ObtenerServicioPorId,
  ObtenerPaquetePorId,
} from '../../cache/servicios-cache'
import {
  ObtenerUsuarioPorTipoDocumentoYDocumento,
  ObtenerOrdenAbiertaPorUsuarioId,
  ObtenerOrdenesAsignadasPorUsuarioIdEstadoId,
} from '../../microservicios'
import {
  regexBasicoV1,
  regexTextoCaracteresBasicos,
} from '../../constantes/regex'
import {AlertaDinamica, AlertaLongitudDocumento} from '../MostrarInformacion'
import {obtenerFechaMinima} from '../../utilidades'
import clsx from 'clsx'
import {EncabezadoFondo, TablaConPaginacionYFiltros} from '../Tablas'
import {CeldaConTooltipPersonalizable} from '../Celdas'
import SignoAdmiracion from '../../imagenes/iconoAdmiracion.svg'

const FormularioAsignarTurno = ({manejarGuardar, resetearFormulario}) => {
  const {
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const fechaEsValida = useRef(false)
  const fechaDeHoy = moment().format().slice(0, -15)
  const contextoAplicacion = useContext(ContextApplication)
  const contextoAsignarTurno = useContext(ContextoAsignarTurno)

  const [tipoDocumento, setTipoDocumento] = useState([])
  const [tipoGrupoSanguineo, setTipoGrupoSanquineo] = useState([])
  const [tipoSexos, setTipoSexos] = useState([])
  const [tiposTurno, setTiposTurno] = useState([])
  const [indicativos, setIndicativos] = useState([])
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([])

  const [nombres, setNombres] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [empresaTurno, setEmpresaTurno] = useState(null)

  const [infoNumeroCaracteres, setInfoNumeroCaracteres] = useState({
    minimo: 6,
    maximo: 10,
    mensaje: '',
    tipoCampo: 'text',
  })

  const deshabilitarGuardar = useMemo(() => {
    return empresaTurno !== null && !empresaTurno?.EstaActiva
  }, [empresaTurno])

  const [opcionesPais, setOpcionesPais] = useState([])

  const countryCodes = require('country-codes-list')

  const codigoDePaises = countryCodes.customList(
    'countryCode',
    '+{countryCallingCode}'
  )
  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  useEffect(() => {
    if (getValues('fechaNacimiento')) {
      const fechaNacimiento = moment(getValues('fechaNacimiento'))
      const fechaMinima = moment()
        .subtract(anioFechMinima, 'years')
        .startOf('day')
        .subtract(1, 'day')
      fechaEsValida.current = !fechaNacimiento.isAfter(fechaMinima)
    }
  }, [getValues('fechaNacimiento'), fechaEsValida.current])

  const [paisesArray, setPaisesArray] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setOpcionesPais(codigoDePaises)
    }

    fetchData()
  }, [])

  const anioFechMinima = 99

  const valoresFormularioPorDefecto = {
    numeroDocumento: '',
    tipoDocumento: '',
    apellidos: '',
    nombre: '',
    grupoSanguineo: '',
    fechaNacimiento: '',
    lugarNacimiento: '',
    sexo: '',
    indicativoCelular: '+57',
    celular: '',
    nacionalidad: '',
    tipoTurno: 1,
  }

  const eliminarDatosLectorDeFirmas = () => {
    const formulario = document.getElementById('cargarDatosFormularioConLector')
    const inputs = formulario.querySelectorAll('input')
    inputs.forEach((input) => {
      input.value = ''
    })
  }
  const reestablecerFormulario = async () => {
    contextoAsignarTurno.setUsuarioId('')
    reset(valoresFormularioPorDefecto)
    setNombres('')
    setApellidos('')
    eliminarDatosLectorDeFirmas()
    await AgregarEmpresasACache()
    trigger()
  }

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
    reset(valoresFormularioPorDefecto)
    contextoAsignarTurno.setUsuarioId(0)
    trigger()
  }

  useEffect(() => {
    reestablecerFormulario()
  }, [resetearFormulario])

  useEffect(() => {
    const nuevoArray = []

    const nombresUnicos = [...new Set(Object.values(opcionesPais))]

    nombresUnicos.sort()

    nombresUnicos.forEach((nombre) => {
      const id = Object.keys(opcionesPais).find(
        (key) => opcionesPais[key] === nombre
      )
      nuevoArray.push({
        Id: opcionesPais[id],
        Nombre: nombre,
      })
    })

    setPaisesArray(nuevoArray)
  }, [opcionesPais])

  useEffect(() => {
    trigger()
  }, [infoNumeroCaracteres])

  useEffect(() => {
    const subscription = watch(() => {
      contextoAsignarTurno.setFormularioAsignarTurno(getValues())
    })
    return () => subscription.unsubscribe()
  }, [isValid, watch])

  useEffect(() => {
    reset(contextoAsignarTurno.formularioAsignarTurno)
    trigger()
  }, [contextoAsignarTurno.actualizar])

  useEffect(() => {
    ;(async () => {
      setTipoDocumento(await ObtenerTiposDeDocumento())
      setTipoGrupoSanquineo(await ObtenerGruposSanguineos())
      setTipoSexos(await ObtenerSexos())
      setTiposTurno(await ObtenerTiposDeTurno())
      setIndicativos(await ObtenerIndicativos())

      document?.getElementById('primerInput')?.focus()
      setValue('indicativoCelular', '+57')
      setValue('tipoTurno', 1)
    })()
  }, [])

  const columnsOrdenesDisponibles = [
    {
      Header: (row) => <EncabezadoFondo titulo={'N° DE ORDEN'} />,
      accessor: 'id',
      className: 'w-1/4',
      cellStyles:
        'flex justify-center items-center h-12 text-14px text-cendiatra-gris-1',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-l-lg',
      disableFilters: true,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'FECHA DE VENCIMIENTO'} />,
      accessor: 'fechaVencimiento',
      className: 'w-1/4',
      cellStyles:
        'flex justify-center items-center h-12 text-14px text-cendiatra-gris-1',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
      disableFilters: true,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'EMPRESA'} />,
      accessor: 'nombreEmpresa',
      className: 'w-1/4',
      cellStyles:
        'flex justify-center items-center h-12 text-14px text-cendiatra-gris-1 w-10',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14',
      disableFilters: true,
      Cell: (row) => (
        <CeldaConTooltipPersonalizable
          texto={row.row.original.nombreEmpresa}
          estilosPersonalizados={'text-14px bg-cendiatra text-white '}
          anchoMaximo={'95%'}
          align={'w-full relative justify-center'}
          estilosTexto={'w-full'}
        />
      ),
    },
    {
      id: 'CeldaConBoton',
      className: 'w-1/4',
      cellStyles:
        'flex justify-center items-center h-12 text-14px text-cendiatra-gris-1',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-14 rounded-r-lg',
      disableFilters: true,
      Cell: (row) =>
        !row.row.original.estaActiva || row.row.original.ordenModificada ? (
          <div className="w-9/12 flex justify-center items-center">
            <div className="w-11/12">
              <AlertaDinamica
                mostrarAlerta={
                  !row.row.original.estaActiva ||
                  row.row.original.ordenModificada
                }
                mensaje={
                  row.row.original.ordenModificada
                    ? 'La orden se afectó por actualización del tarifario.'
                    : `Empresa inactiva por: ${row.row.original.motivoDesactivacion}`
                }
                color={'normal-case text-cendiatra-rojo-1 text-left'}
                estilosTexto={'w-10/12 ml-2 text-left'}
              />
            </div>
            {row.row.original.ordenModificada ? (
              <button
                className="w-1/12"
                type={'button'}
                onClick={() =>
                  mostrarServiciosOPaquetesRetirados(row.row.original)
                }
              >
                <img src={SignoAdmiracion} alt="signoAdmiracion" />
              </button>
            ) : null}
          </div>
        ) : null,
    },
  ]

  const consultarOrdenesPorPaciente = async (datos) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <>
          <span className="font-bold text-3xl ">
            EL PACIENTE TIENE ORDENES ASIGNADAS
          </span>
        </>
      ),
      contenido: (
        <div className="w-full flex justify-center items-center mx-10 flex-wrap">
          <TablaConPaginacionYFiltros
            data={datos}
            columns={columnsOrdenesDisponibles}
            estilosTablaDatos={'overflow-y-auto bg-white rounded-lg h-350'}
            usarSort={false}
          />
          <div className="w-full flex justify-center items-center my-10">
            <BotonSimple
              texto={'Regresar'}
              estilosBoton={
                'text-lg text-cendiatra w-32 h-10 bg-center bg-cover bg-no-repeat rounded-lg mx-3'
              }
              funcion={() => {
                manejaCerrarModal()
                reestablecerFormulario()
              }}
            />
            <BotonSimple
              texto={'Continuar'}
              estilosBoton={
                'text-lg text-white w-auto px-5 h-10 bg-btnBg bg-center bg-cover bg-no-repeat rounded-lg mx-3'
              }
              funcion={() => manejaCerrarModal()}
            />
          </div>
        </div>
      ),
      botones: [],
      clasesAdicioneles: 'w-9/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
  }

  const mostrarServiciosOPaquetesRetirados = async (datosOrden) => {
    const nombreServicios = await Promise.all(
      datosOrden.serviciosEliminados.map(async (servicioId) => {
        const datosServicio = await ObtenerServicioPorId(servicioId)

        return datosServicio.Nombre
      })
    )
    const nombrePaquetes = await Promise.all(
      datosOrden.paquetesEliminados.map(async (paqueteId) => {
        const datosPaquete = await ObtenerPaquetePorId(paqueteId)

        return datosPaquete.Nombre
      })
    )
    const paquetesOServiciosEliminados = nombreServicios.concat(nombrePaquetes)

    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <span className="text-cendiatra-rojo-1 w-full text-2xl font-bold mb-5">
            {' '}
            LA ORDEN SE AFECTÓ
          </span>
          <span className="text-cendiatra-rojo-1 w-full text-2xl font-bold mb-5">
            {' '}
            POR ACTUALIZACIÓN DEL TARIFARIO.
          </span>
          <div className="w-full text-cendiatra text-lg uppercase mb-5 font-bold">
            <span>SERVICIOS Y/O PAQUETES ELIMINADOS DEL TARIFARIO</span>
          </div>
          <div className="w-full flex flex-wrap justify-start items-center text-cendiatra uppercase mb-5">
            <div className="w-1/5"></div>
            <div className="w-3/5 flex flex-wrap justify-start text-start">
              {paquetesOServiciosEliminados.map((elemento) => (
                <span className="w-full text-left my-1">-{elemento}</span>
              ))}
            </div>
            <div className="w-1/5"></div>
          </div>
          <div className="w-full text-cendiatra-rojo-1 text-lg normal-case mb-5 ">
            <span>
              La orden no podra ser cargada, comuníquese con la empresa
            </span>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles: 'w-1/3 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5',
    })
    return
  }

  useEffect(() => {
    if (
      contextoAsignarTurno.formularioAsignarTurno.numeroDocumento.length >= 4 &&
      contextoAsignarTurno.formularioAsignarTurno.tipoDocumento
    ) {
      trigger()
      setEmpresaTurno(null)
      ObtenerUsuarioPorTipoDocumentoYDocumento(
        contextoAsignarTurno.formularioAsignarTurno.numeroDocumento,
        contextoAsignarTurno.formularioAsignarTurno.tipoDocumento
      )
        .then(async (res) => {
          if (res.status === 200) {
            const municipio = res.data.lugarNacimiento
              ? await FiltrarCiudadPorNombre(
                  res.data.lugarNacimiento.split(',')[0]
                )
              : ''
            const departamento =
              municipio.length > 0
                ? await ObtenerDepartamentoPorId(municipio[0]?.DepartamentoId)
                : ''
            contextoAsignarTurno.setFormularioAsignarTurno({
              ...contextoAsignarTurno.formularioAsignarTurno,
              numeroDocumento: res.data.numeroDocumento,
              tipoDocumento: res.data.tipoDocumento,
              apellidos: res.data.apellidos,
              nombre: res.data.nombres,
              grupoSanguineo: res.data.grupoSanguineoId,
              fechaNacimiento: res.data.fechaNacimiento
                ? moment(res.data.fechaNacimiento).format('YYYY-MM-DD')
                : null,
              sexo: res.data.sexoId,
              estadoCivil: res.data.estadoCivilId,
              ...(!!departamento &&
                municipio.length > 0 && {
                  lugarNacimiento:
                    res.data.lugarNacimiento.split(',')[0] +
                    ', ' +
                    departamento.Nombre,
                }),
              escolaridad: res.data.escolaridadId,
              celular: res.data.celular,
              indicativoCelular: res.data.indicativo,
            })
            contextoAsignarTurno.setUsuarioId(res.data.id)
            contextoAsignarTurno.setActualizar(!contextoAsignarTurno.actualizar)
          }
          trigger()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [
    contextoAsignarTurno.formularioAsignarTurno.numeroDocumento,
    contextoAsignarTurno.formularioAsignarTurno.tipoDocumento,
  ])

  useEffect(() => {
    if (contextoAsignarTurno.usuarioId) {
      ;(async () => {
        try {
          const res = await ObtenerOrdenesAsignadasPorUsuarioIdEstadoId(
            contextoAsignarTurno.usuarioId
          )
          if (res.status === 200) {
            const datosOrdenes = await Promise.all(
              res.data.map(async (orden) => {
                const datosEmpresa = await ObtenerEmpresaPorId(orden.empresaId)
                return {
                  ...orden,
                  estaActiva: datosEmpresa.EstaActiva,
                  motivoDesactivacion: datosEmpresa.MotivoDesactivacion,
                  ordenModificada: Boolean(
                    orden.paquetesEliminados.length ||
                      orden.serviciosEliminados.length
                  ),
                }
              })
            )
            consultarOrdenesPorPaciente(datosOrdenes)
          }
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [contextoAsignarTurno.usuarioId])

  const manejarCerrarModal = (formularioPorDefecto) => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    if (formularioPorDefecto) {
      reestablecerFormulario()
    }
  }

  const manejarLectorDeCodigo = async (e, tabIndex) => {
    contextoAplicacion.setIsLoading(true)
    if (tabIndex === 2) {
      setApellidos(e.target.value)
      contextoAsignarTurno.setFormularioAsignarTurno({
        ...contextoAsignarTurno.formularioAsignarTurno,
        tipoDocumento: 1,
      })
      setValue('tipoDocumento', 1)
      clearErrors('tipoDocumento')
    }
    if (tabIndex === 3) {
      if (e.target.value) setApellidos(...(apellidos + e.target.value))
      setValue('apellidos', (apellidos + ' ' + e.target.value).toUpperCase())
      clearErrors('apellidos')
    }
    if (tabIndex === 4) {
      setNombres(e.target.value)
    }
    if (tabIndex === 5) {
      setApellidos(...(nombres + e.target.value))
      setValue('nombre', (nombres + ' ' + e.target.value).toUpperCase())
      clearErrors('nombre')
    }
    if (tabIndex === 6) {
      if (e.target.value == 'F') {
        setValue('sexo', 1)
        clearErrors('sexo')
      } else {
        setValue('sexo', 2)
        clearErrors('sexo')
      }
    }
    if (tabIndex === 7) {
      let fechaModificada =
        e.target.value.slice(4, 8) +
        '-' +
        e.target.value.slice(2, 4) +
        '-' +
        e.target.value.slice(0, 2)
      let fechaNacimiento = moment(fechaModificada).format('YYYY-MM-DD')
      setValue('fechaNacimiento', fechaNacimiento)
      clearErrors('fechaNacimiento')
    }
    if (tabIndex === 8) {
      if (e.target.value.length > 1) {
        const grupoSanguineo = await ObtenerGrupoSanguineoPorNombre(
          e.target.value
        )
        setValue('grupoSanguineo', grupoSanguineo[0].Id)
        document.getElementById('primerInput').focus()
        eliminarDatosLectorDeFirmas()
      }
    }
    contextoAplicacion.setIsLoading(false)
  }
  const manejarNumeroDocumento = async (e) => {
    contextoAsignarTurno.setFormularioAsignarTurno({
      ...contextoAsignarTurno.formularioAsignarTurno,
      numeroDocumento: e.target.value.replace(/^(0+)/g, ''),
    })
    if (e.target.value == '') {
      contextoAsignarTurno.setUsuarioId('')
      setValue('numeroDocumento', '')
      setError('numeroDocumento', {type: 'require', message: ''})
    } else {
      setValue('numeroDocumento', e.target.value.replace(/^(0+)/g, ''))
      clearErrors('numeroDocumento')
    }
  }

  const manejarTipoDeDocumento = async (e) => {
    contextoAsignarTurno.setFormularioAsignarTurno({
      ...contextoAsignarTurno.formularioAsignarTurno,
      tipoDocumento: e.target.value,
    })
    if (e.target.value == '') {
      contextoAsignarTurno.setUsuarioId('')
      setValue('tipoDocumento', '')
      setError('tipoDocumento', {type: 'require', message: ''})
    } else {
      setValue('tipoDocumento', e.target.value)
      clearErrors('tipoDocumento')
    }
  }

  const manejarLugarDeNacimientoCambiado = async (e) => {
    contextoAsignarTurno.setFormularioAsignarTurno({
      ...contextoAsignarTurno.formularioAsignarTurno,
      lugarNacimiento: e.target.value,
    })
    if (!e.target.value) {
      return clearErrors('lugarNacimiento')
    }
    setError('lugarNacimiento', {type: 'require', message: ''})
    setValue('lugarNacimiento')
    if (e.target.value.length >= 3) {
      setValue('lugarNacimiento', e.target.value)
      FiltrarCiudadPorNombre(e.target.value)
        .then((res) => {
          if (res) {
            return Promise.all(
              res.map(async (ciudad) => {
                const departamento = ciudad?.DepartamentoId
                  ? await ObtenerDepartamentoPorId(ciudad.DepartamentoId)
                  : ''
                return {
                  id: ciudad.Id,
                  filtro: ciudad.Nombre + ', ' + departamento.Nombre,
                }
              })
            )
          }
        })
        .then((respuesta) => {
          setCiudadesFiltradas(respuesta)
        })
    } else {
      setCiudadesFiltradas([])
    }
  }

  const manejarLugarSeleccionado = (lugarSeleccionada) => {
    contextoAsignarTurno.setFormularioAsignarTurno({
      ...contextoAsignarTurno.formularioAsignarTurno,
      lugarNacimiento: lugarSeleccionada.filtro,
    })
    clearErrors('lugarNacimiento')
    setValue('lugarNacimiento', lugarSeleccionada.filtro)
    setCiudadesFiltradas([])
  }
  const ObtenerDatosFormulario = () => {
    contextoAsignarTurno.setFormularioAsignarTurno(getValues())
  }

  const manejarTipoTurno = async (e) => {
    contextoAsignarTurno.setFormularioAsignarTurno({
      ...contextoAsignarTurno.formularioAsignarTurno,
      tipoTurno: e.target.value,
    })
    setValue('tipoTurno', e.target.value)
  }

  return (
    <>
      <form id={'cargarDatosFormularioConLector'}>
        <input
          type="text"
          tabIndex="2"
          onBlur={(e) => manejarLectorDeCodigo(e, 2)}
          className="h-0 w-0"
        />
        <input
          type="text"
          tabIndex="3"
          onBlur={(e) => manejarLectorDeCodigo(e, 3)}
          className="h-0 w-0"
        />
        <input
          type="text"
          tabIndex="4"
          onBlur={(e) => manejarLectorDeCodigo(e, 4)}
          className="h-0 w-0"
        />
        <input
          type="text"
          tabIndex="5"
          onBlur={(e) => manejarLectorDeCodigo(e, 5)}
          className="h-0 w-0"
        />
        <input
          type="text"
          tabIndex="6"
          onBlur={(e) => manejarLectorDeCodigo(e, 6)}
          className="h-0 w-0"
        />
        <input
          type="text"
          tabIndex="7"
          onBlur={(e) => manejarLectorDeCodigo(e, 7)}
          className="h-0 w-0"
        />
        <input
          type="text"
          tabIndex="8"
          onChange={(e) => manejarLectorDeCodigo(e, 8)}
          className="h-0 w-0"
        />
      </form>
      <div className="w-full flex justify-evenly items-center my-2">
        <div className="w-2/12 flex justify-end items-center">
          <img className="w-16 h-auto" src={CodigoQr} alt="codigoQR" />
        </div>
        <Controller
          name="numeroDocumento"
          control={control}
          rules={{
            required: true,
            pattern: regexSoloLetrasYNumeros,
            minLength: infoNumeroCaracteres.minimo,
            maxLength: infoNumeroCaracteres.maximo,
          }}
          render={() => (
            <div className="w-4/12 flex justify-center items-center flex-wrap">
              <Input
                funcion={manejarNumeroDocumento}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'numeroDocumento'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={infoNumeroCaracteres.tipoCampo || 'text'}
                tabIndex={'1'}
                placeholder={'Descripción'}
                titulo={'No. Documento*'}
                valor={
                  contextoAsignarTurno.formularioAsignarTurno.numeroDocumento
                }
                mostrarErrores={true}
                id={'primerInput'}
              />
              <ErroresInput
                erroresFormulario={errors}
                nombre={'numeroDocumento'}
                tipoError={'pattern'}
                mensaje={'Se permiten letras y números'}
              />
            </div>
          )}
        />

        <Controller
          name="tipoDocumento"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <ListaDesplegable
              funcion={manejarTipoDeDocumento}
              estilosContenedor={'w-4/12'}
              estilosLista={
                Object.keys(errors).find(
                  (element) => element === 'tipoDocumento'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Tipo de documento*'}
              opciones={tipoDocumento}
              valor={contextoAsignarTurno.formularioAsignarTurno.tipoDocumento}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-cener items-center">
        <div className="w-3/12"></div>
        <div className="w-8/12">
          <AlertaLongitudDocumento
            tipoDocumento={
              contextoAsignarTurno.formularioAsignarTurno.tipoDocumento
            }
            informacionCaracteres={infoNumeroCaracteres}
            setInformacionCaracteres={setInfoNumeroCaracteres}
            numeroDocumento={
              contextoAsignarTurno.formularioAsignarTurno.numeroDocumento
            }
            errors={errors}
            trigger={trigger}
          />
        </div>
      </div>
      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="apellidos"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'apellidos')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Apellidos*'}
                onBlur={ObtenerDatosFormulario}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.apellidos?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />

        <Controller
          name="nombre"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'nombre')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nombre*'}
                onBlur={ObtenerDatosFormulario}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nombre?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
        <Controller
          name="grupoSanguineo"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              titulo={'Grupo sanguíneo'}
              opciones={tipoGrupoSanguineo}
              onBlur={ObtenerDatosFormulario}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="fechaNacimiento"
          control={control}
          rules={{
            required: true,
            pattern: regexTextoCaracteresBasicos,
          }}
          render={({field: {onChange, value}, fieldState}) => {
            return (
              <div className="flex flex-wrap w-31%">
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full p-1.5 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    {
                      'border border-cendiatra-rojo-1 text-cendiatra-gris-3':
                        !!fieldState.error || fechaEsValida.current,
                      'border border-cendiatra text-cendiatra-gris-3 ':
                        !fieldState.error && !fechaEsValida.current,
                    }
                  )}
                  tipo={'date'}
                  fechaMinima={obtenerFechaMinima(anioFechMinima)}
                  fechaMaxima={fechaDeHoy}
                  placeholder={'Descripción'}
                  titulo={'Fecha de nacimiento*'}
                  onBlur={ObtenerDatosFormulario}
                  valor={value}
                />
                <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.fechaNacimiento?.type === 'pattern' &&
                    'Se permiten estos caracteres + ( ) [ ] , / - . '}
                </span>
              </div>
            )
          }}
        />

        <Controller
          name="lugarNacimiento"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={() => (
            <div className="flex flex-wrap w-31%">
              <>
                <CampoFiltrarEspañol
                  estilosPersonalizados={'w-full'}
                  titulo={'Lugar de nacimiento'}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) => element === 'lugarNacimiento'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra  text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                  }
                  tipo={'text'}
                  placeholder={'Autocompletar'}
                  desactivarOtroFiltro={false}
                  valorDelCampoFiltro={
                    contextoAsignarTurno.formularioAsignarTurno.lugarNacimiento
                  }
                  handleChange={manejarLugarDeNacimientoCambiado}
                  handleOptionChange={manejarLugarSeleccionado}
                  informacionFiltrada={ciudadesFiltradas}
                />

                <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.lugarNacimiento?.type === 'pattern' &&
                    TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
                </span>
              </>
            </div>
          )}
        />
        <Controller
          name="sexo"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'sexo')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Sexo*'}
              opciones={tipoSexos}
              onBlur={ObtenerDatosFormulario}
              valor={value}
            />
          )}
        />
      </div>

      <div className="w-full">
        <div className="w-31% ml-7">
          <AlertaDinamica
            mostrarAlerta={fechaEsValida.current}
            mensaje={ALERTAS.EDAD_MAXIMA}
          />
        </div>
      </div>

      <div className="w-full flex justify-evenly items-center my-2">
        <div className="w-31% flex justify-between items-center">
          <div className="w-5/12">
            <Controller
              name="indicativoCelular"
              control={control}
              rules={{
                required: false,
              }}
              render={({field: {onChange, value}}) => (
                <ListaDesplegable
                  onChange={onChange}
                  estilosContenedor={'w-11/12'}
                  estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                  titulo={'Celular'}
                  opciones={paisesArray}
                  valor={value}
                  onBlur={() =>
                    contextoAsignarTurno.setFormularioAsignarTurno(getValues())
                  }
                />
              )}
            />
          </div>
          <div className="w-7/12">
            <Controller
              name="celular"
              control={control}
              rules={{
                required: false,
              }}
              render={({field: {onChange, value}}) => (
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full'}
                  estilosInput="appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm mt-6"
                  tipo={'number'}
                  placeholder={'Descripción'}
                  titulo={''}
                  onBlur={ObtenerDatosFormulario}
                  valor={value}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="nacionalidad"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nacionalidad'}
                onBlur={ObtenerDatosFormulario}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nacionalidad?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
        <Controller
          name="tipoTurno"
          control={control}
          rules={{
            required: false,
          }}
          render={() => (
            <ListaDesplegable
              onChange={manejarTipoTurno}
              estilosContenedor={'w-31%'}
              estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              titulo={'Tipo de Turno'}
              opciones={tiposTurno}
              valor={contextoAsignarTurno.formularioAsignarTurno.tipoTurno}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-evenly items-center my-10">
        <BotonSimple
          texto={'Guardar'}
          estilosBoton={clsx(
            'w-28 h-8 text-white bg-center bg-cover bg-no-repeat rounded-xl text-14px m-2',
            Object.keys(errors).length === 0 &&
              !deshabilitarGuardar &&
              !fechaEsValida.current
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder'
          )}
          deshabilitado={
            (Object.keys(errors).length !== 0 && !deshabilitarGuardar) ||
            fechaEsValida.current
          }
          funcion={() => {
            if (deshabilitarGuardar) return

            manejarGuardar()
          }}
          tipoDeBoton={'submit'}
        />
      </div>
    </>
  )
}

export default FormularioAsignarTurno
