export const ESTADOS_FACTURA = {
  NO_ENVIADA: 1,
  ENVIADO_PENDIENTE_DE_RESPUESTA: 2,
  DOCUMENTO_GENERADO: 3,
  DOCUMENTO_NO_GENERADO_REQUIERE_CORRECCION: 4,
  DOCUMENTO_GENERADO_PENDIENTE_CUFE_DIAN: 5,
}

export const LISTA_ESTADOS_FACTURA = [
  {id: 1, nombre: 'NO ENVIADA'},
  {id: 2, nombre: 'ENVIADO PENDIENTE DE RESPUESTA'},
  {id: 3, nombre: 'DOCUMENTO GENERADO'},
  {id: 4, nombre: 'DOCUMENTO NO GENERADO REQUIERE CORRECCIÓN'},
  {id: 5, nombre: 'DOCUMENTO GENERADO PENDIENTE CUFE DIAN'},
]

export const ORIGENES_FACTURAS = [
  {id: 0, nombre: 'TODOS'},
  {id: 1, nombre: 'SEDE'},
  {id: 2, nombre: 'PORTAL CLIENTE'},
]
