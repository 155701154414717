import {ObtenerTarifarios} from '../../microservicios/Tarifario'
import {dbComercial} from '../bases-datos/dbComercial'
import moment from 'moment'
import {ObtenerTodosLosPortafolios} from './Portafolios'
import TarifariosWorker from './Tarifarios.worker.js'

export const AgregarTarifariosACache = async (usarFecha = true, onProgress) => {
  await dbComercial.open()
  const fechaUltimaActualizacion = (
    await dbComercial.table('FechasActualizacion').get({Tabla: 'Tarifarios'})
  )?.FechaUltimaActualizacion

  const empresas = await dbComercial.Empresas.toArray()
  const res = await ObtenerTarifarios(
    usarFecha ? fechaUltimaActualizacion : undefined
  )

  if (!Array.isArray(res.data)) return

  const worker = new TarifariosWorker()

  worker.addEventListener('message', (e) => {
    if (e.data.type === 'progreso') {
      onProgress?.(parseFloat(e.data.porcentaje))
    } else if (e.data.type === 'completo') {
      console.log('Carga de tarifarios completada')
    } else if (e.data.type === 'error') {
      console.error('Error desde el worker de tarifarios:', e.data.mensaje)
    }
  })

  await worker.cargarTarifarios(res.data, empresas, usarFecha)
}

export const ObtenerTarifariosPorNombreEmpresa = async (empresa) => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()
  const tarifarios = await Promise.resolve(
    dbComercial
      .table('Tarifarios')
      .filter(
        ({NombreEmpresa, Id}) =>
          NombreEmpresa.toUpperCase().indexOf(empresa.toUpperCase()) > -1 &&
          !idsPortafolios.includes(Id)
      )
      .toArray()
  )
  return tarifarios
}

export const ObtenerTarifariosPorNombre = async (nombre) => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()
  const tarifarios = await Promise.resolve(
    dbComercial
      .table('Tarifarios')
      .filter(
        ({Nombre, Id}) =>
          Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1 &&
          !idsPortafolios.includes(Id)
      )
      .toArray()
  )

  return tarifarios
}

export const ObtenerTodosLosTarifarios = async () => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()

  const Tarifarios = await Promise.resolve(
    dbComercial
      .table('Tarifarios')
      .filter(({Id}) => !idsPortafolios.includes(Id))
      .toArray()
  )

  return Tarifarios
}

export const ObtenerTarifarioPorEmpresaId = async (empresaId) => {
  return Promise.resolve(
    dbComercial.table('Tarifarios').get({EmpresaId: empresaId})
  )
}

export const ObtenerTarifarioPorNombre = (nombre) =>
  dbComercial
    .table('Tarifarios')
    .where('Nombre')
    .equalsIgnoreCase(nombre)
    .first()

export const ObtenerTarifariosPorFechas = async (fechaInicial, fechaFinal) => {
  const idsPortafolios = await obtenerIdsDeLosPortafoliosParaValidacion()

  let tarifarios = dbComercial
    .table('Tarifarios')
    .filter(({Id}) => !idsPortafolios.includes(Id))
  if (fechaInicial == '' && fechaFinal == '') {
    return tarifarios.toArray()
  }
  if (fechaFinal == '') {
    tarifarios = tarifarios.filter(({FechaInicial}) =>
      moment(JSON.stringify(FechaInicial)).isSameOrAfter(
        JSON.stringify(fechaInicial)
      )
    )
  }
  if (fechaInicial == '') {
    tarifarios = tarifarios.filter(({FechaFinal}) =>
      moment(JSON.stringify(FechaFinal)).isSameOrBefore(
        JSON.stringify(fechaFinal)
      )
    )
  }
  if (fechaInicial !== '' && fechaFinal !== '') {
    tarifarios = tarifarios.filter(({FechaInicial, FechaFinal}) =>
      moment(JSON.stringify(FechaInicial))
        .add(1, 'days')
        .isBetween(JSON.stringify(fechaInicial), JSON.stringify(fechaFinal)) &&
      FechaFinal
        ? moment(JSON.stringify(FechaFinal))
            .subtract(1, 'days')
            .isBetween(JSON.stringify(fechaInicial), JSON.stringify(fechaFinal))
        : null
    )
  }
  return await Promise.resolve(tarifarios.toArray())
}

const obtenerIdsDeLosPortafoliosParaValidacion = async () => {
  const portafolios = await ObtenerTodosLosPortafolios()

  return portafolios?.map((portafolio) => portafolio.Id) ?? []
}
