import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {
  ObtenerAutocompletarFuenteDeDatosPorId,
  ObtenerAutocompletarFuenteDeDatosPorNombre,
  ObtenerCie11PorNombreOCodigo,
  ObtenerCiePorId,
} from '../../../cache/servicios-cache'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import {AreaDeTexto} from '../../inputs'
import {NOMBRE_REFERENCIAS} from '../../../constantes'

const CampoAutocompletar = ({
  numeroCampo,
  numeroPestana,
  numeroSeccion,
  readOnly,
}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const versionFuenteDatos = useMemo(() => {
    return campo?.atributos?.version ?? 1
  }, [campo])

  const [valoresFiltrados, setValoresFiltrados] = useState([])
  const [valorCampo, setValorCampo] = useState('')
  const [esModificacion, setEsModificacion] = useState(false)

  const valorCampoId =
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  const valorObservacion =
    contextoFormulario.valoresObservaciones?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ] ?? ''

  useEffect(() => {
    if (
      valorCampoId &&
      campo.atributos.fuenteDatos.toUpperCase() !== 'ELEGIR MANUALMENTE' &&
      !campo?.atributos?.listaOpciones?.length
    ) {
      ObtenerAutocompletarFuenteDeDatosPorId(
        campo.atributos.fuenteDatos,
        parseInt(valorCampoId ?? 0)
      ).then(async (opcion) => {
        let nombreOpcion = opcion?.nombre ?? ''
        if (campo.atributos?.fuenteDatos === NOMBRE_REFERENCIAS.CIE10) {
          const opciones = await ObtenerCie11PorNombreOCodigo(nombreOpcion)
          const usoDeFindCie = opciones.find(
            (opt) =>
              opt?.filtro?.split(' - ')?.[1]?.toUpperCase() ===
              nombreOpcion?.toUpperCase()
          )

          const cie = await ObtenerCiePorId(+valorCampoId ?? 0)
          const nombreCie = !!cie ? `${cie.Cie10} - ${cie.Nombre}` : null

          nombreOpcion = usoDeFindCie?.filtro ?? nombreCie ?? ''
        }
        setValorCampo(nombreOpcion)
      })
    } else if (valorCampoId && campo?.atributos?.listaOpciones?.length) {
      const opcion = campo?.atributos?.listaOpciones?.find(
        (opcion) => opcion.id === valorCampoId
      )

      setValorCampo(opcion?.valor ?? '')
    } else {
      setValorCampo('')
    }
  }, [valorCampoId])

  useEffect(() => {
    if (
      campo?.atributos?.hasOwnProperty('valorPredeterminado') &&
      contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
        campo?.tituloCampo
      ] === undefined
    ) {
      contextoFormulario.setValoresCampos((prv) => ({
        ...prv,
        [accesoPestana]: {
          ...(prv?.[accesoPestana] ?? {}),
          [accesoSeccion]: {
            ...(prv?.[accesoPestana]?.[accesoSeccion] ?? {}),
            [campo.tituloCampo]: campo?.atributos?.valorPredeterminado ?? '',
          },
        },
      }))
    }
  }, [
    campo,
    contextoFormulario.valoresCampos?.[accesoPestana]?.[accesoSeccion]?.[
      campo?.tituloCampo
    ],
  ])

  const minimoCaracteres =
    campo?.atributos?.fuenteDatos === NOMBRE_REFERENCIAS.CIE10 ? 4 : 3

  const filtrarCampo = async (e) => {
    setEsModificacion(true)
    setValorCampo(e.target.value)
    if (e.target.value.length >= minimoCaracteres) {
      if (
        campo.atributos.listaOpciones &&
        !!campo.atributos.listaOpciones.length &&
        campo.atributos.fuenteDatos.toUpperCase() === 'ELEGIR MANUALMENTE'
      ) {
        setValoresFiltrados(
          campo.atributos.listaOpciones
            .filter(
              (x) =>
                x.valor.toUpperCase().indexOf(e.target.value?.toUpperCase()) >
                -1
            )
            .map((opcion) => ({
              id: opcion.id,
              filtro: opcion.valor,
            }))
        )
      } else {
        if (campo.atributos.fuenteDatos === NOMBRE_REFERENCIAS.CIE10) {
          setValoresFiltrados(
            await ObtenerCie11PorNombreOCodigo(e.target.value)
          )
        } else {
          const opciones =
            (await ObtenerAutocompletarFuenteDeDatosPorNombre(
              campo.atributos.fuenteDatos,
              e.target.value
            )) ?? []
          setValoresFiltrados(
            opciones
              .filter((t) =>
                !!versionFuenteDatos ? t.version === versionFuenteDatos : true
              )
              .map((opcion) => ({
                id: opcion.identificacion,
                filtro: opcion.nombre,
              }))
          )
        }
      }
    } else {
      setValoresFiltrados([])
    }
  }

  const seleccionarOpcion = (opcion) => {
    if (readOnly) return
    setValorCampo(opcion?.filtro ?? '')
    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: opcion?.id ?? opcion?.Id ?? '',
        },
      },
    })
  }

  const manejarCambioObservacion = (evt) => {
    if (readOnly) return

    contextoFormulario.setValoresObservaciones({
      ...contextoFormulario.valoresObservaciones,
      [accesoPestana]: {
        ...(contextoFormulario.valoresObservaciones?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresObservaciones?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: evt?.target?.value ?? '',
        },
      },
    })
  }

  useEffect(() => {
    if (esModificacion && !valorCampo) {
      seleccionarOpcion(null)
      manejarCambioObservacion(null)
    }
  }, [esModificacion, valorCampo])

  return (
    <div className="w-full flex flex-wrap">
      <CampoFiltrarEspañol
        titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
        estilosPersonalizados={'w-full'}
        estilosInput={`${
          campo?.requerido && !valorCampo
            ? 'border-cendiatra-rojo-1'
            : 'border-cendiatra'
        }`}
        tipo={'text'}
        valorDelCampoFiltro={valorCampo}
        handleChange={filtrarCampo}
        handleOptionChange={seleccionarOpcion}
        desactivarOtroFiltro={''}
        informacionFiltrada={valoresFiltrados}
        id={1}
        readOnly={readOnly}
      />
      {campo?.atributos?.campoTextoLargo ? (
        <AreaDeTexto
          placeholder={'Descripción (texto largo)'}
          estilosContenedor={'w-full'}
          estilosAreaDeTexto={
            'appearance-none rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
          }
          valor={valorObservacion}
          cambioValor={manejarCambioObservacion}
          readOnly={readOnly}
        />
      ) : null}
    </div>
  )
}

export default CampoAutocompletar
