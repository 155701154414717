import React, {useEffect, useContext} from 'react'
import {ContextApplication} from '../../contexto'
import FilaPrecios from './filas/FilaPrecios'
import {
  ValidarOrdenesPorTarifarioIdYPaqueteId,
  ValidarAtencionesPorTarifarioIdYPaqueteId,
} from '../../microservicios'

export default function TablaPaquetes({contexto, esPortafolio = false}) {
  const contextoApp = useContext(ContextApplication)

  useEffect(() => {
    contexto.listaPaquetes.forEach((paquete, index, listaPaquetes) => {
      listaPaquetes[index].precios_sedes = contexto.sedesSeleccionadas
        .filter((x) => x.Activo)
        .map((sede) => ({
          id: 'paquete:' + sede.Id,
          nombre: sede.Nombre,
          grupo: sede.Grupo,
          precio:
            paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id) ==
            undefined
              ? 0
              : paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id)
                  .precio,
          idPrecio:
            paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id) ==
            undefined
              ? 0
              : paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id)
                  .idPrecio,
          tipo: 'paquete',
          idGeneral: paquete?.paquete_id,
          sedeId: sede.Id,
          nuevoPrecio:
            paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id) ==
            undefined
              ? 0
              : paquete.precios_sedes.find((x) => x.id == 'paquete:' + sede.Id)
                  .nuevoPrecio,
        }))
    })
  }, [contexto.sedesSeleccionadas])

  useEffect(() => {
    contexto.listaPaquetes.forEach((paquete, index, listaPaquetes) => {
      listaPaquetes[index].precios_aliados = contexto.aliadosSeleccionados
        .filter((x) => x.Activo)
        .map((aliado) => ({
          id: 'aliado:' + aliado.Id,
          nombre: aliado.Nombre,
          grupo: 'ALIADOS',
          precio:
            paquete.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : paquete.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).precio,
          idPrecio:
            paquete.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : paquete.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).idPrecio,
          tipo: 'paquete',
          idGeneral: paquete?.paquete_id,
          aliadoId: aliado.Id,
          nuevoPrecio:
            paquete.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : paquete.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).nuevoPrecio,
        }))
    })
  }, [contexto.aliadosSeleccionados])

  const manejarCerrarModalEliminar = () => {
    contextoApp.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap mx-20">
          <span className="w-full text-cendiatra text-3xl font-bold">
            NO SE APLICARÁN LOS CAMBIOS
          </span>
          <span className="w-full text-cendiatra text-lg mt-16 mb-10 normal-case">
            No se continúa con el proceso y no se actualiza el tarifario.
          </span>
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: cerrarModal,
        },
      ],
      clasesAdicioneles:
        'w-5/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5 rounded-xl',
    })
  }

  const cerrarModal = () => {
    contextoApp.setModal({abierto: false, botones: []})
  }

  const confirmarEliminarPaquete = (paquete) => {
    if (Boolean(contexto.portafolioSeleccionadoId)) {
      contexto.setSeguroDeCambio(true)
      return
    }
    contextoApp.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap mx-20">
          <span className="w-full text-cendiatra text-3xl font-bold">
            ¿ESTA SEGURO DE QUITAR EL SERVICIO Y/O PAQUETE?
          </span>
          <span className="w-full text-cendiatra text-lg mt-16 mb-10">
            Nombre del paquete : {paquete.nombre}
          </span>
        </div>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => {
            validarPaqueteTieneAtenciones(paquete.paquete_id)
            cerrarModal()
          },
        },
        {
          nombre: 'No',
          click: cerrarModal,
        },
      ],
      clasesAdicioneles:
        'w-5/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5 rounded-xl',
    })
  }

  const validarPaqueteTieneAtenciones = async (idPaquete) => {
    let eliminarLocalmente = true

    const id = contexto.portafolioId || contexto.idTarifario

    const {data: ordenConRegistros} =
      await ValidarOrdenesPorTarifarioIdYPaqueteId(id, idPaquete)

    if (ordenConRegistros) {
      eliminarLocalmente = false

      contextoApp.setModal({
        abierto: true,
        titulo: '',
        contenido: (
          <div className="w-full flex justify-center items-center flex-wrap mx  -5">
            <span className="w-full text-cendiatra-rojo-1 text-3xl font-bold">
              EXISTEN ÓRDENES DE SERVICIO PENDIENTES DE ATENCIÓN
            </span>
            <span className="w-full text-cendiatra-rojo-1 text-3xl font-bold">
              PARA ESTE TARIFARIO
            </span>
            <span className="w-full text-cendiatra text-lg mt-16 mb-10 font-bold">
              ¿DESEA APLICAR LOS CAMBIOS?
            </span>
          </div>
        ),
        botones: [
          {
            nombre: 'Si',
            click: () => {
              eliminarPaquetePorId(idPaquete)
              cerrarModal()
            },
          },
          {
            nombre: 'No',
            click: manejarCerrarModalEliminar,
          },
        ],
        clasesAdicioneles:
          'w-4/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5 rounded-xl',
      })
    }
    if (eliminarLocalmente) {
      eliminarPaquetePorId(idPaquete)
    }
  }

  const eliminarPaquetePorId = (idPaquete) => {
    let filtro = contexto.listaPaquetes.filter((paquete) => {
      return paquete.paquete_id !== idPaquete
    })
    contexto.setListaPaquetes(filtro)
  }

  const validarElementoNuevo = (id) => {
    return Boolean(
      contexto.paquetesIniciales.find((elemento) => elemento === id)
    )
  }

  const validarColumnaAEditar = (paqueteId, datos) => {
    const sedeOAliadoNuevo = validacionGeneral(datos)
    if (sedeOAliadoNuevo) {
      const nombrePropiedad =
        contexto.modoEdicion && validarElementoNuevo(paqueteId)
          ? 'nuevoPrecio'
          : 'precio'
      return nombrePropiedad
    }
    return 'precio'
  }

  const validacionGeneral = (data) => {
    const resultado = Boolean(
      contexto?.[
        data?.grupo === 'ALIADOS' ? 'aliadosIniciales' : 'sedesIniciales'
      ].find((x) =>
        data?.grupo === 'ALIADOS'
          ? x === data?.aliadoId ?? 0
          : x === data?.sedeId ?? 0
      )
    )
    return resultado
  }

  const actualizarPrecios = (idSede, value, idFila) => {
    contexto.setListaPaquetes(
      contexto.listaPaquetes.map((paquete) => {
        if (paquete.paquete_id == idFila) {
          return {
            ...paquete,
            precios_sedes: paquete.precios_sedes.map((elementoPrecio) => {
              if (elementoPrecio.id == idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(paquete.paquete_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
            precios_aliados: paquete.precios_aliados.map((elementoPrecio) => {
              if (elementoPrecio.id == idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(paquete.paquete_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
          }
        }
        return paquete
      })
    )
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      {contexto.listaPaquetes.map((paquete, posicion) => (
        <FilaPrecios
          contexto={contexto}
          idFila={paquete.paquete_id}
          nombre={paquete.nombre}
          precios={paquete.precios_sedes.concat(paquete.precios_aliados)}
          actualizarPrecios={actualizarPrecios}
          eliminarFila={() => confirmarEliminarPaquete(paquete)}
          posicion={contexto.listaServicios.length + posicion}
          key={posicion}
          tipoElemento={'Paquete'}
          esPortafolio={esPortafolio}
        />
      ))}
    </div>
  )
}
