import React, {useEffect, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {Input, AreaDeTexto} from '../../inputs'
import {ContextoRegistro, ContextApplication} from '../../../contexto'
import clsx from 'clsx'

const MotivoModificacionDeDatos = ({
  setActualizarMotivoModificacion,
  habilitarCampo,
}) => {
  const {
    trigger,
    getValues,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const contextoRegistro = useContext(ContextoRegistro)

  useEffect(() => {
    trigger()
  }, [])

  useEffect(() => {
    const subscription = watch(() => {
      const motivoModificacion = getValues()
      setActualizarMotivoModificacion(
        motivoModificacion.motivoModificacionDeDatos
      )
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    trigger()
    contextoRegistro.setformularioActualTieneErrores(
      !isValid || Object.keys(errors).length
    )

    contextoRegistro.setErroresModificarFormulario({
      ...contextoRegistro.erroresModificarFormulario,
      motivosModificacion: !isValid || Boolean(Object.keys(errors).length),
    })
  }, [isValid, Object.keys(errors).length])

  return (
    <Controller
      name="motivoModificacionDeDatos"
      control={control}
      rules={{
        required: true,
      }}
      render={({field: {onChange, value}}) => (
        <AreaDeTexto
          estilosContenedor={'w-full -mt-8 mb-10'}
          estilosAreaDeTexto={clsx(
            'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
            {
              'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                Object.keys(errors).find(
                  (element) => element === 'motivoModificacionDeDatos'
                ) && habilitarCampo,
              'border border-cendiatra focus:border-cendiatra': !Object.keys(
                errors && habilitarCampo
              ).find((element) => element === 'motivoModificacionDeDatos'),
              'border border-cendiatra pointer-events-none bg-cendiatra-gris-2':
                !habilitarCampo,
            }
          )}
          placeholder={'Descripción'}
          valor={value}
          cambioValor={onChange}
          titulo={'Motivo de modificación de datos*'}
          deshabilitado={false}
          rows={2}
        />
      )}
    />
  )
}

export default MotivoModificacionDeDatos
