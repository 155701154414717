export const TIPOS_MEDIOS_DE_PAGO = {
  BONO: 'Bonos',
  EFECTIVO: 'Efectivo',
  TARJETA: 'Tarjetas Débito / Credito',
  TIQUETERA: 'Tiqueteras',
  USO_DE_SALDO_CUENTA_CLIENTE: 'Uso De Saldo Cuenta Del Cliente',
  CONSIGNACIONES_TRANSFERENCIAS: 'Consignaciones - Transferencia',
  RETEFUENTE_RENTA: 'Retefuente Renta',
  RETEFUENTE_ICA: 'Retefuente ICA',
  USO_DE_SALDO_CUENTA_CLIENTE_NUMERICO: {
    VALOR_NUMERICO_STRING: '7',
    VALOR_NUMERICO: 7,
  },
  BONO_VALOR_NUMERICO: 3,
}
