import {getRestApi} from './ApiAxios'

export const ObtenerOrdenAbiertaPorUsuarioId = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'orden-function/ObtenerOrdenAbiertaPorUsuarioId?Id=' + id
  )
}

export const ObtenerOrdenPorId = async (id) => {
  const apiReq = await getRestApi()
  return await apiReq.get('orden-function/ObtenerOrdenPorId?Id=' + id)
}

export const ValidarOrdenesPorTarifarioIdYServicioId = async (
  tarifarioId,
  servicioId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'orden-function/ValidarOrdenesPorTarifarioIdYServicioId?tarifarioId=' +
      tarifarioId +
      '&servicioId=' +
      servicioId
  )
}

export const ValidarOrdenesPorTarifarioIdYPaqueteId = async (
  tarifarioId,
  paqueteId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'orden-function/ValidarOrdenesPorTarifarioIdYPaqueteId',
    {
      params: {
        tarifarioId,
        paqueteId,
      },
    }
  )
}

export const ObtenerOrdenesAsignadasPorUsuarioIdEstadoId = async (
  usuarioId,
  estadoOrdenId
) => {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'gato-api-function/ObtenerOrdenesAsignadasPorUsuarioIdEstadoId',
    {
      params: {
        usuarioId,
        estadoOrdenId,
      },
    }
  )
}
