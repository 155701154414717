import React, {useContext, useEffect, useState} from 'react'
import {ListaDesplegable} from '../../componentes/inputs'
import {TEXTO_VISUAL} from '../../constantes'
import {ContextApplication, ContextoTarifario} from '../../contexto'
import moment from 'moment'
import {useModal} from '../../hooks/useModal'
import {
  AgregarPortafoliosACache,
  ObtenerTodosLosPortafolios,
  ObtenerTodasLasSedes,
  ObtenerTodosLosAliados,
  obtenerServiciosPorIds,
  obtenerPaquetesPorIds,
} from '../../cache/servicios-cache'
import {obtenerPortafolioPorId} from '../../microservicios'
import {UseObtenerRolValido} from '../../utilidades/funcionesComunes'
import {ObtenerTarifarioPorEmpresaIdBD} from '../../microservicios'

const InputSeleccionarPortafolio = ({numeroPagina, modalEdicion}) => {
  const [listaPortafolios, setListaPortafolios] = useState([])

  const contextoApp = useContext(ContextApplication)

  const contextoTarifario = useContext(ContextoTarifario)

  const [cerrarModal] = useModal()

  const rolValido = UseObtenerRolValido()

  const claseBloqueo = rolValido ? 'pointer-events-none' : ''

  useEffect(() => {
    AgregarPortafoliosACache()
      .then(() => ObtenerTodosLosPortafolios())
      .then((portafolios) =>
        setListaPortafolios(
          portafolios.map((elemento) => {
            return {
              Nombre: elemento.Nombre,
              Id: elemento.Id,
            }
          })
        )
      )
      .catch((e) => console.error)
  }, [])

  const obtenerArrayConDatos = (arrayServicios, arrayPaquetes) => {
    if (arrayServicios && arrayServicios.length > 0) {
      return arrayServicios
    }
    if (arrayPaquetes && arrayPaquetes.length > 0) {
      return arrayPaquetes
    }
    return []
  }

  const abrirModalConfirmacionAgregarPortafolio = async (id) => {
    if (!id) {
      contextoTarifario.setFormularioEmpresa({
        ...contextoTarifario.formularioEmpresa,
        fechaInicial: modalEdicion
          ? contextoTarifario?.formularioEmpresa?.fechaInicial ?? ''
          : '',
        fechaFinal: modalEdicion
          ? contextoTarifario?.formularioEmpresa?.fechaFinal ?? ''
          : '',
        portafolio: id,
      })
      contextoTarifario.setListaServicios([])
      contextoTarifario.setListaPaquetes([])
      contextoTarifario.setFilaSeleccionada(-1)
      contextoTarifario.setNombreTarifarioValidacion((prv) =>
        modalEdicion ? prv : ''
      )
      contextoTarifario.setActualizar(!contextoTarifario.actualizar)
      contextoTarifario.setPortafolioSeleccionadoId('')
      return
    }
    contextoTarifario.setPortafolioSeleccionadoId(id)
    const respuesta = await obtenerPortafolioPorId(id)

    const infoTarifario = await ObtenerTarifarioPorEmpresaIdBD(
      contextoTarifario.idTarifario
    )

    const serviciosIniciales =
      infoTarifario?.data?.servicios?.map((servicio) => servicio?.servicioId) ??
      []

    const paquetesIniciales =
      infoTarifario?.data?.paquetes?.map((paquete) => paquete?.paqueteId) ?? []

    const portafolioServiciosIds = respuesta.data.servicios.map(
      (item) => item.servicioId
    )
    const portafolioPaquetesIds = respuesta.data.paquetes.map(
      (item) => item.paqueteId
    )

    const paquetesDiferentes = paquetesIniciales.filter(
      (id) => !portafolioPaquetesIds.includes(id)
    )
    const serviciosDiferentes = serviciosIniciales.filter(
      (id) => !portafolioServiciosIds.includes(id)
    )

    const paquetesABorrar = await obtenerPaquetesPorIds(paquetesDiferentes)

    const serviciosABorrar = await obtenerServiciosPorIds(serviciosDiferentes)

    const todosLosServiciosYPaquetes = serviciosABorrar.concat(paquetesABorrar)

    if (!todosLosServiciosYPaquetes.length) {
      return manejarObtenerDatosPortafolio(id)
    }

    contextoApp.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <h2 className="text-cendiatra w-full text-2xl font-bold mb-5">
            {' '}
            AL APLICAR EL PORTAFOLIO <br />
            SE VAN A ELIMINAR O RETIRAR LOS SERVICIOS
          </h2>
          <div className="w-11/12 flex flex-wrap justify-center items-center text-cendiatra-gris-1  uppercase max-h-96 overflow-y-auto">
            {todosLosServiciosYPaquetes.map((elemento) => (
              <div
                key={elemento.CodigoInterno}
                className="w-11/12 flex justify-center items-center border-b border-cendiatra-gris-1 h-auto "
              >
                <span className="w-4/12 my-5">
                  {elemento.CodigoCompleto || elemento.CodigoInterno}
                </span>
                <span className="w-8/12 my-5 text-left">{elemento.Nombre}</span>
              </div>
            ))}
          </div>
          <span className="w-full text-center text-cendiatra text-xl normal-case mt-10">
            ¿Desea continuar?
          </span>
        </>
      ),
      botones: [
        {
          nombre: 'SI',
          click: () => {
            cerrarModal('')
            manejarObtenerDatosPortafolio(id)
          },
        },
        {
          nombre: 'NO',
          click: () => {
            cerrarModal()
            contextoTarifario.setPortafolioSeleccionadoId('')
          },
        },
      ],
      clasesAdicioneles:
        'w-7/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5 rounded-2xl',
    })
  }

  const manejarObtenerDatosPortafolio = async (id) => {
    contextoTarifario.setPortafolioSeleccionadoId(id)
    const sedes = await ObtenerTodasLasSedes()
    const aliados = await ObtenerTodosLosAliados()
    const respuesta = await obtenerPortafolioPorId(id)

    contextoTarifario.setImplementoPortafolio(true)

    const {data: portafolio} = respuesta

    const serviciosIds = portafolio.servicios.map((s) => s.servicioId)
    const paquetesIds = portafolio.paquetes.map((s) => s.paqueteId)
    const servicios = await obtenerServiciosPorIds(serviciosIds)
    const paquetes = await obtenerPaquetesPorIds(paquetesIds)

    contextoTarifario.setFormularioEmpresa({
      ...contextoTarifario.formularioEmpresa,
      id: contextoTarifario?.idTarifario ?? '',
      fechaInicial: moment(portafolio.fechaInicial).format('YYYY-MM-DD'),
      fechaFinal: portafolio.fechaFinal
        ? moment(portafolio.fechaFinal).format('YYYY-MM-DD')
        : '',
      portafolio: id,
    })
    let sedesIds = []
    let aliadosIds = []

    const arrayObtenerSedes = obtenerArrayConDatos(
      respuesta?.data?.servicios,
      respuesta?.data?.paquetes
    )

    contextoTarifario.setSedesIniciales(
      arrayObtenerSedes?.[0]?.preciosSedes?.map((sede) => {
        return sede?.sedeId
      }) ?? []
    )
    contextoTarifario.setAliadosIniciales(
      arrayObtenerSedes?.[0]?.preciosAliados?.map((sede) => {
        return sede?.aliadoId
      }) ?? []
    )

    const serviciosIniciales =
      respuesta?.data?.servicios?.map((servicio) => servicio?.servicioId) ?? []
    contextoTarifario.setServiciosIniciales(serviciosIniciales)

    const paquetesIniciales =
      respuesta?.data?.paquetes?.map((paquete) => paquete?.paqueteId) ?? []
    contextoTarifario.setPaquetesIniciales(paquetesIniciales)

    if (portafolio.paquetes.length > 0) {
      sedesIds = portafolio.paquetes[0].preciosSedes.map((x) => x.sedeId)
      aliadosIds =
        portafolio.paquetes[0].preciosAliados == null
          ? []
          : portafolio.paquetes[0].preciosAliados.map((x) => x.aliadoId)
    } else {
      sedesIds = portafolio.servicios[0].preciosSedes.map((x) => x.sedeId)
      aliadosIds =
        portafolio.servicios[0].preciosAliados == null
          ? []
          : portafolio.servicios[0].preciosAliados.map((x) => x.aliadoId)
    }

    contextoTarifario.setListaPaquetes(
      portafolio.paquetes.map((elemento) => {
        return {
          paquete_id: elemento.paqueteId,
          puedeModificarse: elemento.puedeModificarse,
          nombre: paquetes.find((x) => x.Id == elemento.paqueteId).Nombre,
          precios_sedes: elemento.preciosSedes.map((sede) => {
            const sedeCache = sedes.find((x) => x.Id == sede.sedeId)

            return {
              grupo: sedeCache.Grupo,
              id: 'paquete:' + sede.sedeId,
              nombre: sedeCache.Nombre,
              precio: sede.precio,
              idPrecio: sede.id,
              tipo: 'paquete',
              idGeneral: elemento.paqueteId,
              nuevoPrecio: sede.precioAModificarse,
            }
          }),
          precios_aliados:
            elemento.preciosAliados == null
              ? []
              : elemento.preciosAliados.map((aliado) => {
                  return {
                    grupo: 'ALIADOS',
                    id: 'aliado:' + aliado.aliadoId,
                    nombre: aliados.find((x) => x.Id == aliado.aliadoId).Nombre,
                    precio: aliado.precio,
                    idPrecio: aliado.id,
                    tipo: 'paquete',
                    idGeneral: elemento.paqueteId,
                    nuevoPrecio: aliado.precioAModificarse,
                  }
                }),
        }
      })
    )

    contextoTarifario.setListaServicios(
      portafolio.servicios.map((elemento) => {
        return {
          alias: elemento.alias,
          nombre: servicios.find((x) => x.Id == elemento.servicioId).Nombre,
          servicio_id: elemento.servicioId,
          precios_aliados:
            elemento.preciosAliados == null
              ? []
              : elemento.preciosAliados.map((aliado) => {
                  return {
                    grupo: 'ALIADOS',
                    id: 'aliado:' + aliado.aliadoId,
                    nombre: aliados.find((x) => x.Id == aliado.aliadoId).Nombre,
                    precio: aliado.precio,
                    idPrecio: aliado.id,
                    tipo: 'servicio',
                    idGeneral: elemento.servicioId,
                    nuevoPrecio: aliado.precioAModificarse,
                  }
                }),
          precios_sedes: elemento.preciosSedes.map((sede) => {
            const sedeCache = sedes.find((x) => x.Id == sede.sedeId)

            return {
              grupo: sedeCache.Grupo,
              id: 'servicio:' + sede.sedeId,
              nombre: sedeCache.Nombre,
              precio: sede.precio,
              idPrecio: sede.id,
              tipo: 'servicio',
              idGeneral: elemento.servicioId,
              nuevoPrecio: sede.precioAModificarse,
            }
          }),
        }
      })
    )

    contextoTarifario.setSedesSeleccionadas(
      sedes.map((sede) => {
        return {
          Id: sede.Id,
          Nombre: sede.Nombre,
          Ciudad: sede.Ciudad,
          Grupo: sede.Grupo,
          Activo: sedesIds.find((x) => x == sede.Id) ? true : false,
        }
      })
    )
    contextoTarifario.setAliadosSeleccionados(
      aliados.map((aliado) => {
        return {
          Id: aliado.Id,
          Nombre: aliado.Nombre,
          Activo: aliadosIds.find((x) => x == aliado.Id) ? true : false,
        }
      })
    )
    contextoTarifario.setActualizar(!contextoTarifario.actualizar)
    if (modalEdicion) {
      contextoApp.setModal({
        abierto: true,
        titulo: (
          <span className="w-full text-cendiatra text-2xl font-bold">
            {TEXTO_VISUAL.TARIFARIO.MODAL_CARGAR_PORTAFOLIO.TITULO}
          </span>
        ),
        contenido: (
          <>
            <span className="w-full text-cendiatra text-xl font-bold">
              {
                TEXTO_VISUAL.TARIFARIO.MODAL_CARGAR_PORTAFOLIO
                  .TEXTO_PRIMERA_LINEA
              }
            </span>
            <span className="w-full text-cendiatra text-xl font-bold">
              {
                TEXTO_VISUAL.TARIFARIO.MODAL_CARGAR_PORTAFOLIO
                  .TEXTO_SEGUNDA_LINEA
              }
            </span>
          </>
        ),
        botones: [
          {
            nombre: TEXTO_VISUAL.BOTONES.REGRESAR,
            click: () => {
              cerrarModal()
            },
          },
        ],
      })
    }
  }
  return (
    <div className={`w-full flex justify-evenly mt-10 ${claseBloqueo}`}>
      <ListaDesplegable
        onChange={(e) =>
          abrirModalConfirmacionAgregarPortafolio(e.target.value)
        }
        estilosContenedor={'w-4/12'}
        estilosLista={
          'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
        }
        deshabilitado={numeroPagina !== contextoTarifario.numeroPagina}
        titulo={'Opciones de portafolio para aplicar'}
        opciones={listaPortafolios}
        valor={contextoTarifario.portafolioSeleccionadoId}
      />
      <div className="w-3/12"></div>
      <div className="w-4/12"></div>
    </div>
  )
}

export default InputSeleccionarPortafolio
