import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
  EncabezadoFondo,
  EncabezadoConSubTitulos,
  TablaConPaginacionYFiltros,
} from '../Tablas'
import {InputFiltroFecha} from '../inputs'
import {CuadroInformativoConSumatoria} from '../MostrarInformacion'
import {FiltroListaSeleccionar} from '../Filtros'
import BotonConImagen from '../BotonConImagen'
import {
  CeldaDobleTexto,
  CeldaFormatoDinero,
  CeldaConEventoClick,
} from '../Celdas'
import {
  FachadaObtenerConteoDeEstadosFactura,
  FachadaGenerarReciboCajaAtencion,
  GenerarDocumentoFactura,
  ObtenerDocumentoPDF,
} from '../../microservicios'
import {
  ObtenerEstadoFacturaPorId,
  ObtenerTodasLasSedes,
} from '../../cache/servicios-cache'
import imprimirHabilitado from '../../imagenes/iconoImpresoraCuadradoVerde.svg'
import imprimirDeshabilitado from '../../imagenes/iconoImprimirGrisCuadrado.svg'
import moment from 'moment/moment'
import {RUTAS_APP} from '../../constantes'
import {encabezadosFacturas} from '../CSV/EncabezadosArchivos'
import {ESTADOS_FACTURA} from '../../constantes'
import {
  FachadaObtenerFacturasEnviadasPaginado,
  FachadaObtenerFacturas,
  FachadaObtenerReciboCajaPagoPorFacturaId,
} from '../../microservicios/Fachada'
import ComponenteConjuntoDeFiltros from '../Filtros/ComponenteConjuntoDeFiltros'
import {agregarFormatoFecha} from '../../utilidades/funcionesComunes'
import {
  LISTA_ESTADOS_FACTURA,
  ORIGENES_FACTURAS,
} from '../../constantes/estadosFactura'
import {ExportarCSV} from '../Botones'

const filtrosIniciales = {
  tamano: 10,
  pagina: 1,
  filtros: {
    estadoFacturaId: 0,
    nombreSede: '',
    sedeId: null,
    fechaInicial: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    fechaFinal: moment().format('YYYY-MM-DD'),
    numeroFactura: null,
    origen: 0,
    identificacionONombre: null,
    numeroNotaCredito: null,
  },
}
function CeldaModulosDocumentacion({modulos}) {
  return (
    <div className="w-full flex justify-evenly items-center">
      {modulos.map((modulo) => (
        <div className="w-auto">
          <BotonConImagen
            imagen={
              modulo.habilitado
                ? modulo.imagenHabilitada
                : modulo.imagenDeshabilitada
            }
            textoAlternativo={modulo.textoAlternativo}
            funcionAEjecutar={modulo.funcion}
            estilosImagen={'w-10 h-10 content-start justify-start'}
            estilosContenedor={`h-10 content-start flex-col pb-5 ${
              modulo.habilitado ? '' : 'pointer-events-none'
            }`}
          />
        </div>
      ))}
    </div>
  )
}

const DocumentacionElectronica = () => {
  const [datosFacturas, setDatosFacturas] = useState({
    datos: [],
    paginacion: {},
  })
  const [valoresFiltros, setValoresFiltros] = useState(filtrosIniciales)
  const validacionFechaMinima = moment()
    .subtract(30, 'days')
    .format('YYYY-MM-DD')
  const validacionFechaMaxima = moment().format('YYYY-MM-DD')
  const [filtrosAbierto, setFiltrosAbierto] = useState(false)

  const [fechaInicialEstado, setFechaInicialEstado] = useState(
    validacionFechaMinima
  )

  const [fechaFinalEstado, setFechaFinalEstado] = useState(
    validacionFechaMaxima
  )
  const [refetch, setRefetch] = useState(false)
  const [mensajeFiltro, setMensajeFiltro] = useState(true)
  const [sedes, setSedes] = useState([])
  const [fechaMinima, setFechaMinima] = useState(validacionFechaMinima)
  const [fechaMaxima, setFechaMaxima] = useState(validacionFechaMaxima)

  const [datosCantidadEstadosFactura, setDatosCantidadEstadosFactura] =
    useState([])

  const history = useHistory()

  const abrirDocumentoEnNuevaVentana = (url, nombreDocumento) => {
    const newWindow = window.open(url, '_blank')
    newWindow.addEventListener('load', () => {
      newWindow.document.title = nombreDocumento
    })
  }

  const obtenerDocumentoPDFDeFactura = async (
    id,
    atencionId,
    estadoFacturaId,
    nombreDocumento,
    origen
  ) => {
    try {
      let respuesta

      const SEDE = 1
      const FACTURA_GENERADA = 3
      const esSede = origen === SEDE
      const esFacturaGenerada = estadoFacturaId === FACTURA_GENERADA
      const esFacturaCENGT = /^CENGT/.test(nombreDocumento)

      if (esSede) {
        if (!esFacturaGenerada) {
          respuesta = await FachadaGenerarReciboCajaAtencion({atencionId})
        } else if (esFacturaCENGT && esFacturaGenerada) {
          respuesta = await ObtenerDocumentoPDF(id)
        } else {
          respuesta = await GenerarDocumentoFactura({facturaId: id})
        }
      } else {
        if (!esFacturaGenerada) {
          respuesta = await FachadaObtenerReciboCajaPagoPorFacturaId(id)
        } else {
          respuesta = await ObtenerDocumentoPDF(id)
        }
      }

      if (respuesta.status === 200 && respuesta?.data) {
        const url = respuesta.data?.urlDocumento || respuesta.data
        abrirDocumentoEnNuevaVentana(url, nombreDocumento)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const esRangoDeUnMes = (fechaInicial, fechaFinal) => {
    const momentFechaInicial = moment(fechaInicial)
    const momentFechaFinal = moment(fechaFinal)

    const diferenciaEnDias = momentFechaFinal.diff(momentFechaInicial, 'days')

    return diferenciaEnDias <= 30
  }

  const verificarNumeroFactura = (numeroFactura) => {
    return Boolean(/^CENGT/.test(numeroFactura))
  }

  const transformarDatosFactura = (datosFactura) => {
    const sede = 1

    return datosFactura === sede ? 'Sede' : 'Portal Cliente'
  }

  const prepararDatos = async (factura) => {
    const estadoEmision =
      (await ObtenerEstadoFacturaPorId(factura.estadoFacturaId)).Nombre ?? ''
    return {
      id: factura.id,
      atencionId: factura.atencionId,
      nombreDocumento: factura.numeroFactura,
      sede: factura.nombreSede,
      fechaEmision: moment(factura.fechaEmision).format('YYYY-MM-DD'),
      nombreTercero: {
        Fila1: factura.identificacion,
        Fila2: factura.nombreCliente,
      },
      valorTotal: factura.montoTotal,
      estadoEmision: estadoEmision,
      origen: transformarDatosFactura(factura.origen),
      errorTransmision: factura.cantidadErroresFactura,
      numeroNotaCredito: factura.numeroNotaCredito,
      modulos: [
        {
          imagenHabilitada: imprimirHabilitado,
          imagenDeshabilitada: imprimirDeshabilitado,
          textoAlternativo: 'imprimir',
          funcion: () =>
            obtenerDocumentoPDFDeFactura(
              factura.id,
              factura.atencionId,
              factura.estadoFacturaId,
              factura.numeroFactura,
              factura.origen
            ),
          habilitado: Boolean(
            (!verificarNumeroFactura(factura.numeroFactura) &&
              factura.estadoFacturaId !== ESTADOS_FACTURA.NO_ENVIADA) ||
              (verificarNumeroFactura(factura.numeroFactura) &&
                factura.estadoFacturaId === ESTADOS_FACTURA.DOCUMENTO_GENERADO)
          ),
        },
      ],
    }
  }

  const obtenerTodosLosDatos = () => {
    if (esRangoDeUnMes(fechaInicialEstado, fechaFinalEstado)) {
      FachadaObtenerFacturasEnviadasPaginado(valoresFiltros).then(
        async (res) => {
          if (res.status !== 204) {
            let facturas = await Promise.all(
              res.data.datos.map(
                async (factura) => await prepararDatos(factura)
              )
            )
            setDatosFacturas({datos: facturas, paginacion: res.data.paginacion})
          } else {
            setDatosFacturas({datos: [], paginacion: {}})
          }
        }
      )
      setMensajeFiltro(true)
    } else {
      setMensajeFiltro(false)
    }
  }

  const obtenerDatosEstadosFactura = () => {
    if (esRangoDeUnMes(fechaInicialEstado, fechaFinalEstado)) {
      FachadaObtenerConteoDeEstadosFactura(
        fechaInicialEstado,
        fechaFinalEstado
      ).then((res) => {
        setDatosCantidadEstadosFactura([
          {
            titulo: 'ENVIADO PENDIENTE DE RESPUESTA',
            cantidad: res.data.enviadoPendienteRespuesta.cantidad ?? 0,
            total: res.data.enviadoPendienteRespuesta.valorTotal ?? 0,
          },
          {
            titulo: 'DOCUMENTO GENERADO',
            cantidad: res.data.documentoGenerado.cantidad ?? 0,
            total: res.data.documentoGenerado.valorTotal ?? 0,
          },
          {
            titulo: 'DOCUMENTO NO GENERADO REQUIERE CORRECCIÓN',
            cantidad: res.data.requiereCorreccion.cantidad ?? 0,
            total: res.data.requiereCorreccion.valorTotal ?? 0,
          },
          {
            titulo: 'DOCUMENTO GENERADO PENDIENTE CUFE (DIAN) ',
            cantidad: res.data.pendienteCUFE.cantidad ?? 0,
            total: res.data.pendienteCUFE.valorTotal ?? 0,
          },
        ])
      })
    }
  }

  useEffect(() => {
    obtenerDatosEstadosFactura()
  }, [fechaInicialEstado, fechaFinalEstado])

  useEffect(() => {
    setValoresFiltros((prv) => ({
      ...prv,
      tamano: 10,
      pagina: 1,
      filtros: {
        ...prv.filtros,
        fechaFinal: fechaFinalEstado,
        fechaInicial: fechaInicialEstado,
      },
    }))
    setRefetch(true)
  }, [fechaInicialEstado, fechaFinalEstado])

  useEffect(() => {
    if (!refetch) return
    setRefetch(false)
    obtenerTodosLosDatos()
  }, [refetch])

  const abrirCorregirErroresEnFactura = (id) => {
    history.push(
      RUTAS_APP.GESTION_FACTURACION
        .CORRECCION_ERRORES_TRANSMISION_FACTURA_ELECTRONICA,
      {id: id}
    )
  }

  let columns = [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Documento electrónico'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'nombreDocumento',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Origen'} />,
      accessor: 'origen',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 ',
      Filter: FiltroListaSeleccionar,
      disableFilters: true,
      filter: 'lista',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Sede'} />,
      accessor: 'sede',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      disableFilters: true,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Fecha emisión electrónica'} />,
      accessor: 'fechaEmision',
      className: 'w-10%',
      headerStyle:
        "flex justify-center items-center bg-cendiatra-verde-5 h-24 text-white text-14px'",
      disableFilters: true,
      filter: 'fechaCustom',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Identificación y nombre tercero'} />
      ),
      accessor: 'nombreTercero',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 ',
      disableFilters: true,
      filter: 'dobleTexto',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => <CeldaDobleTexto datos={row.row.original.nombreTercero} />,
    },

    {
      Header: (row) => <EncabezadoFondo titulo={'Valor Total'} />,
      accessor: 'valorTotal',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={`text-center appearance-none ${
            row.row.original.errorTransmision > 0 ? 'bg-red-200' : ''
          } rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
          valor={row.row.original.valorTotal}
        />
      ),
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Estado de emisión electrónica'} />
      ),
      accessor: 'estadoEmision',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 ',
      Filter: FiltroListaSeleccionar,
      disableFilters: true,
      filter: 'lista',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Descripción error transmisión y/o rechazada'}
        />
      ),
      accessor: 'errorTransmision',
      className: 'w-10% ',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 ',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: ({row: {original}, value}) => (
        <CeldaConEventoClick
          texto={Boolean(value > 0) ? ` Ver ${value} errores` : ''}
          manejarClick={() => abrirCorregirErroresEnFactura(original.id)}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nota Crédito'} />,
      accessor: 'numeroNotaCredito',
      className: 'w-10%',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoConSubTitulos titulo={'Módulos'} subtitulo1={'Imprimir'} />
      ),
      accessor: 'modulos',
      className:
        'w-10% bg-white h-full flex justify-center items-center h-full',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-24 rounded-r-lg',
      disableFilters: true,
      Cell: (row) => (
        <CeldaModulosDocumentacion modulos={row.row.original.modulos} />
      ),
    },
  ]
  const manejarCambioEnFiltro = (e) => {
    setValoresFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: agregarFormatoFecha(e),
        },
      }
    })
  }

  useEffect(() => {
    const configSedes = async () => {
      const response = await ObtenerTodasLasSedes()
      setSedes(response.map((r) => ({id: r.Id, filtro: r.Nombre})))
    }
    configSedes()
  }, [])

  const filtrosExternos = [
    {
      id: 1,
      estilos: 'w-15%',
      titulo: 'Documento electrónico',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroFactura',
      formato: 'input',
      valor: valoresFiltros.filtros.numeroFactura,
    },
    {
      id: 2,
      estilos: 'w-15%',
      titulo: 'Origen',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'origen',
      formato: 'lista',
      valor: valoresFiltros.filtros.origen,
      opciones: ORIGENES_FACTURAS.map((org) => ({
        Id: org.id,
        Nombre: org.nombre,
      })),
    },
    {
      id: 3,
      estilos: 'w-15%',
      titulo: 'Sede',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'sedeId',
      formato: 'autocompletar',
      valor: valoresFiltros.filtros.sedeId,
      valorCampo: valoresFiltros.filtros.nombreSede,
      opciones: [],
      funcionManejarCambio: (e) => {
        setValoresFiltros((prv) => ({
          ...prv,
          filtros: {
            ...prv.filtros,
            nombreSede: e.target.value,
            sedeId: null,
          },
        }))
      },
      funcionOpcionSeleccionada: (opt) => {
        setValoresFiltros((prv) => ({
          ...prv,
          filtros: {
            ...prv.filtros,
            nombreSede: opt?.filtro ?? '',
            sedeId: opt?.id ?? null,
          },
        }))
      },
      informacionFiltrada: sedes.filter((s) =>
        s.filtro
          .toUpperCase()
          .includes(valoresFiltros.filtros.nombreSede.toUpperCase())
      ),
    },
    {
      id: 4,
      estilos: 'w-15%',
      titulo: 'Identificación y nombre tercero',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'identificacionONombre',
      formato: 'input',
      valor: valoresFiltros.filtros.identificacionONombre,
    },
    {
      id: 5,
      estilos: 'w-15%',
      titulo: 'Estado de emisión electrónica',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'estadoFacturaId',
      formato: 'lista',
      valor: valoresFiltros.filtros.estadoFacturaId,
      opciones: [{id: 0, nombre: 'TODOS'}, ...LISTA_ESTADOS_FACTURA].map(
        (item) => ({
          Id: item.id,
          Nombre: item.nombre,
        })
      ),
    },
    {
      id: 5,
      estilos: 'w-15%',
      titulo: 'Nota crédito',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroNotaCredito',
      formato: 'input',
      valor: valoresFiltros.filtros.numeroNotaCredito,
    },
  ]

  const actualizarFecha = (valor, fechaInicial) => {
    if (fechaInicial) {
      setFechaInicialEstado(valor)
      setFechaMaxima(moment().format('YYYY-MM-DD'))
      if (moment(valor).isAfter(fechaFinalEstado)) {
        setFechaFinalEstado('')
        const inputFechaFinal = document.getElementById('fechaFinal')
        inputFechaFinal.value = ''
      }
      setFechaMinima(valor)
    } else {
      setFechaFinalEstado(valor)
      setFechaMinima(moment(valor).subtract(1, 'month').format('YYYY-MM-DD'))
      if (moment(valor).isBefore(fechaInicialEstado)) {
        setFechaInicialEstado('')
        const inputFechaInicial = document.getElementById('fechaInicial')
        inputFechaInicial.value = ''
        setFechaMaxima(valor)
      }
    }
  }

  useEffect(() => {
    const fechaActual = moment()
    setFechaMaxima(fechaActual.format('YYYY-MM-DD'))
  }, [])

  const manejarObtenerDatosExportar = async () => {
    try {
      const respuesta = await FachadaObtenerFacturas(valoresFiltros.filtros)

      if (respuesta.status === 204) return []

      return await Promise.all(
        respuesta?.data?.map(async (factura) => ({
          ...(await prepararDatos(factura)),
          nombreTercero:
            `${factura.identificacion} - ${factura.nombreCliente}`.toUpperCase(),
        }))
      )
    } catch (error) {
      console.log(error)
      return []
    }
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      <div className="w-full flex flex-wrap justify-between items-start">
        <div className="w-3/12 flex flex-col gap-3 items-center rounded-lg border border-cendiatra p-3">
          <div className="flex flex-row justify-between w-full mt-5">
            <InputFiltroFecha
              titulo={'Fecha Inicial'}
              funcion={(e) => actualizarFecha(e.target.value, true)}
              fechaMaxima={fechaMaxima}
              valorPorDefecto={fechaInicialEstado}
              id={'fechaInicial'}
            />
            <InputFiltroFecha
              titulo={'Fecha Final'}
              funcion={(e) => actualizarFecha(e.target.value, false)}
              fechaMaxima={fechaMaxima}
              fechaMinima={fechaInicialEstado}
              valorPorDefecto={fechaFinalEstado}
              id={'fechaFinal'}
            />
          </div>
          <p
            className={`${
              mensajeFiltro ? 'text-cendiatra-gris-1' : 'text-red-500'
            } flex flex-row mt-5`}
          >
            <span className=" text-cendiatra text-2xl">*</span>Ten en cuenta que
            al usar este filtro el intervalo máximo de consulta es de 30 días
            entre la fecha inicial y la fecha final¨
          </p>
        </div>
        <div className="w-5/12 flex justify-between items-center">
          <CuadroInformativoConSumatoria datos={datosCantidadEstadosFactura} />
        </div>
        <div className="w-3/12 h-48 flex flex-wrap justify-center content-end text-center">
          <ExportarCSV
            encabezados={encabezadosFacturas}
            obtenerDatos={manejarObtenerDatosExportar}
            nombreArchivo="Documentacion_electronica.csv"
          />
          <span className="text-gray-400 text-xs w-full">Exportar Excel</span>
        </div>
      </div>
      <div className="w-full flex justify-start items-center">
        <ComponenteConjuntoDeFiltros
          filtrosPorCrear={filtrosExternos}
          funcionBoton={() => obtenerTodosLosDatos()}
          ejecutarAbiertoMenu={(valor) => setFiltrosAbierto(valor)}
        />
      </div>
      <TablaConPaginacionYFiltros
        data={
          datosFacturas?.datos?.sort(
            (a, b) => a.fechaEmision - b.fechaEmision
          ) ?? []
        }
        columns={columns}
        rowProps={(row) => ({
          style: {
            backgroundColor: row.original.errorTransmision > 0 ? '#fecaca' : '',
          },
        })}
        filasPorPagina={10}
        encabezados={encabezadosFacturas}
        fechaInicial={fechaInicialEstado}
        fechaFinal={fechaFinalEstado}
        quitarMtCsv={filtrosAbierto}
        paginacionBackend={true}
        parametrosPaginacionConBackEnd={datosFacturas?.paginacion}
        funcionCambiarPagina={(numeroPagina) => {
          setValoresFiltros((prv) => ({...prv, pagina: numeroPagina}))
          setRefetch(true)
        }}
        funcionLimpiarFiltro={() => {
          setValoresFiltros(filtrosIniciales)
          setRefetch(true)
        }}
        maximoDePaginasparaMostrar={10}
        botonLimpiarFiltros
        usarSort={false}
      />
    </div>
  )
}

export default DocumentacionElectronica
