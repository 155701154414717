import {ObtenerServicios} from '../../microservicios/Servicio'
import {dbComercial} from '../bases-datos/dbComercial'
import ServiciosWorker from './Servicios.worker.js'

export const AgregarServiciosACache = async (onProgress) => {
  await dbComercial.open()

  const fechaUltimaActualizacion = (
    await dbComercial.table('FechasActualizacion').get({Tabla: 'Servicios'})
  )?.FechaUltimaActualizacion

  const cups = await dbComercial.table('Cups').toArray()
  const res = await ObtenerServicios(fechaUltimaActualizacion)

  if (!Array.isArray(res.data)) return

  const worker = new ServiciosWorker()

  worker.addEventListener('message', (e) => {
    if (e.data.type === 'progreso') {
      onProgress?.(parseFloat(e.data.porcentaje))
    } else if (e.data.type === 'completo') {
      console.log('Carga de servicios completada')
    } else if (e.data.type === 'error') {
      console.error('Error desde el worker de servicios:', e.data.mensaje)
    }
  })

  await worker.cargarServicios(res.data, cups, res.headers.tiemposervidor)
}

export const ObtenerTodosLosServicios = async () => {
  return Promise.resolve(dbComercial.table('Servicios').toArray())
}

export const ObtenerServiciosPorNombre = async (filtro) => {
  let serviciosPorNombre = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
    codigo: elemento.CodigoCompleto,
    nombre: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorNombre)
}

export const ObtenerServicioPorNombre = async (nombre) => {
  return Promise.resolve(dbComercial.table('Servicios').get({Nombre: nombre}))
}

export const ObtenerServicioPorId = async (id) => {
  return Promise.resolve(dbComercial.table('Servicios').get({Id: id}))
}

export const ObtenerServiciosPorCodigo = async (filtro) => {
  let serviciosPorCodigo = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({CodigoCompleto}) =>
          CodigoCompleto.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.CodigoCompleto,
    codigo: elemento.CodigoCompleto,
    nombre: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorCodigo)
}

export const ObtenerUnicoServicioPorNombre = async (filtro) => {
  let serviciosPorNombre = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({Nombre}) =>
          Nombre.trim().toUpperCase() === filtro.trim().toUpperCase()
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorNombre)
}

export const ObtenerUnicaSiglaStickerPorNombre = async (filtro) => {
  let serviciosPorNombre = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({SiglaSticker}) =>
          SiglaSticker.trim().toUpperCase() === filtro.trim().toUpperCase()
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
  }))
  return Promise.resolve(serviciosPorNombre)
}

export const obtenerServiciosPorIds = (ids) => {
  if (Array.isArray(ids)) {
    return dbComercial
      .table('Servicios')
      .filter((servicio) => ids.includes(servicio.Id))
      .toArray()
  }

  return Promise.resolve([])
}

export const ObtenerServiciosPorNombreOCodigo = async (filtro) => {
  let servicios = (
    await dbComercial
      .table('Servicios')
      .filter(
        ({Nombre, CodigoCompleto}) =>
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1 ||
          CodigoCompleto.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
    codigo: elemento.CodigoCompleto,
  }))
  return Promise.resolve(servicios)
}
