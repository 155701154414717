import {dbComercial} from '../bases-datos/dbComercial'
import {ObtenerFuenteDatosPlantillas} from '../../microservicios/Referencia'
import FuentesWorker from './FuentesDeDatos.worker.js'

export const AgregarFuentesDeDatosACache = async (onProgress) => {
  await dbComercial.open()

  const fechaUltimaActualizacion = (
    await dbComercial
      .table('FechasActualizacion')
      .get({Tabla: 'FuentesDeDatos'})
  )?.FechaUltimaActualizacion

  const res = await ObtenerFuenteDatosPlantillas(fechaUltimaActualizacion)

  if (!Array.isArray(res.data)) return

  const worker = new FuentesWorker()

  worker.addEventListener('message', (e) => {
    if (e.data.type === 'progreso') {
      onProgress?.(parseFloat(e.data.porcentaje))
    } else if (e.data.type === 'completo') {
      console.log('Carga de fuentes de datos completada')
    } else if (e.data.type === 'error') {
      console.error(
        'Error desde el worker de fuentes de datos:',
        e.data.mensaje
      )
    }
  })

  await worker.cargarFuentesDeDatos(res.data, res.headers.tiemposervidor)
}

export const ObtenerFuenteDeDatosPorId = async (id) => {
  return Promise.resolve(
    dbComercial.table('FuentesDeDatos').get({Id: parseInt(id)})
  )
}

export const ObtenerFuenteDeDatosPorNombre = async (nombre) => {
  if (!nombre) {
    return null
  }
  return Promise.resolve(
    dbComercial.table('FuentesDeDatos').get({Nombre: nombre})
  )
}

export const ObtenerFuentesDeDatos = async () => {
  return Promise.resolve(dbComercial.table('FuentesDeDatos').toArray())
}

export const ObtenerAutocompletarFuenteDeDatosPorNombre = async (
  nombre,
  valorFiltro
) => {
  if (!nombre) {
    return []
  }
  let opciones = await dbComercial.table('FuentesDeDatos').get({Nombre: nombre})

  let opcionesFiltradas = opciones.Opciones.filter(
    (x) => x.nombre.toUpperCase().indexOf(valorFiltro?.toUpperCase()) > -1
  )
  return Promise.resolve(opcionesFiltradas)
}

export const ObtenerAutocompletarFuenteDeDatosPorId = async (
  nombre,
  valorFiltro
) => {
  if (!nombre) {
    return null
  }
  let opciones = await dbComercial.table('FuentesDeDatos').get({Nombre: nombre})

  let opcionFiltrada =
    opciones.Opciones.find((x) => x.identificacion === valorFiltro) ?? null
  return Promise.resolve(opcionFiltrada)
}
