import moment from 'moment'
import React, {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {ConfirmacionResolucionCreada} from '../../componentes/resolucionFirmaElectronica/ConfirmacionResolucionCreada'
import {FormularioResolucionFirmaElectronica} from '../../componentes/resolucionFirmaElectronica/FormularioResolucionFirmaElectronica'
import {FORMATO_FECHA_INGLES} from '../../constantes/formatos'
import {RUTAS_APP} from '../../constantes/rutas/rutasApp'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {
  ContextApplication,
  ContextoResolucionFirmaElectronica,
} from '../../contexto'
import {useModal} from '../../hooks/useModal'
import {
  actualizarResolucion,
  crearResolucion,
  obtenerResolucionPorId,
} from '../../microservicios/ResolucionFirmaElectronica'
import {TEXTO_FEATURE_FLAGS} from '../../constantes/featureFlags'
import {useFeatureIsOn} from '@growthbook/growthbook-react'
import clsx from 'clsx'

const TEXTO_PANTALLA = TEXTO_VISUAL.RESOLUCION_FIRMA_ELECTRONICA

export const DefinirResolucionFirmaElectronica = () => {
  const history = useHistory()
  const [cerrarModal] = useModal()
  const contextoApp = useContext(ContextApplication)
  const contextoResolucionFirmaElectronica = useContext(
    ContextoResolucionFirmaElectronica
  )
  const activoGestionResoluciones = useFeatureIsOn(
    TEXTO_FEATURE_FLAGS.ACTIVO_GESTION_RESOLUCIONES
  )

  const manejarGuardar = async (resolucion) => {
    if (!contextoResolucionFirmaElectronica.actualizar) {
      resolucion.id = await crearResolucion({...resolucion, id: undefined})
    } else {
      await actualizarResolucion(resolucion)
    }

    contextoApp.setModal({
      abierto: true,
      titulo: TEXTO_PANTALLA.MODAL.GUARDADO_EXITOSO.TITULO(
        !contextoResolucionFirmaElectronica.actualizar
      ),
      contenido: <ConfirmacionResolucionCreada resolucion={resolucion} />,
      botones: [
        {
          nombre: TEXTO_VISUAL.BOTONES.FINALIZAR,
          click: () => {
            cerrarModal()
            history.push('/main')
          },
        },
        {
          nombre: TEXTO_VISUAL.BOTONES.VOLVER,
          click: () => {
            cerrarModal()
            history.replace(RUTAS_APP.RESOLUCIONES_FIRMA_ELECTRONICA.RAIZ)
          },
        },
      ],
    })
  }

  useEffect(() => {
    const actualizar =
      contextoResolucionFirmaElectronica.resolucionId !== undefined

    if (actualizar) {
      obtenerResolucionPorId(contextoResolucionFirmaElectronica.resolucionId)
        .then((resolucion) =>
          contextoResolucionFirmaElectronica.setFormulario({
            ...resolucion,
            vencida: undefined,
            proximaVencer: undefined,
            fechaInicial: moment(resolucion.fechaInicial).format(
              FORMATO_FECHA_INGLES
            ),
            fechaFinal: moment(resolucion.fechaFinal).format(
              FORMATO_FECHA_INGLES
            ),
          })
        )
        .finally(() =>
          contextoResolucionFirmaElectronica.setActualizar(actualizar)
        )
    } else {
      contextoResolucionFirmaElectronica.setActualizar(actualizar)
    }

    return () => {
      contextoResolucionFirmaElectronica.setActualizar(false)
      contextoResolucionFirmaElectronica.setResolucionId(undefined)
      contextoResolucionFirmaElectronica.setFormulario({})
    }
  }, [contextoResolucionFirmaElectronica.resolucionId])

  const tituloPagina = !contextoResolucionFirmaElectronica.resolucionId
    ? TEXTO_PANTALLA.TITULO.CARGAR_RESOLUCION
    : TEXTO_PANTALLA.TITULO.MODIFICAR_RESOLUCION

  return (
    <ContenedorPagina
      tituloPagina={tituloPagina}
      estilosContenedor={clsx(
        'w-5/6',
        !activoGestionResoluciones && 'pointer-events-none'
      )}
    >
      <FormularioResolucionFirmaElectronica manejadorGuardar={manejarGuardar} />
    </ContenedorPagina>
  )
}
