import {ObtenerCups} from '../../microservicios'
import {dbComercial} from '../bases-datos/dbComercial'
import CupsWorker from './Cups.worker.js'

export const AgregarCupsSACache = async (sinfecha = false, onProgress) => {
  await dbComercial.open()

  const fechaUltimaActualizacion = (
    await dbComercial.table('FechasActualizacion').get({Tabla: 'Cups'})
  )?.FechaUltimaActualizacion

  const res = await ObtenerCups(sinfecha ? undefined : fechaUltimaActualizacion)

  if (!Array.isArray(res.data)) return

  const worker = new CupsWorker()

  worker.addEventListener('message', (e) => {
    if (e.data.type === 'progreso') {
      onProgress?.(parseFloat(e.data.porcentaje))
    } else if (e.data.type === 'completo') {
      console.log('Carga de CUPS completada')
    } else if (e.data.type === 'error') {
      console.error('Error desde el worker de CUPS:', e.data.mensaje)
    }
  })

  await worker.cargarCups(res.data, !sinfecha)
}
