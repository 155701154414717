import React, {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ContextoRegistro, ContextApplication} from '../../../contexto'
import {Input, ListaDesplegable} from '../../inputs'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import {
  ObtenerEpsPorNombre,
  ObtenerArlPorNombre,
  ObtenerFondoDePensionPorNombre,
  ObtenerTodosLosParentescos,
} from '../../../cache/servicios-cache'
import {regexBasicoV1} from '../../../constantes/regex'
import {TEXTO_VISUAL} from '../../../constantes'
import clsx from 'clsx'

const InformacionGeneral = ({numeroPagina, deshabilitarEdicion = false}) => {
  const {
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const contextoRegistro = useContext(ContextoRegistro)
  const contextoAplicacion = useContext(ContextApplication)

  const [epsFiltrada, setEpsFiltrada] = useState([])
  const [arlFiltrada, setArlFiltrada] = useState([])
  const [fondoPensionFiltrados, setFondosPensionFiltrados] = useState([])

  const [parentesco, setParentesco] = useState([])

  useEffect(() => {
    trigger()
  }, [])

  useEffect(async () => {
    setParentesco(await ObtenerTodosLosParentescos())
  }, [])
  useEffect(() => {
    reset(contextoRegistro.informacionGeneral)
    trigger()
  }, [contextoRegistro.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoRegistro.numeroPagina) {
      trigger()
      contextoRegistro.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
  }, [isValid, contextoRegistro.numeroPagina])

  useEffect(() => {
    const subscription = watch(() => {
      contextoRegistro.setInformacionGeneral(getValues())
      trigger()
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const manejarEpsCambiada = (e) => {
    contextoRegistro.setInformacionGeneral({
      ...contextoRegistro.informacionGeneral,
      eps: e.target.value,
    })
    setError('eps', {type: 'require', message: ''})
    setValue('eps')
    if (e.target.value.length >= 3) {
      ObtenerEpsPorNombre(e.target.value).then((res) => {
        if (res) {
          setEpsFiltrada(
            res.map((ciudad) => ({
              id: ciudad.Id,
              filtro: ciudad.Nombre.toUpperCase(),
            }))
          )
        }
      })
    } else {
      setEpsFiltrada([])
    }
  }

  const manejarEpsSeleccionada = (epsSeleccionado) => {
    contextoRegistro.setInformacionGeneral({
      ...contextoRegistro.informacionGeneral,
      eps: epsSeleccionado.filtro,
    })
    setValue('eps', epsSeleccionado.filtro)
    clearErrors('eps')
    setEpsFiltrada([])
  }

  const manejarArlCambiada = (e) => {
    contextoRegistro.setInformacionGeneral({
      ...contextoRegistro.informacionGeneral,
      arl: e.target.value,
    })
    setError('arl', {type: 'require', message: ''})
    setValue('arl')
    if (e.target.value.length >= 3) {
      ObtenerArlPorNombre(e.target.value).then((res) => {
        if (res) {
          setArlFiltrada(
            res.map((ciudad) => ({
              id: ciudad.Id,
              filtro: ciudad.Nombre,
            }))
          )
        }
      })
    } else {
      setArlFiltrada([])
    }
  }

  const manejarArlSeleccionada = (arlSeleccionado) => {
    contextoRegistro.setInformacionGeneral({
      ...contextoRegistro.informacionGeneral,
      arl: arlSeleccionado.filtro,
    })
    setValue('arl', arlSeleccionado.filtro)
    clearErrors('arl')
    setEpsFiltrada([])
  }

  const manejarFondoDePensionCmabiado = (e) => {
    contextoRegistro.setInformacionGeneral({
      ...contextoRegistro.informacionGeneral,
      fondoPension: e.target.value,
    })
    setError('fondoPension', {type: 'require', message: ''})
    setValue('fondoPension')
    if (e.target.value.length >= 3) {
      ObtenerFondoDePensionPorNombre(e.target.value).then((res) => {
        if (res) {
          setFondosPensionFiltrados(
            res.map((ciudad) => ({
              id: ciudad.Id,
              filtro: ciudad.Nombre.toUpperCase(),
            }))
          )
        }
      })
    } else {
      setFondosPensionFiltrados([])
    }
  }

  const manejarFondoDePensionSeleccionado = (fondoDePensionSeleccionado) => {
    contextoRegistro.setInformacionGeneral({
      ...contextoRegistro.informacionGeneral,
      fondoPension: fondoDePensionSeleccionado.filtro,
    })
    setValue('fondoPension', fondoDePensionSeleccionado.filtro)
    clearErrors('fondoPension')
    setArlFiltrada([])
  }

  return (
    <div
      className={`w-full flex flex-wrap justify-between items-center my-2 ${
        deshabilitarEdicion ? 'pointer-events-none' : ''
      }`}
    >
      <div className="w-full flex justify-between items-center my-2">
        <Controller
          name="eps"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-31%'}
              titulo={'EPS*'}
              estilosInput={
                Object.keys(errors).find((element) => element === 'eps')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'Autocompletar'}
              valorDelCampoFiltro={contextoRegistro.informacionGeneral.eps}
              desactivarOtroFiltro={''}
              informacionFiltrada={epsFiltrada}
              handleChange={manejarEpsCambiada}
              handleOptionChange={manejarEpsSeleccionada}
            />
          )}
        />
        <Controller
          name="arl"
          control={control}
          rules={{
            required: false,
          }}
          render={() => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-31%'}
              titulo={'ARL'}
              estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10"
              tipo={'text'}
              placeholder={'Autocompletar'}
              valorDelCampoFiltro={contextoRegistro.informacionGeneral.arl}
              desactivarOtroFiltro={''}
              informacionFiltrada={arlFiltrada}
              handleChange={manejarArlCambiada}
              handleOptionChange={manejarArlSeleccionada}
            />
          )}
        />

        <Controller
          name="fondoPension"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <CampoFiltrarEspañol
              titulo={'Fondo de pensiones*'}
              estilosPersonalizados={'w-31%'}
              textoTitulo={'text-sm font-medium'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'fondoPension'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              placeholder={'Autocompletar'}
              tipo={'text'}
              valorDelCampoFiltro={
                contextoRegistro.informacionGeneral.fondoPension
              }
              desactivarOtroFiltro={''}
              informacionFiltrada={fondoPensionFiltrados}
              handleChange={manejarFondoDePensionCmabiado}
              handleOptionChange={manejarFondoDePensionSeleccionado}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-between items-center my-2">
        <Controller
          name="raza"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'raza')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Raza*'}
              opciones={contextoAplicacion.razas}
              valor={value}
            />
          )}
        />
        <Controller
          name="acompanante"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'acompanante'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Acompañante'}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.acompanante?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />

        <Controller
          name="telAcompanante"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              tipo={'number'}
              placeholder={'Descripción'}
              titulo={'Teléfono acompañante'}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-between items-center mt-2 mb-50px">
        <Controller
          name="parentesco"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              titulo={'Parentesco'}
              opciones={parentesco}
              valor={value}
            />
          )}
        />
        <Controller
          name="discapacidad"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'discapacidad'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Discapacidad'}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.discapacidad?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
        <Controller
          name="tipoPoblacion"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={clsx(
                'appearance-none rounded relative block border w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border-cendiatra-rojo-1':
                    Object.keys(errors).includes('tipoPoblacion'),
                  'border-cendiatra':
                    !Object.keys(errors).includes('tipoPoblacion'),
                }
              )}
              titulo={'Tipo de población*'}
              opciones={contextoAplicacion.tiposPoblacion}
              valor={value}
            />
          )}
        />
      </div>
    </div>
  )
}

export default InformacionGeneral
