import React, {useContext, useState, useEffect, useRef} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {Input, ListaDesplegable, ErroresInput} from '../../inputs'
import {ContextoRegistro, ContextApplication} from '../../../contexto'
import moment from 'moment'
import {
  ObtenerTiposDeDocumento,
  AgregarEmpresasACache,
  AgregarTarifariosACache,
  AgregarServiciosACache,
  AgregarPaquetesACache,
  ObtenerDepartamentoPorId,
  ObtenerCiudadPorId,
  ObtenerLocalidadPorId,
} from '../../../cache/servicios-cache'
import {
  ObtenerInformacionPaciente,
  ValidarDocumentoUsuarioId,
} from '../../../microservicios'
import IconoAlerta from '../../../imagenes/alerta.svg'
import {
  ALERTAS,
  TEXTO_VISUAL,
  regexSoloLetrasYNumeros,
} from '../../../constantes'
import {
  regexBasicoV1,
  regexTextoCaracteresBasicos,
} from '../../../constantes/regex'
import {AlertaDinamica, AlertaLongitudDocumento} from '../../MostrarInformacion'
import {obtenerFechaMinima} from '../../../utilidades'
import clsx from 'clsx'

const ModificarInformacionPersonalPaciente = ({actualizarDatosIniciales}) => {
  const {
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const anioFechMinima = 99

  const contextoRegistro = useContext(ContextoRegistro)
  const contextoAplicacion = useContext(ContextApplication)

  const [tipoDocumento, setTipoDocumento] = useState([])
  const [pattern, setPattern] = useState('')

  const fechaDeHoy = moment().format().slice(0, -15)

  const [infoNumeroCaracteres, setInfoNumeroCaracteres] = useState({
    minimo: 6,
    maximo: 10,
    mensaje: '',
    tipoCampo: 'text',
  })

  const [fechaEsValida, setFechaEsValida] = useState(false)

  useEffect(() => {
    if (getValues('fechaNacimiento')) {
      const fechaNacimiento = moment(getValues('fechaNacimiento'))
      const fechaMinima = moment()
        .subtract(anioFechMinima, 'years')
        .startOf('day')
        .subtract(1, 'day')
      setFechaEsValida(!fechaNacimiento.isAfter(fechaMinima))
    }
  }, [getValues('fechaNacimiento'), fechaEsValida])

  useEffect(async () => {
    setTipoDocumento(await ObtenerTiposDeDocumento())
  }, [])

  useEffect(async () => {
    await AgregarEmpresasACache()
    await AgregarTarifariosACache()
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    trigger()
  }, [])

  useEffect(() => {
    trigger()
    contextoRegistro.setformularioActualTieneErrores(
      !isValid || Object.keys(errors).length || fechaEsValida
    )

    contextoRegistro.setErroresModificarFormulario({
      ...contextoRegistro.erroresModificarFormulario,
      personal: !isValid || Object.keys(errors).length || fechaEsValida,
    })

    contextoRegistro.setModificarPacienteInformacionPersonal(getValues())
  }, [fechaEsValida, isValid, Object.keys(errors).length])

  useEffect(() => {
    reset(contextoRegistro.modificarPacienteInformacionPersonal)
    trigger()
  }, [contextoRegistro.actualizar, infoNumeroCaracteres])

  useEffect(() => {
    if (contextoRegistro.modificarPacienteNumeroDocumento.length > 4) {
      patternReglas(contextoRegistro.modificarPacienteNumeroDocumento)
      if (
        contextoRegistro.modificarPacienteNumeroDocumento &&
        contextoRegistro.modificarPacienteTipoDocumentoId
      ) {
        ValidarDocumentoUsuarioId(
          contextoRegistro.modificarPacienteTipoDocumentoId,
          contextoRegistro.modificarPacienteNumeroDocumento,
          contextoRegistro.modificarPacienteUsuarioId
        )
          .then((res) => {
            if (!res.data) {
              setError('usuarioExiste', {
                type: 'existent',
                message:
                  'El número de documento y tipo de documento ya se encuentra registrado',
              })
            } else {
              clearErrors('usuarioExiste')
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [
    contextoRegistro.modificarPacienteNumeroDocumento,
    contextoRegistro.modificarPacienteTipoDocumentoId,
  ])

  useEffect(() => {
    const obtenerDatosPaciente = async () => {
      if (contextoRegistro.modificarPacienteUsuarioId) {
        try {
          const respuesta = await ObtenerInformacionPaciente(
            contextoRegistro.modificarPacienteUsuarioId,
            false
          )
          if (respuesta.status === 200) {
            contextoRegistro.setModificarPacienteNumeroDocumento(
              respuesta.data.documento
            )
            contextoRegistro.setModificarPacienteTipoDocumentoId(
              respuesta.data.tipoDocumentoId
            )

            const datosInformacionPersonal = {
              numeroDocumento: respuesta.data.documento,
              tipoDocumento: respuesta.data.tipoDocumentoId,
              tipoPoblacion: respuesta.data.tipoPoblacionId,
              apellidos: respuesta.data.apellidos,
              nombre: respuesta.data.nombre,
              grupoSanguineo: respuesta.data.grupoSanguineoId,
              fechaNacimiento: respuesta.data.fechaNacimiento
                ? moment(respuesta.data.fechaNacimiento).format('YYYY-MM-DD')
                : '',
              sexo: respuesta.data.sexoId,
              estadoCivil: respuesta.data.estadoCivilId,
              lugarNacimiento: respuesta.data.lugarNacimiento,
              escolaridad: respuesta.data.escolaridadId,
              nacionalidad: respuesta.data.nacionalidad,
            }

            contextoRegistro.setModificarPacienteInformacionPersonal(
              datosInformacionPersonal
            )

            let informacionDireccion = {}

            if (respuesta.data.direccion) {
              informacionDireccion = {
                ...contextoRegistro.informacionDireccion,
                celular: respuesta.data.direccion.celular ?? '',
                correoElectronico: respuesta.data.direccion.correo ?? '',
                departamento: (
                  await ObtenerDepartamentoPorId(
                    respuesta.data.direccion.departamentoId
                  )
                ).Nombre,
                direccion: respuesta.data.direccion.direccion ?? '',
                estrato: respuesta.data.direccion.estratoId ?? '',
                indicativoCelular: respuesta.data.direccion.indicativo ?? '',
                localidad: respuesta.data.direccion.localidadId
                  ? (
                      await ObtenerLocalidadPorId(
                        respuesta.data.direccion.localidadId
                      )
                    ).Nombre
                  : null,
                municipio: (
                  await ObtenerCiudadPorId(respuesta.data.direccion.municipioId)
                )[0]?.Nombre,
                paisResidencia: 'COLOMBIA',
                telefono: respuesta.data.direccion.telefono ?? '',
                zona: respuesta.data.direccion.zonaId ?? '',
              }
              contextoRegistro.setInformacionDireccion(informacionDireccion)
            }

            const informacionFirmaFotoHuella = {
              foto: respuesta.data.urlFoto,
              firma: respuesta.data.urlFirma,
            }

            contextoRegistro.setFoto(informacionFirmaFotoHuella.foto ?? '')

            contextoRegistro.setFirma(informacionFirmaFotoHuella.firma ?? '')

            contextoRegistro.setActualizar(!contextoRegistro.actualizar)

            actualizarDatosIniciales({
              informacionPersonal: datosInformacionPersonal,
              informacionDireccion: informacionDireccion,
              firmaFotoHuella: informacionFirmaFotoHuella,
            })
            trigger()
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    obtenerDatosPaciente()
  }, [contextoRegistro.modificarPacienteUsuarioId])

  useEffect(() => {
    const subscription = watch(() => {
      contextoRegistro.setModificarPacienteInformacionPersonal(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const manejarTipoDeDocumento = async (e) => {
    if (!e?.target?.value) {
      contextoRegistro.setModificarPacienteTipoDocumentoId('')
      setValue('tipoDocumento')
      setError('tipoDocumento', {type: 'require', message: ''})
    } else {
      contextoRegistro.setModificarPacienteTipoDocumentoId(e.target.value)
      setValue('tipoDocumento', e.target.value)
      clearErrors('tipoDocumento')
      patternReglas(e.target.value)
    }
  }

  const patternReglas = (valor) => {
    if (valor === '12') {
      setPattern(/^[0-9-]+$/)
    }

    if (valor === '1' || (valor > 4 && valor < 12)) {
      setPattern(/^[0-9]+$/)
    }

    if (valor > 1 && valor < 5) {
      setPattern(/^[a-zA-Z0-9]*$/)
    }
  }

  return (
    <>
      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="numeroDocumento"
          control={control}
          rules={{
            required: true,
            pattern: regexSoloLetrasYNumeros,
            minLength: infoNumeroCaracteres.minimo,
            maxLength: infoNumeroCaracteres.maximo,
            onChange: (e) => {
              contextoRegistro.setModificarPacienteNumeroDocumento(
                e.target.value.replace(/^(0+)/g, '')
              )
            },
          }}
          render={({field: {onChange, value}}) => (
            <div className="w-31% flex justify-start  flex-wrap">
              <Input
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'numeroDocumento'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={infoNumeroCaracteres.tipoCampo || 'text'}
                tabIndex={'1'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'No. Documento*'}
                valor={value}
                id={'primerInput'}
                onChange={onChange}
              />
              <ErroresInput
                erroresFormulario={errors}
                nombre={'numeroDocumento'}
                tipoError={'pattern'}
                mensaje={'Se permiten letras y números'}
              />
            </div>
          )}
        />

        <Controller
          name="tipoDocumento"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <ListaDesplegable
              funcion={manejarTipoDeDocumento}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find(
                  (element) => element === 'tipoDocumento'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Tipo de documento*'}
              opciones={tipoDocumento}
              valor={contextoRegistro.modificarPacienteTipoDocumentoId}
              onBlur={() =>
                contextoRegistro.setModificarPacienteInformacionPersonal(
                  getValues()
                )
              }
            />
          )}
        />
        <Controller
          name="tipoPoblacion"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={clsx(
                'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                    Object.keys(errors).find(
                      (element) => element === 'tipoPoblacion'
                    ),
                  'border border-cendiatra focus:border-cendiatra':
                    !Object.keys(errors).find(
                      (element) => element === 'tipoPoblacion'
                    ),
                }
              )}
              titulo={'Tipo de población*'}
              opciones={contextoAplicacion.tiposPoblacion}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-start items-center">
        <AlertaLongitudDocumento
          tipoDocumento={
            contextoRegistro.modificarPacienteInformacionPersonal.tipoDocumento
          }
          informacionCaracteres={infoNumeroCaracteres}
          setInformacionCaracteres={setInfoNumeroCaracteres}
          numeroDocumento={
            contextoRegistro.modificarPacienteInformacionPersonal
              .numeroDocumento
          }
          errors={errors}
        />
      </div>
      <div
        className={
          Object.keys(errors).find((element) => element === 'usuarioExiste')
            ? 'w-full flex flex-wrap justify-center items-center  py-2 rounded-lg '
            : 'hidden'
        }
      >
        <div className="w-6/12 bg-cendiatra-gris-5  flex justify-start items-center  py-2  rounded-lg -ml-16">
          <div className="w-2/12 flex justify-center items-center">
            <img src={IconoAlerta} alt="alerta" />
          </div>
          <div className="w-10/12">
            <span className="text-red-500 w-full">
              {errors.usuarioExiste?.type === 'existent' &&
                errors.usuarioExiste?.message}
            </span>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="apellidos"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'apellidos')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Apellidos*'}
                valor={value}
                onBlur={() =>
                  contextoRegistro.setModificarPacienteInformacionPersonal(
                    getValues()
                  )
                }
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.apellidos?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />

        <Controller
          name="nombre"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'nombre')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nombre*'}
                valor={value}
                onBlur={() =>
                  contextoRegistro.setModificarPacienteInformacionPersonal(
                    getValues()
                  )
                }
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nombre?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
        <Controller
          name="grupoSanguineo"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              titulo={'Grupo sanguíneo'}
              opciones={contextoAplicacion.gruposSanguineos}
              valor={value}
              onBlur={() =>
                contextoRegistro.setModificarPacienteInformacionPersonal(
                  getValues()
                )
              }
            />
          )}
        />
      </div>
      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="fechaNacimiento"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosInput={clsx(
                'appearance-none rounded relative block w-full p-1.5 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
                    Object.keys(errors).includes('fechaNacimiento') ||
                    fechaEsValida,
                  'border border-cendiatra text-cendiatra-gris-3 focus:border-cendiatra':
                    !Object.keys(errors).includes('fechaNacimiento') &&
                    !fechaEsValida,
                }
              )}
              tipo={'date'}
              placeholder={'Descripción'}
              fechaMinima={obtenerFechaMinima(anioFechMinima)}
              fechaMaxima={fechaDeHoy}
              titulo={'Fecha de nacimiento*'}
              valor={value}
              onBlur={() =>
                contextoRegistro.setModificarPacienteInformacionPersonal(
                  getValues()
                )
              }
            />
          )}
        />
        <Controller
          name="sexo"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'sexo')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Sexo*'}
              opciones={contextoAplicacion.sexos}
              valor={value}
              onBlur={() =>
                contextoRegistro.setModificarPacienteInformacionPersonal(
                  getValues()
                )
              }
            />
          )}
        />
        <Controller
          name="estadoCivil"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'estadoCivil')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Estado civil*'}
              opciones={contextoAplicacion.estadosCiviles}
              valor={value}
              onBlur={() =>
                contextoRegistro.setModificarPacienteInformacionPersonal(
                  getValues()
                )
              }
            />
          )}
        />
      </div>
      <div className="w-full">
        <div className="w-31% ml-7">
          <AlertaDinamica
            mostrarAlerta={fechaEsValida}
            mensaje={ALERTAS.EDAD_MAXIMA}
          />
        </div>
      </div>
      <div className="w-full flex justify-evenly items-center mt-2 mb-50px">
        <Controller
          name="lugarNacimiento"
          control={control}
          rules={{
            required: false,
            pattern: regexTextoCaracteresBasicos,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'lugarNacimiento'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Lugar de nacimiento'}
                valor={value}
                onBlur={() =>
                  contextoRegistro.setModificarPacienteInformacionPersonal(
                    getValues()
                  )
                }
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.lugarNacimiento?.type === 'pattern' &&
                  'Se permiten estos caracteres + ( ) [ ] , / - . '}
              </span>
            </div>
          )}
        />
        <Controller
          name="escolaridad"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'escolaridad')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Escolaridad*'}
              opciones={contextoAplicacion.escolaridades}
              valor={value}
              onBlur={() =>
                contextoRegistro.setModificarPacienteInformacionPersonal(
                  getValues()
                )
              }
            />
          )}
        />
        <Controller
          name="nacionalidad"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nacionalidad'}
                valor={value}
                onBlur={() =>
                  contextoRegistro.setModificarPacienteInformacionPersonal(
                    getValues()
                  )
                }
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nacionalidad?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
      </div>
    </>
  )
}

export default ModificarInformacionPersonalPaciente
