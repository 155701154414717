import React, {useEffect, useState, useContext, useCallback} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ContenedorDesplegable} from '../Contenedores'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {
  Input,
  ErroresInput,
  InputSubirArchivo,
  ListaDesplegable,
} from '../inputs'
import TablaDinamica from '../TablaDinamica'
import EncabezadoFondo from '../Tablas/EncabezadoFondo'
import BotonConImagen from '../BotonConImagen'
import {CeldaConBotonDinamica} from '../Celdas'
import {Boton} from '../Botones'
import {ContextApplication, ContextoCargarDatosAliado} from '../../contexto'
import {
  AgregarPaquetesACache,
  ObtenerAliadoPorNombreONombreCiudad,
  AgregarEmpresasACache,
  ObtenerEmpresasPorNitONombreFiltro,
  ObtenerEmpresasHijasPorNitONombreFiltro,
  AgregarTarifariosACache,
  AgregarServiciosACache,
  ObtenerServicioPorId,
  ObtenerTiposDeDocumento,
  ObtenerCie11PorNombreOCodigo,
} from '../../cache/servicios-cache'
import {
  ObtenerTarifarioPorEmpresaId,
  ObtenerPaquetePorId,
  ComprobarServiciosAsociadosPlantilla,
  ObtenerImprimeCertificadoPorServiciosIds,
} from '../../microservicios'
import BotonAgregar from '../../imagenes/addButton.png'
import BotonQuitar from '../../imagenes/removeButton.png'
import IconoAlerta from '../../imagenes/alerta.svg'
import SignoAdmiracion from '../../imagenes/iconoAdmiracion.svg'
import {
  regexSoloLetrasYNumeros,
  PATRON_INPUT_TEXTO,
  TEXTO_VISUAL,
  ALERTAS,
  regexTextoCaracteresBasicos,
} from '../../constantes'
import {AlertaDinamica, AlertaLongitudDocumento} from '../MostrarInformacion'
import {obtenerFechaMinima} from '../../utilidades'
import moment from 'moment'
import clsx from 'clsx'
import {regexBasicoV1} from '../../constantes/regex'
import {CLASIFICACION_NUMERACION_SERVICIO} from '../../constantes'
import {CeldaConTooltipTextosDiferentes} from '../Celdas'
import {aplicarFormatoMoneda} from '../../utilidades'
import {CLASIFICACION_SERVICIO} from '../../constantes'
import {BotonSimple} from '../Botones'

const FormularioCargarResultadoAliado = ({
  funcionClick,
  aliadoParaModificar,
}) => {
  const [aliados, setAliados] = useState([])
  const [empresasPadre, setEmpresasPadre] = useState([])
  const [diagnosticos, SetDiagnosticos] = useState([])
  const [empresasHijas, setEmpresasHijas] = useState([])
  const [tipoDocumento, setTipoDocumento] = useState([])
  const [servicioOPaqueteFiltrado, setServicioOPaqueteFiltrado] = useState([])
  const [servicioOPaqueteAAgregar, setServiciosOPaquetesAAgregar] =
    useState(null)
  const [faltanArchivos, setFaltanArchivos] = useState(true)

  const [infoNumeroCaracteres, setInfoNumeroCaracteres] = useState({
    minimo: 6,
    maximo: 10,
    mensaje: '',
    tipoCampo: 'text',
  })

  const contextoAplicacion = useContext(ContextApplication)

  const contextoCargarDatosAliado = useContext(ContextoCargarDatosAliado)

  const [fechaEsValida, setFechaEsValida] = useState(false)

  const anioFechMinima = 99

  const fechaDeHoy = moment().format().slice(0, -15)

  const [plantillaNoExiste, setPlantillaNoExiste] = useState(false)

  const [totalServicios, setTotalServicios] = useState(0)

  const [actualizarTarifario, setActualizarTarifario] = useState(true)

  const {
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const manejarCambioDiagnostico = async (valor, datosDiagnostico) => {
    contextoCargarDatosAliado.setDiagnosticoSeleccionado({
      ...contextoCargarDatosAliado.diagnosticoSeleccionado,
      nombre: valor,
    })
    if (valor.length >= 3) {
      contextoAplicacion.setIsLoading(true)
      SetDiagnosticos(await ObtenerCie11PorNombreOCodigo(valor))
      contextoAplicacion.setIsLoading(false)
    } else {
      SetDiagnosticos([])
    }
    if (datosDiagnostico) {
      setValue('diagnostico1', valor)
      clearErrors('diagnostico1')
      contextoCargarDatosAliado.setDiagnosticoSeleccionado(datosDiagnostico)
    } else {
      setValue('diagnostico1', valor)
      setError('diagnostico1')
      contextoCargarDatosAliado.setDiagnosticoSeleccionado({id: ''})
    }
  }

  useEffect(() => {
    if (getValues('fechaNacimiento')) {
      const fechaNacimiento = moment(getValues('fechaNacimiento'))
      const fechaMinima = moment()
        .subtract(anioFechMinima, 'years')
        .startOf('day')
        .subtract(1, 'day')
      setFechaEsValida(!fechaNacimiento.isAfter(fechaMinima))
    }
  }, [getValues('fechaNacimiento'), fechaEsValida])

  useEffect(() => {
    if (aliadoParaModificar) {
      contextoCargarDatosAliado.setDeshabilitarInputs(
        aliadoParaModificar.editable
      )
    }
  }, [aliadoParaModificar])

  useEffect(() => {
    if (aliadoParaModificar) {
      reset(contextoCargarDatosAliado.informacionCargarDatosAliado)
    }
    trigger()
  }, [contextoCargarDatosAliado.actualizar, infoNumeroCaracteres])

  useEffect(() => {
    const subscription = watch(() => {
      contextoCargarDatosAliado.setInformacionCargarDatosAliado(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const obtenerInformacionInicial = useCallback(async () => {
    await AgregarEmpresasACache()
    await AgregarTarifariosACache()
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    setTipoDocumento(await ObtenerTiposDeDocumento())
    trigger()
  }, [])

  useEffect(() => {
    obtenerInformacionInicial()
  }, [])

  useEffect(() => {
    trigger()
  }, [infoNumeroCaracteres, Object.keys(errors).length])

  useEffect(() => {
    const validarExisteAliadoEnTarifario = () => {
      if (
        contextoCargarDatosAliado.aliadoId &&
        contextoCargarDatosAliado.empresaPadreSeleccionada.id != ''
      ) {
        if (
          !contextoCargarDatosAliado.tarifarioAliado?.paquetes?.[0]
            ?.preciosAliados &&
          !contextoCargarDatosAliado.tarifarioAliado?.servicios?.[0]
            ?.preciosAliados
        ) {
          contextoCargarDatosAliado.setAliadoNoExiste(true)
        } else {
          const verificarExistenciaServicio =
            contextoCargarDatosAliado.tarifarioAliado.servicios &&
            contextoCargarDatosAliado.tarifarioAliado.servicios.find(
              (elemento) =>
                elemento.preciosAliados.find(
                  (elemento) =>
                    elemento.aliadoId === contextoCargarDatosAliado.aliadoId
                )
            )
          const verificarExistenciaPaquete =
            contextoCargarDatosAliado.tarifarioAliado.paquetes &&
            contextoCargarDatosAliado.tarifarioAliado.paquetes.find(
              (elemento) =>
                elemento.preciosAliados.find(
                  (elemento) =>
                    elemento.aliadoId === contextoCargarDatosAliado.aliadoId
                )
            )
          contextoCargarDatosAliado.setAliadoNoExiste(
            !(verificarExistenciaServicio || verificarExistenciaPaquete)
          )
        }
      }
    }
    validarExisteAliadoEnTarifario()
  }, [
    contextoCargarDatosAliado.tarifarioAliado,
    contextoCargarDatosAliado.aliadoId,
  ])

  useEffect(() => {
    const obtenerTarifarioEmpresa = () => {
      if (!!contextoCargarDatosAliado.empresaPadreSeleccionada.id) {
        ObtenerTarifarioPorEmpresaId(
          contextoCargarDatosAliado.empresaPadreSeleccionada.id
        )
          .then(async (res) => {
            if (res.data !== '') {
              contextoCargarDatosAliado.setTarifarioAliado(res.data)
              const serviciosIds = res?.data?.servicios.map(
                (servicio) => servicio?.servicioId
              )
              const paquetesIds = await Promise.all(
                res.data.paquetes.map(async (servicio) => {
                  let informacionPaquete = (
                    await ObtenerPaquetePorId(servicio.paqueteId)
                  ).data
                  return await Promise.all(
                    informacionPaquete.servicios.map(async (servicio) => {
                      let dataServicio = await ObtenerServicioPorId(servicio)
                      return dataServicio.Id
                    })
                  )
                })
              )
              const paquetesServiciosIds = paquetesIds.flat()
              let todosLosIds = serviciosIds.concat(paquetesServiciosIds)

              todosLosIds = new Set(todosLosIds)

              const validarImprimeCertificado =
                await ObtenerImprimeCertificadoPorServiciosIds(
                  [...todosLosIds].join(',')
                )

              const servicioPlantillaAsociada =
                await ComprobarServiciosAsociadosPlantilla([...todosLosIds])

              let servicios = await Promise.all(
                res.data.servicios.map(async (servicio) => {
                  let dataServicio = await ObtenerServicioPorId(
                    servicio.servicioId
                  )
                  return {
                    id: dataServicio.Id,
                    filtro: Object.is(servicio.alias, null)
                      ? dataServicio.CodigoCompleto +
                        ' - ' +
                        dataServicio.Nombre
                      : dataServicio.CodigoCompleto + ' - ' + servicio.alias,
                    codigo: dataServicio.CodigoCompleto,
                    nombre: Object.is(servicio.alias, null)
                      ? dataServicio.Nombre
                      : servicio.alias,
                    paqueteAsignado: '',
                    clasificacion: dataServicio.ClasificacionId,
                    historiaMedica: '',
                    certificado: '',
                    paqueteId: 0,
                    tipo: 'SERVICIO',
                    nombreHistoria: '',
                    nombreCertificado: '',
                    imprimeCertificado:
                      validarImprimeCertificado?.data?.find(
                        (servicio) => servicio?.servicioId === dataServicio?.Id
                      )?.imprimeCertificado ?? false,
                    plantillaId:
                      servicioPlantillaAsociada.data?.find(
                        (item) => item.servicioId === dataServicio?.Id
                      )?.plantillaId ?? null,
                    precio: servicio.preciosAliados.find(
                      (aliado) =>
                        aliado.aliadoId === contextoCargarDatosAliado.aliadoId
                    ).precio,
                  }
                })
              )

              let paquetes = await Promise.all(
                res.data.paquetes.map(async (servicio) => {
                  let informacionPaquete = (
                    await ObtenerPaquetePorId(servicio.paqueteId)
                  ).data
                  return {
                    id: informacionPaquete.id,
                    filtro:
                      informacionPaquete.codigoInterno +
                      ' - ' +
                      informacionPaquete.nombre,
                    codigo: informacionPaquete.codigoInterno,
                    tipo: 'PAQUETE',
                    paqueteId: informacionPaquete.id,
                    nombre: informacionPaquete.nombre,

                    servicios: await Promise.all(
                      informacionPaquete.servicios.map(async (elemento, i) => {
                        let dataServicio = await ObtenerServicioPorId(elemento)
                        return {
                          id: dataServicio.Id,
                          codigo: dataServicio.CodigoCompleto,
                          nombre: dataServicio.Nombre,
                          paqueteAsignado: informacionPaquete.nombre,
                          paqueteId: informacionPaquete.id,
                          historiaMedica: '',
                          clasificacion: dataServicio.ClasificacionId,
                          certificado: '',
                          nombreHistoria: '',
                          nombreCertificado: '',
                          imprimeCertificado:
                            validarImprimeCertificado?.data?.find(
                              (elemento) =>
                                elemento?.servicioId === dataServicio?.Id
                            )?.imprimeCertificado ?? false,
                          precio:
                            i === 0
                              ? servicio.preciosAliados.find(
                                  (aliado) =>
                                    aliado.aliadoId ===
                                    contextoCargarDatosAliado.aliadoId
                                ).precio
                              : '',
                        }
                      })
                    ),
                  }
                })
              )
              contextoCargarDatosAliado.setTodosLosPaquetesYServicios(
                servicios.concat(paquetes)
              )
            } else {
              contextoCargarDatosAliado.setTodosLosPaquetesYServicios([])
              contextoCargarDatosAliado.setTarifarioAliado([])
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
    obtenerTarifarioEmpresa()
  }, [contextoCargarDatosAliado.empresaPadreSeleccionada, actualizarTarifario])

  useEffect(() => {
    const sumarPreciosTotal = (...arreglos) => {
      return arreglos.flat().reduce((total, servicio) => {
        const precio = Number(servicio.precio)
        return !isNaN(precio) && !!servicio.precio ? total + precio : total
      }, 0)
    }
    const total = sumarPreciosTotal(
      contextoCargarDatosAliado.servicios,
      contextoCargarDatosAliado.laboratorios
    )
    setTotalServicios(total)
  }, [
    contextoCargarDatosAliado.servicios,
    contextoCargarDatosAliado.laboratorios,
  ])

  const manejarCambioAliado = async (valor, id) => {
    contextoCargarDatosAliado.setNombreAliado(valor)
    if (valor.length >= 3) {
      contextoAplicacion.setIsLoading(true)
      setAliados(await ObtenerAliadoPorNombreONombreCiudad(valor))
      contextoAplicacion.setIsLoading(false)
    } else {
      setAliados([])
    }
    if (id) {
      setValue('seleccionarCiudadAliado', valor)
      clearErrors('seleccionarCiudadAliado')
      contextoCargarDatosAliado.setAliadoId(id)
    } else {
      contextoCargarDatosAliado.setAliadoId()
      contextoCargarDatosAliado.setServicios([])
      contextoCargarDatosAliado.setLaboratorios([])
      setValue('seleccionarCiudadAliado', valor)
      setError('seleccionarCiudadAliado')
    }
  }
  const manejarCambioEmpresaPadre = async (valor, datosEmpresa) => {
    contextoCargarDatosAliado.setEmpresaPadreSeleccionada({
      ...contextoCargarDatosAliado.empresaPadreSeleccionada,
      nombre: valor,
    })
    if (valor.length >= 3) {
      contextoAplicacion.setIsLoading(true)
      setEmpresasPadre(await ObtenerEmpresasPorNitONombreFiltro(valor))

      contextoAplicacion.setIsLoading(false)
    } else {
      setEmpresasPadre([])
      contextoCargarDatosAliado.setAliadoNoExiste(false)
    }
    if (datosEmpresa) {
      setValue('empresaNit', valor)
      clearErrors('empresaNit')
      contextoCargarDatosAliado.setEmpresaPadreSeleccionada(datosEmpresa)
      contextoAplicacion.setIsLoading(true)
    } else {
      setValue('empresaNit', valor)
      setError('empresaNit')
      contextoCargarDatosAliado.setServicios([])
      contextoCargarDatosAliado.setLaboratorios([])
      contextoCargarDatosAliado.setEmpresaPadreSeleccionada({id: ''})
    }
  }

  const manejarCambioEmpresaHija = async (valor, datosEmpresaHija) => {
    setValue('empresaHijaNit', valor)

    if (valor.length >= 3) {
      contextoAplicacion.setIsLoading(true)
      setEmpresasHijas(
        await ObtenerEmpresasHijasPorNitONombreFiltro(
          contextoCargarDatosAliado.empresaPadreSeleccionada.id,
          valor
        )
      )
      contextoAplicacion.setIsLoading(false)
    } else {
      setEmpresasHijas([])
    }
    if (datosEmpresaHija) {
      contextoCargarDatosAliado.setEmpresaHijaSeleccionada(datosEmpresaHija)
    } else {
      contextoCargarDatosAliado.setEmpresaHijaSeleccionada({id: ''})
    }
  }

  const filtrarServiciosPorNombreOCodigo = (filtro) => {
    let servicioPorCodigo =
      contextoCargarDatosAliado.todosLosPaquetesYServicios.filter(
        ({codigo}) => codigo.toUpperCase().indexOf(filtro?.toUpperCase()) > -1
      )

    let servicioPorNombre =
      contextoCargarDatosAliado.todosLosPaquetesYServicios.filter(
        ({nombre}) => nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )

    return servicioPorCodigo.concat(servicioPorNombre)
  }

  const manejarCambiarServicioOPaqueteSeleccionado = (
    valor,
    informacionServicio
  ) => {
    setValue('servicioOPaquete', valor)
    setPlantillaNoExiste(false)
    if (valor.length >= 3) {
      contextoAplicacion.setIsLoading(true)
      setServicioOPaqueteFiltrado(filtrarServiciosPorNombreOCodigo(valor))
      contextoAplicacion.setIsLoading(false)
    } else {
      setServicioOPaqueteFiltrado([])
    }
    if (informacionServicio) {
      setServiciosOPaquetesAAgregar(informacionServicio)
    } else {
      setServiciosOPaquetesAAgregar(null)
    }
  }

  const agregarServicio = async () => {
    if (!servicioOPaqueteAAgregar) {
      return
    }
    let servicioExiste = contextoCargarDatosAliado.servicios.filter(
      (elemento) =>
        elemento.id === servicioOPaqueteAAgregar.id ||
        elemento.paqueteId === servicioOPaqueteAAgregar.id
    )

    let laboratorioExiste = contextoCargarDatosAliado.laboratorios.filter(
      (elemento) =>
        elemento.id === servicioOPaqueteAAgregar.id ||
        elemento.paqueteId === servicioOPaqueteAAgregar.id
    )

    if (
      !servicioOPaqueteAAgregar?.plantillaId &&
      servicioOPaqueteAAgregar?.tipo === 'SERVICIO'
    ) {
      setPlantillaNoExiste(true)
      return
    }

    if (servicioExiste.length || laboratorioExiste.length) {
      return
    }
    setValue('servicioOPaquete', '')
    if (!servicioOPaqueteAAgregar.paqueteId) {
      if (
        servicioOPaqueteAAgregar.clasificacion ===
        CLASIFICACION_NUMERACION_SERVICIO.LABORATORIO_CLINICO
      ) {
        contextoCargarDatosAliado.setLaboratorios([
          ...contextoCargarDatosAliado.laboratorios,
          servicioOPaqueteAAgregar,
        ])
      } else {
        contextoCargarDatosAliado.setServicios([
          ...contextoCargarDatosAliado.servicios,
          servicioOPaqueteAAgregar,
        ])
      }
    } else {
      let laboratoriosParaAgregar = servicioOPaqueteAAgregar.servicios.filter(
        (servicio) =>
          servicio.clasificacion ===
          CLASIFICACION_NUMERACION_SERVICIO.LABORATORIO_CLINICO
      )
      let serviciosParaAgregar = servicioOPaqueteAAgregar.servicios.filter(
        (servicio) =>
          servicio.clasificacion !==
          CLASIFICACION_NUMERACION_SERVICIO.LABORATORIO_CLINICO
      )
      contextoCargarDatosAliado.setLaboratorios(
        contextoCargarDatosAliado.laboratorios.concat(laboratoriosParaAgregar)
      )
      contextoCargarDatosAliado.setServicios(
        contextoCargarDatosAliado.servicios.concat(serviciosParaAgregar)
      )
    }
    setServiciosOPaquetesAAgregar(null)
  }

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const manejarBorrarPaquete = (servicio) => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    contextoCargarDatosAliado.setLaboratorios(
      contextoCargarDatosAliado.laboratorios.filter(
        (elemento) =>
          elemento.paqueteAsignado.toUpperCase() !==
          servicio.paqueteAsignado.toUpperCase()
      )
    )
    contextoCargarDatosAliado.setServicios(
      contextoCargarDatosAliado.servicios.filter(
        (elemento) =>
          elemento.paqueteAsignado.toUpperCase() !==
          servicio.paqueteAsignado.toUpperCase()
      )
    )
  }

  const borrarServicio = (servicio) => {
    if (servicio.paqueteId === 0) {
      contextoCargarDatosAliado.setLaboratorios(
        contextoCargarDatosAliado.laboratorios.filter(
          (elemento) =>
            !(
              +elemento.id === +servicio.id &&
              elemento.tipo?.toLowerCase() === CLASIFICACION_SERVICIO.SERVICIO
            )
        )
      )
      contextoCargarDatosAliado.setServicios(
        contextoCargarDatosAliado.servicios.filter(
          (elemento) =>
            !(
              +elemento.id === +servicio.id &&
              elemento.tipo?.toLowerCase() === CLASIFICACION_SERVICIO.SERVICIO
            )
        )
      )
    } else {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: '',
        contenido: (
          <>
            <span className="text-cendiatra w-full font-bold mb-1">
              EL SERVICIO PERTENECE A UN PAQUETE
            </span>
            <span className="text-cendiatra w-full font-bold mt-1">
              ¿DESEA QUITAR EL PAQUETE?
            </span>
          </>
        ),
        botones: [
          {
            nombre: 'Sí',
            click: () => manejarBorrarPaquete(servicio),
          },
          {
            nombre: 'No',
            click: manejaCerrarModal,
          },
        ],
      })
    }
  }
  const manejarGuardarUrlHistoria = (
    nombre,
    url,
    idServicio,
    idPaquete,
    propiedadNombre,
    propiedadUrl
  ) => {
    contextoCargarDatosAliado.setLaboratorios(
      contextoCargarDatosAliado.laboratorios.map((laboratorio) => {
        if (
          laboratorio.id == idServicio &&
          laboratorio.paqueteId == idPaquete
        ) {
          return {
            ...laboratorio,
            [propiedadNombre]: nombre,
            [propiedadUrl]: url,
          }
        }
        return laboratorio
      })
    )

    contextoCargarDatosAliado.setServicios(
      contextoCargarDatosAliado.servicios.map((servicio) => {
        if (servicio.id == idServicio && servicio.paqueteId == idPaquete) {
          return {
            ...servicio,
            [propiedadNombre]: nombre,
            [propiedadUrl]: url,
          }
        }
        return servicio
      })
    )
  }
  const manejarGuardarUrlLaboratorios = (nombre, url) => {
    contextoCargarDatosAliado.setUrlLaboratorios({
      nombre: nombre,
      url: url,
    })
  }

  useEffect(() => {
    const validarArchivosSinSubir = () => {
      let servicios = contextoCargarDatosAliado.servicios.find(
        (elemento) => elemento.certificado === '' && elemento.imprimeCertificado
      )
      setFaltanArchivos(!servicios)
    }
    validarArchivosSinSubir()
  }, [
    contextoCargarDatosAliado.servicios,
    contextoCargarDatosAliado.laboratorios,
    contextoCargarDatosAliado.urlLaboratorios,
  ])

  const columnsTablaServicios = React.useMemo(() => [
    {
      Header: (row) => <EncabezadoFondo titulo={'Código del servicio'} />,
      accessor: 'codigo',
      className:
        'w-18% flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nombre del servicio'} />,
      accessor: 'nombre',
      className:
        'w-20%  flex text-center justify-center items-center content-center  h-20 text-13px uppercase',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Paquete Asignado'} />,
      accessor: 'paqueteAsignado',
      className:
        'w-15% flex text-center justify-center items-center content-center  h-20 text-13px uppercase',
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Adjuntar historia medica (Max 2mb)'} />
      ),
      accessor: 'adjuntarHistoria',
      className:
        'w-17% flex text-center justify-center items-center content-center  h-20 text-13px',
      Cell: (row) => (
        <InputSubirArchivo
          editable={contextoCargarDatosAliado.deshabilitarIputs}
          tamanoMaximoArchivo={2}
          nombreLabel={''}
          errorLabel={''}
          TamanoMaximo={'2Mb'}
          ocultarLeyenda={true}
          nombreArchivoServicio={row.row.original.nombreHistoria}
          estiloAdicional={
            row.row.original.nombreHistoria ||
            row.row.original.nombreCertificado
              ? 'h-75'
              : ''
          }
          archivoRequerido={false}
          archivosAceptaos={'application/pdf'}
          archivoLimpiado={() =>
            manejarGuardarUrlHistoria(
              '',
              '',
              row.row.original.id,
              row.row.original.paqueteId,
              'nombreHistoria',
              'historiaMedica'
            )
          }
          obtenerUrlArchivo={(e, f) =>
            manejarGuardarUrlHistoria(
              e,
              f,
              row.row.original.id,
              row.row.original.paqueteId,
              'nombreHistoria',
              'historiaMedica'
            )
          }
          nombreEnlace={'historiaServicios' + row.row.original.id}
          habilitarVisualizarArchivo={true}
          urlArchivoServicio={row.row.original.historiaMedica}
          contenedor={'certificados'}
          subirPdf={true}
        />
      ),
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Adjuntar certificado médico (Max 2mb)'} />
      ),
      accessor: 'adjuntarCertificado',
      className:
        'w-17% flex text-center justify-center items-center content-center rounded-r-md h-20 text-13px',
      Cell: (row) => (
        <InputSubirArchivo
          editable={contextoCargarDatosAliado.deshabilitarIputs}
          tamanoMaximoArchivo={2}
          nombreLabel={''}
          errorLabel={''}
          ocultarLeyenda={true}
          TamanoMaximo={'2Mb'}
          archivosAceptaos={'application/pdf'}
          nombreArchivoServicio={row.row.original.nombreCertificado}
          estiloAdicional={
            row.row.original.nombreHistoria ||
            row.row.original.nombreCertificado
              ? 'h-75'
              : ''
          }
          archivoRequerido={
            row.row.original.imprimeCertificado &&
            row.row.original.certificado === ''
          }
          archivoLimpiado={() =>
            manejarGuardarUrlHistoria(
              '',
              '',
              row.row.original.id,
              row.row.original.paqueteId,
              'nombreCertificado',
              'certificado'
            )
          }
          obtenerUrlArchivo={(e, f) =>
            manejarGuardarUrlHistoria(
              e,
              f,
              row.row.original.id,
              row.row.original.paqueteId,
              'nombreCertificado',
              'certificado'
            )
          }
          nombreEnlace={'certificadoServicios' + row.row.original.id}
          habilitarVisualizarArchivo={true}
          urlArchivoServicio={row.row.original.certificado}
          contenedor={'certificados'}
          subirPdf={true}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Valor'} />,
      accessor: 'precio',
      className:
        'w-12% flex text-center justify-center items-center content-center  h-20 text-13px uppercase',
      Cell: (row) =>
        row.row.original.tipo?.toLowerCase() ===
        CLASIFICACION_SERVICIO.SERVICIO ? (
          <span> {aplicarFormatoMoneda(row.row.original.precio)}</span>
        ) : (
          <div
            className={`w-full ${
              row.row.original.precio !== '' ? 'flex' : 'hidden'
            } justify-center items-center w-`}
          >
            <img
              className={'w-auto mr-1'}
              src={SignoAdmiracion}
              alt="signoAdmiracion"
            />
            <CeldaConTooltipTextosDiferentes
              textoToolTip={'Este valor corresponde al paquete'}
              estilosPersonalizados={
                'text-sm bg-cendiatra text-white w-52 normal-case -mt-20'
              }
              texto={aplicarFormatoMoneda(row.row.original.precio)}
            />
          </div>
        ),
    },
    {
      id: 'CeldaConBoton',
      className: `w-12 -mr-10 ${
        !contextoCargarDatosAliado.deshabilitarIputs ? 'hidden' : ''
      }`,
      Cell: (row) => (
        <CeldaConBotonDinamica
          habilitado={contextoCargarDatosAliado.deshabilitarIputs}
          imagenHabilitado={BotonQuitar}
          imagenDeshabilitado={BotonQuitar}
          funcion={() => borrarServicio(row.row.original)}
          imagen={BotonQuitar}
          estilos={''}
        />
      ),
    },
  ])

  const columnsTablaLaboratorios = React.useMemo(() => [
    {
      Header: '',
      accessor: 'codigo',
      className:
        'w-18% flex text-center justify-center items-center content-center rounded-l-md h-20 text-13px uppercase',
    },
    {
      Header: 'LABORATORIOS CLINICOS',
      accessor: 'nombre',
      className:
        'w-20% flex text-center justify-center items-center content-center h-20 text-13px uppercase',
    },
    {
      Header: '',
      accessor: 'paqueteAsignado',
      className:
        'w-15% flex text-center justify-center items-center content-center h-20 text-13px uppercase',
    },
    {
      Header: () =>
        contextoCargarDatosAliado.laboratorios.length ? (
          <InputSubirArchivo
            editable={contextoCargarDatosAliado.deshabilitarIputs}
            tamanoMaximoArchivo={2}
            nombreLabel={''}
            errorLabel={''}
            TamanoMaximo={'2Mb'}
            ocultarLeyenda={true}
            archivosAceptaos={'application/pdf'}
            nombreArchivoServicio={
              contextoCargarDatosAliado.urlLaboratorios.nombre
            }
            archivoRequerido={false}
            archivoLimpiado={() => manejarGuardarUrlLaboratorios('', '')}
            obtenerUrlArchivo={(e, f) => manejarGuardarUrlLaboratorios(e, f)}
            nombreEnlace={'UrlLaboratorios'}
            habilitarVisualizarArchivo={true}
            urlArchivoServicio={contextoCargarDatosAliado.urlLaboratorios.url}
            contenedor={'certificados'}
            subirPdf={true}
          />
        ) : null,
      accessor: 'adjuntarHistoria',
      className:
        'w-17% flex text-center justify-center items-center content-center h-20 text-13px',
    },
    {
      Header: '',
      accessor: 'adjuntarCertificado',
      className:
        'w-17% flex text-center justify-center items-center content-center rounded-r-md h-20 text-13px',
    },
    {
      Header: '',
      accessor: 'precio',
      className:
        'w-12% flex text-center justify-center items-center content-center  h-20 text-13px uppercase',
      Cell: (row) =>
        row.row.original.tipo?.toLowerCase() ===
        CLASIFICACION_SERVICIO.SERVICIO ? (
          <span> {aplicarFormatoMoneda(row.row.original.precio)}</span>
        ) : (
          <div
            className={`w-full ${
              row.row.original.precio !== '' ? 'flex' : 'hidden'
            } justify-center items-center w-`}
          >
            <CeldaConTooltipTextosDiferentes
              textoToolTip={'Este valor corresponde al paquete'}
              estilosPersonalizados={
                'text-14px bg-cendiatra text-white mt-8 w-80'
              }
              texto={aplicarFormatoMoneda(row.row.original.precio)}
            />
          </div>
        ),
    },
    {
      id: 'CeldaConBoton',
      className: `w-12 -mr-10 ${
        !contextoCargarDatosAliado.deshabilitarIputs ? 'hidden' : ''
      }`,
      Cell: (row) => (
        <CeldaConBotonDinamica
          habilitado={contextoCargarDatosAliado.deshabilitarIputs}
          imagenHabilitado={BotonQuitar}
          imagenDeshabilitado={BotonQuitar}
          funcion={() => borrarServicio(row.row.original)}
          imagen={BotonQuitar}
          estilos={''}
        />
      ),
    },
  ])

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      <div className="w-full flex justify-between items-start my-5">
        <Controller
          name="seleccionarCiudadAliado"
          control={control}
          rules={{
            required: !contextoCargarDatosAliado?.nombreAliado,
          }}
          render={({field: {onChange, value}}) => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-30%'}
              titulo={'Seleccionar ciudad - aliado*'}
              estilosInput={clsx(
                'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                Object.keys(errors).find(
                  (element) => element === 'seleccionarCiudadAliado'
                )
                  ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                  : 'focus:border-cendiatra border-cendiatra '
              )}
              tipo={'text'}
              placeholder={'Autocompletar'}
              valorDelCampoFiltro={contextoCargarDatosAliado.nombreAliado}
              desactivarOtroFiltro={
                !contextoCargarDatosAliado.deshabilitarIputs
              }
              informacionFiltrada={aliados}
              handleChange={(e) => manejarCambioAliado(e.target.value)}
              handleOptionChange={(e) => {
                manejarCambioAliado(e.filtro, e.id)
              }}
            />
          )}
        />
        <div className="w-30%">
          <Controller
            name="fechaAtencion"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                estilosContenedor={'w-2/3'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-7/12  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find(
                    (element) => element === 'fechaAtencion'
                  )
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                tipo={'date'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'Fecha de atención*'}
                onChange={onChange}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
                fechaMinima={obtenerFechaMinima(anioFechMinima)}
              />
            )}
          />
        </div>

        <div className="w-30%"></div>
      </div>

      <ContenedorDesplegable
        titulo={'DATOS PACIENTE'}
        posicionTitulo={'justify-center'}
        expandidoInicial={true}
        ocultarBotonDesplegable={true}
      >
        <div className="w-full flex justify-between items-center flex-wrap mb-5">
          <Controller
            name="tipoDocumento"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                funcion={null}
                estilosContenedor={'w-30%'}
                estilosLista={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find(
                    (element) => element === 'tipoDocumento'
                  )
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                onChange={onChange}
                titulo={'Tipo de documento*'}
                opciones={tipoDocumento}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />
          <Controller
            name="numeroDocumento"
            control={control}
            rules={{
              required: true,
              minLength: infoNumeroCaracteres.minimo,
              maxLength: infoNumeroCaracteres.maximo,
              pattern: regexSoloLetrasYNumeros,
            }}
            render={({field: {onChange, value}}) => (
              <div className="w-30%">
                <Input
                  funcion={null}
                  estilosContenedor={'w-full'}
                  onChange={onChange}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    Object.keys(errors).find(
                      (element) => element === 'numeroDocumento'
                    )
                      ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                      : 'focus:border-cendiatra border-cendiatra '
                  )}
                  tipo={infoNumeroCaracteres.tipoCampo || 'text'}
                  placeholder={'DESCRIPCIÓN'}
                  titulo={'No. Documento*'}
                  valor={value}
                  deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
                />
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={'numeroDocumento'}
                  tipoError={'pattern'}
                  mensaje={'Se permiten letras y números'}
                />
              </div>
            )}
          />

          <Controller
            name="celular"
            control={control}
            rules={{
              required: false,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                funcion={null}
                estilosContenedor={'w-30%'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'number'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'Celular'}
                onChange={onChange}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <div className="w-full flex justify-start items-center">
            <AlertaLongitudDocumento
              tipoDocumento={watch('tipoDocumento')}
              informacionCaracteres={infoNumeroCaracteres}
              setInformacionCaracteres={setInfoNumeroCaracteres}
              numeroDocumento={watch('numeroDocumento')}
              errors={errors}
              trigger={trigger}
            />
          </div>

          <Controller
            name="apellidos"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                funcion={null}
                estilosContenedor={'w-30%'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find((element) => element === 'apellidos')
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                tipo={'text'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'Apellidos*'}
                onChange={onChange}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <Controller
            name="nombres"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                funcion={null}
                estilosContenedor={'w-30%'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find((element) => element === 'nombres')
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                tipo={'text'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'Nombres*'}
                onChange={onChange}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <Controller
            name="grupoSanguineo"
            control={control}
            rules={{
              required: false,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'w-30%'}
                estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                titulo={'Grupo sanguíneo'}
                opciones={contextoAplicacion.gruposSanguineos}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <Controller
            name="fechaNacimiento"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                onChange={onChange}
                estilosContenedor={'w-30%'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full p-1.5 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  {
                    'border border-cendiatra-rojo-1 text-cendiatra-gris-placeholder focus:border-cendiatra-rojo-1':
                      Object.keys(errors).includes('fechaNacimiento') ||
                      fechaEsValida,
                    'border border-cendiatra text-cendiatra-gris-3 focus:border-cendiatra':
                      !Object.keys(errors).includes('fechaNacimiento') &&
                      !fechaEsValida,
                  }
                )}
                tipo={'date'}
                placeholder={'Descripción'}
                fechaMinima={obtenerFechaMinima(anioFechMinima)}
                fechaMaxima={fechaDeHoy}
                titulo={'Fecha de nacimiento*'}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <Controller
            name="sexo"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'w-30%'}
                estilosLista={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find((element) => element === 'sexo')
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                titulo={'Sexo*'}
                opciones={contextoAplicacion.sexos}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <Controller
            name="estadoCivil"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'w-30%'}
                estilosLista={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find(
                    (element) => element === 'estadoCivil'
                  )
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                titulo={'Estado civil*'}
                opciones={contextoAplicacion.estadosCiviles}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <div className="w-full flex justify-start items-center">
            <AlertaDinamica
              mostrarAlerta={fechaEsValida}
              mensaje={ALERTAS.EDAD_MAXIMA}
            />
          </div>

          <Controller
            name="estrato"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'w-30%'}
                estilosLista={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find((element) => element === 'estrato')
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                titulo={'Estrato*'}
                opciones={contextoAplicacion.estratos}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <Controller
            name="escolaridad"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'w-30%'}
                estilosLista={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  Object.keys(errors).find(
                    (element) => element === 'escolaridad'
                  )
                    ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                    : 'focus:border-cendiatra border-cendiatra '
                )}
                titulo={'Escolaridad*'}
                opciones={contextoAplicacion.escolaridades}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <Controller
            name="lugarNacimiento"
            control={control}
            rules={{
              required: false,
              pattern: regexTextoCaracteresBasicos,
            }}
            render={({field: {onChange, value}}) => (
              <div className="flex flex-wrap w-30%">
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    Object.keys(errors).find(
                      (element) => element === 'lugarNacimiento'
                    )
                      ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                      : 'focus:border-cendiatra border-cendiatra '
                  )}
                  tipo={'text'}
                  placeholder={'Descripción'}
                  titulo={'Lugar de nacimiento'}
                  valor={value}
                  deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
                />
                <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.lugarNacimiento?.type === 'pattern' &&
                    'Se permiten estos caracteres + ( ) [ ] , / - . '}
                </span>
              </div>
            )}
          />

          <Controller
            name="nacionalidad"
            control={control}
            rules={{
              required: false,
              pattern: regexBasicoV1,
            }}
            render={({field: {onChange, value}}) => (
              <div className="flex flex-wrap w-30%">
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full'}
                  estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                  tipo={'text'}
                  placeholder={'Descripción'}
                  titulo={'Nacionalidad'}
                  valor={value}
                  deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
                />
                <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.nacionalidad?.type === 'pattern' &&
                    TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
                </span>
              </div>
            )}
          />
          <Controller
            name="tipoPoblacion"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <ListaDesplegable
                onChange={onChange}
                estilosContenedor={'flex flex-wrap w-30%'}
                estilosLista={clsx(
                  'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                  {
                    'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                      Object.keys(errors).find(
                        (element) => element === 'tipoPoblacion'
                      ),
                    'border border-cendiatra focus:border-cendiatra':
                      !Object.keys(errors).find(
                        (element) => element === 'tipoPoblacion'
                      ),
                  }
                )}
                titulo={'Tipo de población*'}
                opciones={contextoAplicacion.tiposPoblacion}
                valor={value}
                deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
              />
            )}
          />

          <div className="w-30%"></div>
        </div>
      </ContenedorDesplegable>

      <div className="w-full mb-5">
        <ContenedorDesplegable
          titulo={'RESULTADO'}
          posicionTitulo={'justify-center'}
          expandidoInicial={true}
          ocultarBotonDesplegable={true}
        >
          <div className="w-full gap-2 flex flex-wrap items-center">
            <Controller
              name="conceptoAptitud"
              control={control}
              rules={{
                required: true,
                pattern: PATRON_INPUT_TEXTO,
              }}
              render={({field: {onChange, value}}) => (
                <Input
                  funcion={null}
                  estilosContenedor={'w-custom'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    Object.keys(errors).find(
                      (element) => element === 'conceptoAptitud'
                    )
                      ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                      : 'focus:border-cendiatra border-cendiatra '
                  )}
                  tipo={'text'}
                  placeholder={'DESCRIPCIÓN'}
                  titulo={'Concepto de aptitud*'}
                  onChange={onChange}
                  valor={value}
                  deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
                  longitudMaxima={60}
                />
              )}
            />

            <Controller
              name="diagnostico1"
              control={control}
              rules={{
                required:
                  !contextoCargarDatosAliado?.diagnosticoSeleccionado?.nombre,
                pattern: regexBasicoV1,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <CampoFiltrarEspañol
                    estilosPersonalizados={'w-custom'}
                    titulo={'Diagnóstico 1*'}
                    estilosInput={clsx(
                      'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                      Object.keys(errors).find(
                        (element) => element === 'diagnostico1'
                      )
                        ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                        : 'focus:border-cendiatra border-cendiatra '
                    )}
                    tipo={'text'}
                    placeholder={'Descripción'}
                    desactivarOtroFiltro={
                      !contextoCargarDatosAliado.deshabilitarIputs
                    }
                    valorDelCampoFiltro={
                      contextoCargarDatosAliado.diagnosticoSeleccionado.nombre
                    }
                    handleChange={(e) =>
                      manejarCambioDiagnostico(e.target.value)
                    }
                    handleOptionChange={(e) => {
                      manejarCambioDiagnostico(e.filtro, e)
                    }}
                    informacionFiltrada={diagnosticos}
                  />
                  <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                    {errors.diagnostico1?.type === 'pattern' &&
                      TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
                  </span>
                </>
              )}
            />

            <span className="text-red-500 w-full">
              {errors.conceptoAptitud?.type === 'pattern'
                ? TEXTO_VISUAL.FORMULARIO.VALOR_INVALIDO_CAMPO_TEXTO
                : ''}
            </span>

            <div className="w-30%"></div>
          </div>
        </ContenedorDesplegable>
      </div>

      <div className="w-full mb-5">
        <ContenedorDesplegable
          titulo={'ORDEN SERVICIO'}
          posicionTitulo={'justify-center'}
          expandidoInicial={true}
          ocultarBotonDesplegable={true}
        >
          <div className="w-full flex justify-between items-center flex-wrap">
            <Controller
              name="empresaNit"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <CampoFiltrarEspañol
                  estilosPersonalizados={'w-30%'}
                  titulo={'Empresa (NIT)*'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    Object.keys(errors).find(
                      (element) => element === 'empresaNit'
                    )
                      ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                      : 'focus:border-cendiatra border-cendiatra '
                  )}
                  tipo={'text'}
                  placeholder={'Autocompletar'}
                  valorDelCampoFiltro={
                    contextoCargarDatosAliado.empresaPadreSeleccionada.nombre
                  }
                  desactivarOtroFiltro={
                    !contextoCargarDatosAliado.deshabilitarIputs
                  }
                  informacionFiltrada={empresasPadre}
                  handleChange={(e) =>
                    manejarCambioEmpresaPadre(e.target.value)
                  }
                  handleOptionChange={(e) =>
                    manejarCambioEmpresaPadre(e.filtro, e)
                  }
                />
              )}
            />
            <Controller
              name="empresaHijaNit"
              control={control}
              rules={{
                required: false,
                onChange: (e) => manejarCambioEmpresaHija(e.target.value),
              }}
              render={({field: {onChange, value}}) => (
                <CampoFiltrarEspañol
                  estilosPersonalizados={'w-30%'}
                  titulo={'Empresa hija (NIT)'}
                  estilosInput={`appearance-none rounded relative block w-full text-cendiatra-gris-3 p-1.5 border border-cendiatra  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm `}
                  tipo={'text'}
                  placeholder={'Autocompletar'}
                  valorDelCampoFiltro={value}
                  desactivarOtroFiltro={
                    contextoCargarDatosAliado.empresaPadreSeleccionada.id ==
                      '' || !contextoCargarDatosAliado.deshabilitarIputs
                  }
                  informacionFiltrada={empresasHijas}
                  handleChange={onChange}
                  handleOptionChange={(e) =>
                    manejarCambioEmpresaHija(e.filtro, e)
                  }
                />
              )}
            />
            <Controller
              name="cargoEmpresa"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <Input
                  funcion={null}
                  estilosContenedor={'w-30%'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    Object.keys(errors).find(
                      (element) => element === 'cargoEmpresa'
                    )
                      ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                      : 'focus:border-cendiatra border-cendiatra '
                  )}
                  tipo={'text'}
                  placeholder={'DESCRIPCIÓN'}
                  titulo={'Cargo*'}
                  onChange={onChange}
                  valor={value}
                  deshabilitado={!contextoCargarDatosAliado.deshabilitarIputs}
                />
              )}
            />
            <div className="w-full">
              <div className="w-30% ">
                {contextoCargarDatosAliado.aliadoNoExiste ? (
                  <div className="w-full flex justify-center items-center bg-cendiatra-gris-5 py-2 mb-1 rounded-lg">
                    <div className="w-1/12 flex justify-center items-center mx-4">
                      <img
                        src={IconoAlerta}
                        className={'w-5 h-5 '}
                        alt="alerta"
                      />
                    </div>
                    <div className="w-11/12 pr-1">
                      <span className="text-cendiatra-semaforo-rojo font-bold text-13px ">
                        {`La empresa seleccionada no tiene tarifario asignado para el aliado seleccionado`}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <Controller
              name="servicioOPaquete"
              control={control}
              rules={{
                required: false,
                onChange: (e) =>
                  manejarCambiarServicioOPaqueteSeleccionado(e.target.value),
              }}
              render={({field: {onChange, value}}) => (
                <div className="w-full flex flex-wrap">
                  <div className="w-42% flex justify-end items-center">
                    <CampoFiltrarEspañol
                      estilosPersonalizados={'w-full'}
                      titulo={'Seleccione el servicio o paquete'}
                      estilosInput={
                        'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                      }
                      tipo={'text'}
                      placeholder={'Autocompletar'}
                      valorDelCampoFiltro={value}
                      desactivarOtroFiltro={
                        !contextoCargarDatosAliado.deshabilitarIputs
                      }
                      informacionFiltrada={servicioOPaqueteFiltrado}
                      handleChange={onChange}
                      handleOptionChange={(e) =>
                        manejarCambiarServicioOPaqueteSeleccionado(e.filtro, e)
                      }
                    />
                    <BotonConImagen
                      ancho={clsx(
                        'w-12 flex ml-5 justify-center items-center mt-1',
                        {
                          hidden: !contextoCargarDatosAliado.deshabilitarIputs,
                          'pointer-events-none':
                            !contextoCargarDatosAliado.aliadoId,
                        }
                      )}
                      imagen={BotonAgregar}
                      textoAlternativo={'Agregar'}
                      funcionAEjecutar={agregarServicio}
                    />
                    <div className="w-30%">
                      <BotonSimple
                        texto={'Actualizar'}
                        estilosBoton={clsx(
                          'w-24 h-8 text-white bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2 mt-8 ml-3 z-50',
                          {
                            'bg-cendiatra-gris-2 pointer-events-none':
                              !contextoCargarDatosAliado.deshabilitarIputs,
                            'bg-btnBg':
                              contextoCargarDatosAliado.deshabilitarIputs,
                          }
                        )}
                        funcion={() =>
                          setActualizarTarifario(!actualizarTarifario)
                        }
                        tipoDeBoton={'button'}
                        deshabilitado={
                          !contextoCargarDatosAliado.deshabilitarIputs
                        }
                      />
                    </div>
                  </div>
                  {plantillaNoExiste ? (
                    <div className="w-100%">
                      <div className="w-full flex justify-center items-center bg-cendiatra-gris-5 py-2 mb-1 rounded-lg">
                        <div className="w-1/12 flex justify-center items-center mx-4">
                          <img
                            src={IconoAlerta}
                            className={'w-5 h-5 '}
                            alt="alerta"
                          />
                        </div>
                        <div className="w-11/12 pr-1">
                          <span className="text-cendiatra-semaforo-rojo font-bold text-13px ">
                            El servicio seleccionado no tiene plantilla asignada
                            comuniquese con el administrador
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            />
          </div>
        </ContenedorDesplegable>
      </div>

      <span className="w-full text-cendiatra text-13px ml-2">
        Agregar minimo un servício*
      </span>
      <div className="w-full justify-center flex flex-wrap items-center">
        <TablaDinamica
          data={contextoCargarDatosAliado.servicios}
          columns={columnsTablaServicios}
        />

        <TablaDinamica
          data={contextoCargarDatosAliado.laboratorios}
          estilosHeader={
            'bg-white text-cendiatra border-cendiatra border-t-2 mt-5 h-20'
          }
          columns={columnsTablaLaboratorios}
        />
      </div>
      <span className="w-full border border-cendiatra"></span>
      <div className="w-full flex justify-end items-center mt-8">
        <span className="w-auto text-cendiatra normal-case text-2xl">
          Total
        </span>
        <span className="w-auto text-cendiatra-gris-2 normal-case text-2xl mx-16">
          {aplicarFormatoMoneda(totalServicios)}
        </span>
      </div>
      <div className="w-full flex justify-center items-center h-32">
        <div
          className={`w-full flex justify-center items-center ${
            !contextoCargarDatosAliado.deshabilitarIputs ? 'hidden' : ''
          }`}
        >
          <Boton
            titulo="Guardar"
            habilitado={
              isValid &&
              (contextoCargarDatosAliado.laboratorios.length > 0 ||
                contextoCargarDatosAliado.servicios.length > 0) &&
              faltanArchivos &&
              !contextoCargarDatosAliado.aliadoNoExiste
            }
            funcionCLick={() => funcionClick()}
            colorPorDefecto={'bg-cendiatra-gris-placeholder'}
            colorActivo={'bg-cendiatra'}
            tipo={'button'}
          />
        </div>
      </div>
    </div>
  )
}

export default FormularioCargarResultadoAliado
