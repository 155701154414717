import React, {useContext, useEffect, useState} from 'react'
import {FormularioCargarResultadoAliado} from '../../componentes/administradorAliado'
import {ContextoCargarDatosAliado, ContextApplication} from '../../contexto'
import {useMsal} from '@azure/msal-react'
import {FachadaCrearResultadoAliado} from '../../microservicios/Fachada.js'
import {useHistory} from 'react-router-dom'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ObtenerUsuarioPorTipoDocumentoYDocumento} from '../../microservicios/Usuario.js'
import {ObtenerAtencionAliadoPorFecha} from '../../microservicios/Aliado.js'
import moment from 'moment'

const CargarResultadosAliado = () => {
  const contextoCargarDatosAliado = useContext(ContextoCargarDatosAliado)
  const contextoAplicacion = useContext(ContextApplication)

  const {accounts} = useMsal()
  const nombreUsuario = accounts[0].idTokenClaims.name

  const [pacienteId, setPacienteId] = useState('')

  const history = useHistory()

  const obtenerAtencionAliadoPorFecha = async () => {
    const fechaAtencion =
      contextoCargarDatosAliado.informacionCargarDatosAliado.fechaAtencion
    const aliadoId = contextoCargarDatosAliado.aliadoId

    try {
      let respuesta = null
      if (!!pacienteId)
        respuesta = await ObtenerAtencionAliadoPorFecha(
          pacienteId,
          fechaAtencion,
          aliadoId
        )

      if (!!respuesta && respuesta.status === 200) {
        yaExisteUnaAtencion(respuesta.data)
      }

      if (!respuesta || respuesta.status === 204) {
        guardarResultados()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const generarPayload = async () => {
    let serviciosSeleccinados = contextoCargarDatosAliado.servicios.map(
      (servicio) => {
        return {
          servicioId: servicio.id,
          paqueteId: servicio.paqueteId,
          urlHistoriaClinica: servicio.historiaMedica,
          urlCertificado: servicio.certificado,
        }
      }
    )
    let laboratoriosSeleccionados = contextoCargarDatosAliado.laboratorios.map(
      (laboratorio) => {
        return {
          servicioId: laboratorio.id,
          paqueteId: laboratorio.paqueteId,
          urlHistoriaClinica: laboratorio.historiaMedica,
          urlCertificado: laboratorio.certificado,
        }
      }
    )

    let todosLosServicios = serviciosSeleccinados.concat(
      laboratoriosSeleccionados
    )

    let payloadCargarResultadosAliado = {
      id: contextoCargarDatosAliado.idResultados,
      paciente: {
        tipoDocumentoId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.tipoDocumento,
        numeroDocumento:
          contextoCargarDatosAliado.informacionCargarDatosAliado
            .numeroDocumento,
        cargo: contextoCargarDatosAliado.informacionCargarDatosAliado
          .cargoEmpresa
          ? contextoCargarDatosAliado.informacionCargarDatosAliado.cargoEmpresa.toUpperCase()
          : null,
        nombres:
          contextoCargarDatosAliado.informacionCargarDatosAliado.nombres.toUpperCase(),
        apellidos:
          contextoCargarDatosAliado.informacionCargarDatosAliado.apellidos.toUpperCase(),
        celular: contextoCargarDatosAliado.informacionCargarDatosAliado.celular
          ? contextoCargarDatosAliado.informacionCargarDatosAliado.celular
          : null,
        fechaNacimiento:
          contextoCargarDatosAliado.informacionCargarDatosAliado
            .fechaNacimiento,
        sexoId: contextoCargarDatosAliado.informacionCargarDatosAliado.sexo,
        estadoCivilId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.estadoCivil,
        estratoId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.estrato,
        escolaridadId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.escolaridad,
        grupoSanguineoId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.grupoSanguineo,
        lugarNacimiento:
          contextoCargarDatosAliado.informacionCargarDatosAliado
            .lugarNacimiento,
        nacionalidad:
          contextoCargarDatosAliado?.informacionCargarDatosAliado?.nacionalidad,
        tipoPoblacionId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.tipoPoblacion,
      },
      aliadoId: contextoCargarDatosAliado.aliadoId,
      fechaAtencion:
        contextoCargarDatosAliado.informacionCargarDatosAliado.fechaAtencion,
      empresaId: contextoCargarDatosAliado.empresaPadreSeleccionada.id,
      empresaHijaId: contextoCargarDatosAliado.empresaHijaSeleccionada.id
        ? contextoCargarDatosAliado.empresaHijaSeleccionada.id
        : null,
      urlLaboratorio: contextoCargarDatosAliado.urlLaboratorios.url,
      cargo:
        contextoCargarDatosAliado.informacionCargarDatosAliado.cargoEmpresa,
      creadoPor: nombreUsuario,
      servicios: todosLosServicios,
      conceptoAptitud:
        contextoCargarDatosAliado?.informacionCargarDatosAliado
          ?.conceptoAptitud,
      detalleAtencionAliado: {
        diagnosticoUnoId:
          contextoCargarDatosAliado?.diagnosticoSeleccionado?.id,
      },
    }
    return payloadCargarResultadosAliado
  }

  useEffect(() => {
    contextoCargarDatosAliado.setInformacionCargarDatosAliado({})
    contextoCargarDatosAliado.setServicios([])
    contextoCargarDatosAliado.setLaboratorios([])
    contextoCargarDatosAliado.setDeshabilitarInputs(true)
    contextoCargarDatosAliado.setAliadoId()
    contextoCargarDatosAliado.setTarifarioAliado([])
    contextoCargarDatosAliado.setEmpresaPadreSeleccionada({id: '', nombre: ''})
    contextoCargarDatosAliado.setEmpresaHijaSeleccionada({id: ''})
    contextoCargarDatosAliado.setTodosLosPaquetesYServicios([])
    contextoCargarDatosAliado.setAliadoParaModificar({id: '', editable: true})
    contextoCargarDatosAliado.setNombreAliado('')
    contextoCargarDatosAliado.setDiagnosticoSeleccionado({id: '', nombre: ''})
  }, [])

  useEffect(() => {
    const obtenerUsuario = async () => {
      if (
        contextoCargarDatosAliado.informacionCargarDatosAliado
          .numeroDocumento &&
        contextoCargarDatosAliado.informacionCargarDatosAliado.tipoDocumento &&
        contextoCargarDatosAliado.informacionCargarDatosAliado.numeroDocumento
          .length > 5
      ) {
        try {
          const respuesta = await ObtenerUsuarioPorTipoDocumentoYDocumento(
            contextoCargarDatosAliado.informacionCargarDatosAliado
              .numeroDocumento,
            contextoCargarDatosAliado.informacionCargarDatosAliado.tipoDocumento
          )
          setPacienteId(respuesta.data.id)
        } catch (error) {
          console.log(error)
        }
      }
    }

    obtenerUsuario()
  }, [
    contextoCargarDatosAliado.informacionCargarDatosAliado.numeroDocumento,
    contextoCargarDatosAliado.informacionCargarDatosAliado.tipoDocumento,
  ])

  const yaExisteUnaAtencion = (atencion) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <span className="text-red-900 w-full font-bold text-2xl mb-8">
            YA EXISTE UNA ATENCIÓN CARGADA PARA:
          </span>
          <div className="justify-start flex flex-col text-left">
            <div className="text-cendiatra w-full font-bold mt-1">
              <div className="flex justify-start gap-24 mb-2">
                <span className="capitalize">
                  No. Documento:{' '}
                  {
                    contextoCargarDatosAliado?.informacionCargarDatosAliado
                      ?.numeroDocumento
                  }
                </span>
                <span className="capitalize">
                  Fecha:{' '}
                  {moment(
                    contextoCargarDatosAliado?.informacionCargarDatosAliado
                      ?.fechaAtencion
                  ).format('DD/MM/YYYY')}
                </span>
              </div>
              <div className="flex justify-start gap-10 mb-5">
                <span className="capitalize">
                  No. <span className="lowercase">de</span> Atención: {atencion}
                </span>
                <span></span>
              </div>
            </div>
            <div className="text-cendiatra w-full font-bold mt-1">
              <div className="capitalize">
                Aliado: {contextoCargarDatosAliado?.nombreAliado}
              </div>
              <div className="capitalize">
                Empresa:{' '}
                {contextoCargarDatosAliado.empresaPadreSeleccionada.filtro}
              </div>
            </div>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: manejarCerrarModal,
        },
      ],
    })
  }

  const subirResultadosAliado = async () => {
    obtenerAtencionAliadoPorFecha()
  }

  const modalResultadoGuardado = (atencion) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <span className="text-cendiatra w-full font-bold mb-1">
            CÓDIGO ATENCIÓN ALIADO : {atencion}
          </span>
          <span className="text-cendiatra w-full font-bold mt-1">
            EL RESULTADO HA SIDO GUARDADO
          </span>
          <span className="text-cendiatra w-full font-bold mt-1">
            EXITOSAMENTE
          </span>
        </>
      ),
      botones: [
        {
          nombre: 'Crear nuevo',
          click: manejarCrearNueva,
        },
        {
          nombre: 'Finalizar',
          click: manejarFinalizar,
        },
      ],
    })
  }

  const guardarResultados = async () => {
    let payload = await generarPayload()
    FachadaCrearResultadoAliado(payload)
      .then((res) => {
        modalResultadoGuardado(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarFinalizar = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
  }

  const manejarCrearNueva = () => {
    history.go(0)
  }

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.TITULO_ADMINISTRADOR_ALIADO.CARGA_RESULTADO_ALIADO
      }
    >
      <FormularioCargarResultadoAliado funcionClick={subirResultadosAliado} />
    </ContenedorPagina>
  )
}

export default CargarResultadosAliado
