import React, {useContext, useEffect} from 'react'
import TablaPacientes from '../../componentes/recepcionista/TablaPacientes'
import {useHistory} from 'react-router-dom'
import FiltroSeleccionarPaciente from '../../componentes/inputs/FiltroSeleccionarPaciente'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import BotonesModificarPaciente from '../../componentes/Botones/BotonesModificarPaciente'
import CeldaImagen from '../../componentes/Tablas/CeldaImagen'
import FormularioModificarUsuario from '../../componentes/recepcionista/ModificarUsuario/FormularioModificarUsuario'
import {ContextoRegistro} from '../../contexto'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'

const ModificarPacientes = () => {
  const contextoRegistro = useContext(ContextoRegistro)

  const history = useHistory()
  useEffect(() => {
    contextoRegistro.setTotalPaginas(1)
    contextoRegistro.setNumeroPagina(1)
    contextoRegistro.setValidacionBotonCredito(false)
    contextoRegistro.setErroresModificarFormulario({
      personal: true,
      direccion: true,
      firmaFotoHuella: true,
      motivosModificacion: true,
    })
    contextoRegistro.setPacientesFiltrados([])
  }, [])

  useEffect(() => {
    if (
      contextoRegistro.erroresModificarFormulario.personal ||
      contextoRegistro.erroresModificarFormulario.direccion ||
      contextoRegistro.erroresModificarFormulario.firmaFotoHuella ||
      contextoRegistro.erroresModificarFormulario.motivosModificacion
    ) {
      contextoRegistro.setValidacionBoton(true)
    } else {
      contextoRegistro.setValidacionBoton(false)
    }
  }, [contextoRegistro.erroresModificarFormulario])

  const columnasPacientes = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Foto'} />,
      accessor: 'foto',
      className:
        'w-1/12 flex text-center justify-center items-center content-center border-cendiatra rounded-l-md border-l border-t border-b h-16',
      headerStyle:
        'w-1/12 bg-cendiatra-verde-7 text-white ml-0.5 rounded-l-md h-14',
      Cell: (row) => <CeldaImagen imagen={row.row.original.foto} />,
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'No de documento'} />,
      accessor: 'numeroDocumento',
      className:
        'w-3/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16 uppercase',
      headerStyle: 'w-3/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Apellidos'} />,
      accessor: 'apellidos',
      className:
        'w-3/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16',
      headerStyle: 'w-3/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Nombre'} />,
      accessor: 'nombre',
      className:
        'w-2/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b h-16',
      headerStyle: 'w-2/12 bg-cendiatra-verde-7 text-white h-14',
    },
    {
      Header: (row) => <EncabezadoFondo titulo={'Módulos'} />,
      accessor: 'modulos',
      className:
        'w-2/12 flex text-center justify-center items-center content-center border-cendiatra border-t border-b border-r rounded-r-md h-16',
      headerStyle: 'w-2/12 bg-cendiatra-verde-7 text-white rounded-r-md h-14',
      Cell: (row) => (
        <BotonesModificarPaciente
          funcionEditar={() => manejarEditarPaciente(row.row.original.id)}
          funcionHistoriaClinica={() =>
            manejarClickHistoriaClinica(row.row.original.id)
          }
        />
      ),
    },
  ])

  const limpiarDatos = () => {
    contextoRegistro.setModificarPaciente(false)
    contextoRegistro.setModificarPacienteNumeroDocumento('')
    contextoRegistro.setModificarPacienteTipoDocumentoId('')
    contextoRegistro.setModificarPacienteInformacionPersonal({})
    contextoRegistro.setInformacionDireccion({
      paisResidencia: 'COLOMBIA',
      departamento: '',
      municipio: '',
      localidad: '',
    })
    contextoRegistro.setFoto('')
    contextoRegistro.setFirma('')
    contextoRegistro.setErroresModificarFormulario({
      personal: true,
      direccion: true,
      firmaFotoHuella: true,
    })
  }

  const manejarEditarPaciente = (id) => {
    contextoRegistro.setModificarPaciente(true)
    contextoRegistro.setModificarPacienteUsuarioId(id)
  }

  const manejarClickHistoriaClinica = (idUsuario) => {
    history.push('/historiaclinica', {idUsuario: idUsuario})
  }

  return (
    <ContenedorPagina
      tituloPagina={
        contextoRegistro.modificarPaciente
          ? TEXTO_VISUAL.TITULOS_RECEPCIONISTA.MODIFICAR_DATOS_PACIENTE
          : TEXTO_VISUAL.TITULOS_RECEPCIONISTA.VER_MODIFICAR_PACIENTES
      }
      funcionBotonCerrar={
        contextoRegistro.modificarPaciente ? limpiarDatos : null
      }
    >
      {contextoRegistro.modificarPaciente ? (
        <FormularioModificarUsuario />
      ) : (
        <>
          <FiltroSeleccionarPaciente />
          <div className="bg-white pt-2 rounded-t-3xl rounded-b-3xl justify-center items-center flex flex-wrap mb-16">
            <TablaPacientes
              columns={columnasPacientes}
              data={contextoRegistro.pacientesFiltrados}
            />
          </div>
        </>
      )}
    </ContenedorPagina>
  )
}

export default ModificarPacientes
