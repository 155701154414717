import {
  ObtenerCacheComercial,
  ObtenerSedesCendiatra,
  ObtenerValoresDeReferencia,
  ObtenerLocalidades,
  ObtenerCIE10,
} from '../../microservicios/Referencia'
import {dbComun} from '../bases-datos/dbComun'
import {obtenerVersionActual} from './Version'
import {ObtenerCuentasDeIngreso} from '../../microservicios/Servicio'

const tablaMap = {
  // Diferencias por mayúsculas o abreviaciones
  EPS: 'Eps',
  ARL: 'Arl',

  // Palabras en singular vs plural
  ParentescoFamiliar: 'Parentesco',
  OrganoSistema: 'OrganosSistema',
  EstadoOrganoSistema: 'EstadosOrganoSistema',
  EstadoTunelCarpiano: 'EstadosTunelCarpiano',
  VigilanciaEpidemiologica: 'VigilanciasEpidemiologicas',

  // Otras diferencias de capitalización o nombres exactos
  EstadoFactura: 'EstadoFactura',
  TipoTurno: 'TipoTurno',
  EstadoTurno: 'EstadoTurno',
  EstadoServicioAtencion: 'EstadoServicioAtencion',
  EstadoAtencion: 'EstadoAtencion',
  TipoFacturacion: 'TipoFacturacion',
  TurnoTrabajo: 'TurnoTrabajo',
  RiesgosFisicos: 'RiesgosFisicos',
  RiesgosBiologicos: 'RiesgosBiologicos',
  RiesgosErgonomicos: 'RiesgosErgonomicos',
  RiesgosLocativos: 'RiesgosLocativos',
  RiesgosNaturales: 'RiesgosNaturales',
  RiesgosSeguridad: 'RiesgosSeguridad',
  OtrosRiesgos: 'OtrosRiesgos',
  ConceptoDeAptitud: 'ConceptoDeAptitud',
  Antecedentes: 'Antecedentes',
  AntecedentesToxicologicos: 'AntecedentesToxicologicos',
  Recomendaciones: 'Recomendaciones',
  Restricciones: 'Restricciones',
  ClasificacionExamenFisico: 'ClasificacionExamenFisico',
  TipoCampo: 'TipoCampo',
  ClasificacionServicio: 'ClasificacionServicio',
  DuracionCita: 'DuracionCita',
  GrupoLaboratorio: 'GrupoLaboratorio',
  ObservacionesPreparacion: 'ObservacionesPreparacion',
  Estructura: 'Estructura',
  CausaNotaCredito: 'CausaNotaCredito',
  MotivoModificacionAtencion: 'MotivoModificacionAtencion',
  ActividadEconomica: 'ActividadEconomica',
  TipoPoblacion: 'TipoPoblacion',
  TipoRetencion: 'TipoRetencion',
  TipoFactura: 'TipoFactura',
  EstadoRips: 'EstadoRips',
}

export const PoblarCacheComun = async (nuevaVersion, onProgress) => {
  await dbComun.open()
  const version = await obtenerVersionActual(nuevaVersion)
  if (version && !nuevaVersion) return

  //Simunlando progreso
  onProgress?.(10)

  // Limpiar todas las tablas relevantes
  await Promise.all([
    dbComun.table('Version').clear(),
    dbComun.Departamentos.clear(),
    dbComun.Ciudades.clear(),
    dbComun.Cups.clear(),
    dbComun.Sedes.clear(),
    dbComun.Aliados.clear(),
    dbComun.SedesCendiatra.clear(),
    dbComun.GrupoSanguineo.clear(),
    dbComun.EstadoCivil.clear(),
    dbComun.EstadoFactura.clear(),
    dbComun.Escolaridad.clear(),
    dbComun.Raza.clear(),
    dbComun.MediosPago.clear(),
    dbComun.Sexo.clear(),
    dbComun.Zona.clear(),
    dbComun.Estrato.clear(),
    dbComun.IndicativoTelefono.clear(),
    dbComun.FacturarA.clear(),
    dbComun.TipoExamen.clear(),
    dbComun.Eps.clear(),
    dbComun.Arl.clear(),
    dbComun.TipoAtencion.clear(),
    dbComun.TipoDocumento.clear(),
    dbComun.FondoPension.clear(),
    dbComun.Localidades.clear(),
    dbComun.Parentesco.clear(),
    dbComun.TipoTurno.clear(),
    dbComun.EstadoTurno.clear(),
    dbComun.EstadoServicioAtencion.clear(),
    dbComun.EstadoAtencion.clear(),
    dbComun.TipoFacturacion.clear(),
    dbComun.TurnoTrabajo.clear(),
    dbComun.RiesgosFisicos.clear(),
    dbComun.RiesgosBiologicos.clear(),
    dbComun.RiesgosErgonomicos.clear(),
    dbComun.RiesgosLocativos.clear(),
    dbComun.RiesgosNaturales.clear(),
    dbComun.RiesgosSeguridad.clear(),
    dbComun.OtrosRiesgos.clear(),
    dbComun.ConceptoDeAptitud.clear(),
    dbComun.Antecedentes.clear(),
    dbComun.AntecedentesToxicologicos.clear(),
    dbComun.OrganosSistema.clear(),
    dbComun.EstadosOrganoSistema.clear(),
    dbComun.EstadosTunelCarpiano.clear(),
    dbComun.Recomendaciones.clear(),
    dbComun.VigilanciasEpidemiologicas.clear(),
    dbComun.Restricciones.clear(),
    dbComun.ClasificacionExamenFisico.clear(),
    dbComun.Cie11.clear(),
    dbComun.TipoCampo.clear(),
    dbComun.ClasificacionServicio.clear(),
    dbComun.DuracionCita.clear(),
    dbComun.GrupoLaboratorio.clear(),
    dbComun.ObservacionesPreparacion.clear(),
    dbComun.Estructura.clear(),
    dbComun.CausaNotaCredito.clear(),
    dbComun.MotivoModificacionAtencion.clear(),
    dbComun.CuentaDeIngreso.clear(),
    dbComun.ActividadEconomica.clear(),
    dbComun.TipoPoblacion.clear(),
    dbComun.TipoRetencion.clear(),
    dbComun.TipoFactura.clear(),
    dbComun.EstadoRips.clear(),
  ])

  const [
    resCache,
    resSedesCendiatra,
    resValores,
    resLocalidades,
    resCIE10,
    resCuentas,
  ] = await Promise.all([
    ObtenerCacheComercial(),
    ObtenerSedesCendiatra(),
    ObtenerValoresDeReferencia(
      'GrupoSanguineo,EstadoCivil,EstadoFactura,Escolaridad,Raza,MediosPago,Sexo,Zona,Estrato,IndicativoTelefono,FacturarA,TipoExamen,Eps,Arl,TipoAtencion,TipoDocumento,FondoPension,ParentescoFamiliar,Tipoturno,EstadoTurno,EstadoServicioAtencion,EstadoAtencion,TipoFacturacion,TurnoTrabajo,RiesgosFisicos,RiesgosBiologicos,RiesgosErgonomicos,RiesgosLocativos,RiesgosNaturales,RiesgosSeguridad,OtrosRiesgos,ConceptoDeAptitud,Antecedentes,AntecedentesToxicologicos,OrganoSistema,EstadoOrganoSistema,EstadoTunelCarpiano,Recomendaciones,VigilanciaEpidemiologica,Restricciones,ClasificacionExamenFisico,TipoCampo,ClasificacionServicio,DuracionCita,GrupoLaboratorio,ObservacionesPreparacion,Estructura,CausaNotaCredito,MotivoModificacionAtencion,ActividadEconomica,TipoPoblacion,TipoRetencion,TipoFactura,EstadoRips'
    ),
    ObtenerLocalidades(),
    ObtenerCIE10(),
    ObtenerCuentasDeIngreso(),
  ])

  const promises = []

  // Version
  promises.push(
    dbComun.table('Version').put({
      Numero: resCache.data.version,
      Fecha: resCache.data.fechaVersion,
    })
  )

  // Departamentos, Ciudades, Cups, Sedes, Aliados
  promises.push(
    dbComun.Departamentos.bulkAdd(
      resCache.data.departamentos.map((d) => ({Id: d.id, Nombre: d.nombre}))
    ),
    dbComun.Ciudades.bulkAdd(
      resCache.data.ciudades.map((c) => ({
        Id: c.id,
        Nombre: c.nombre,
        DepartamentoId: c.departamentoId,
      }))
    ),
    dbComun.Cups.bulkAdd(
      resCache.data.cups.map((cup) => ({
        Id: cup.id,
        Codigo: cup.codigo,
        Nombre: cup.nombre,
      }))
    ),
    dbComun.Sedes.bulkAdd(
      resCache.data.sedes.map((sede) => ({
        Id: sede.id,
        Nombre: sede.nombre,
        CiudadId: sede.ciudadId,
        Grupo: sede.grupo,
      }))
    ),
    dbComun.Aliados.bulkAdd(
      resCache.data.aliados.map((aliado) => ({
        Id: aliado.id,
        Nombre: aliado.nombre,
        CiudadId: aliado.ciudadId,
      }))
    )
  )

  // Sedes Cendiatra
  promises.push(
    dbComun.SedesCendiatra.bulkAdd(
      resSedesCendiatra.data.map((sede) => ({
        Id: sede.id,
        Nombre: sede.nombre,
        CiudadId: sede.ciudadId,
        Ciudad: sede.ciudad,
        Grupo: sede.grupo,
        Direccion: sede.direccion,
        UrlLogo: sede.urlLogo,
        Telefono: sede.telefono,
        Recepciones: sede.recepciones,
        Consultorios: sede.consultorios,
      }))
    )
  )

  // Valores de referencia
  console.log('Valores de referencia: ', resValores.data)
  const tipos = {}
  for (const item of resValores.data.listaReferencia) {
    if (!tipos[item.tipo]) tipos[item.tipo] = []
    tipos[item.tipo].push({Id: item.identificacion, Nombre: item.nombre})
  }

  Object.entries(tipos).forEach(([tipo, datos]) => {
    const nombreTabla = tablaMap[tipo] ?? tipo
    if (dbComun[nombreTabla]) {
      promises.push(dbComun[nombreTabla].bulkAdd(datos))
    }
  })

  // Localidades
  promises.push(
    dbComun.Localidades.bulkAdd(
      resLocalidades.data.map((l) => ({Id: l.id, Nombre: l.nombre}))
    )
  )

  // CIE10
  promises.push(
    dbComun.Cie11.bulkAdd(
      resCIE10.data.map((c) => ({
        Id: c.id,
        Nombre: c.nombre,
        Cie10: c.cie_10,
      }))
    )
  )

  // Cuentas de ingreso
  promises.push(
    dbComun.CuentaDeIngreso.bulkAdd(
      resCuentas.data.map((cuenta) => ({
        Id: cuenta.id,
        Codigo: cuenta.codigoInterno,
        Nombre: cuenta.nombre,
      }))
    )
  )

  await Promise.all(promises)

  //Simunlando progreso finalizado
  onProgress?.(100)
}
