import React, {useContext, useEffect, useCallback} from 'react'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink} from 'react-router-dom'
import FormularioCargarResultadoAliado from '../../componentes/administradorAliado/FormularioCargarResultadoAliado'
import {useMsal} from '@azure/msal-react'
import {
  FachadaActualizarResultadoAliado,
  FachadaObtenerResultadoAliado,
} from '../../microservicios/Fachada.js'
import {ContextApplication, ContextoCargarDatosAliado} from '../../contexto'
import {useHistory} from 'react-router-dom'
import moment from 'moment/moment'
import {ObtenerEmpresaPorId} from '../../cache/servicios-cache/Empresas.js'
import {
  ObtenerAliadoPorId,
  ObtenerAliadoPorNombreONombreCiudad,
} from '../../cache/servicios-cache/Aliados'
import {ObtenerServicioPorId} from '../../cache/servicios-cache/Servicios'
import {ObtenerPaquetePorId} from '../../microservicios/Servicio'
import {AgregarEmpresasACache} from '../../cache/servicios-cache/Empresas'
import {AgregarTarifariosACache} from '../../cache/servicios-cache/Tarifarios'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import {AgregarPaquetesACache} from '../../cache/servicios-cache/Paquetes'
import SeleccionarAliado from '../../componentes/administradorAliado/SeleccionarAliado.jsx'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {
  CLASIFICACION_SERVICIO,
  TEXTO_VISUAL,
} from '../../constantes/textoVisual'
import {
  ObtenerImprimeCertificadoPorServiciosIds,
  ObtenerTarifarioPorEmpresaId,
} from '../../microservicios'
import {
  ObtenerCie11PorNombreOCodigo,
  ObtenerCiePorId,
} from '../../cache/servicios-cache/Cie11.js'

const EditarResultadosAliado = () => {
  const contextoAplicacion = useContext(ContextApplication)

  const contextoCargarDatosAliado = useContext(ContextoCargarDatosAliado)

  const {accounts} = useMsal()

  const nombreUsuario = accounts[0].idTokenClaims.name

  const history = useHistory()

  const obtenerInformacionInicial = useCallback(async () => {
    await AgregarEmpresasACache()
    await AgregarTarifariosACache()
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
  }, [])

  useEffect(() => {
    obtenerInformacionInicial()
    contextoCargarDatosAliado.setAliadoParaModificar({id: '', editable: true})
  }, [obtenerInformacionInicial])

  const AsginarNombreArchivo = (url) => {
    if (url) {
      let partes = url.split('/')
      let nombre = partes[partes.length - 1]
      return nombre
    }
  }

  const obtenerAliado = async (idAliado) => {
    if (idAliado) {
      await FachadaObtenerResultadoAliado(idAliado)
        .then(async (res) => {
          contextoCargarDatosAliado.setIdResultados(res.data.id)
          contextoCargarDatosAliado.setAliadoId(res.data.aliadoId)
          let aliado = (
            await ObtenerAliadoPorNombreONombreCiudad(
              (
                await ObtenerAliadoPorId(res.data.aliadoId)
              ).Nombre
            )
          )[0]

          const detalleAtencionAliado = !!res.data?.detalleAtencionAliado

          const obtenerNombre = await ObtenerCiePorId(
            res.data?.detalleAtencionAliado?.diagnosticoUnoId
          ).Nombre

          let diagnostico = detalleAtencionAliado
            ? obtenerNombre
            : {diagnosticoUnoId: 0}

          contextoCargarDatosAliado.setInformacionCargarDatosAliado({
            fechaAtencion: moment(res.data.fechaAtencion).format('YYYY-MM-DD'),
            tipoDocumento: res.data.paciente.tipoDocumentoId,
            numeroDocumento: res.data.paciente.numeroDocumento,
            cargo: res.data.paciente.cargo,
            conceptoAptitud: res?.data?.conceptoAptitud,
            apellidos: res.data.paciente.apellidos,
            nombres: res.data.paciente.nombres,
            celular: res.data.paciente.celular,
            fechaNacimiento: moment(res.data.paciente.fechaNacimiento).format(
              'YYYY-MM-DD'
            ),
            sexo: res.data.paciente.sexoId,
            estadoCivil: res.data.paciente.estadoCivilId,
            estrato: res.data.paciente.estratoId,
            escolaridad: res.data.paciente.escolaridadId,
            grupoSanguineo: res.data.paciente.grupoSanguineoId,
            lugarNacimiento: res.data.paciente.lugarNacimiento,
            nacionalidad: res.data.paciente.nacionalidad,
            tipoPoblacion: res.data.paciente.tipoPoblacionId || null,
            detalleAtencionAliado: {
              diagnosticoUnoId: diagnostico,
            },
            cargoEmpresa: res.data.cargo,
            empresaNit: (await ObtenerEmpresaPorId(res.data.empresaId)).Nombre,

            empresaHijaNit: res.data.empresaHijaId
              ? (await ObtenerEmpresaPorId(res.data.empresaHijaId)).Nombre
              : '',
          })
          contextoCargarDatosAliado.setNombreAliado(aliado.filtro)

          if (detalleAtencionAliado) {
            contextoCargarDatosAliado.setDiagnosticoSeleccionado(
              await Promise.resolve(
                ObtenerCiePorId(
                  res.data?.detalleAtencionAliado?.diagnosticoUnoId
                ).then((res) => {
                  return {
                    filtro: res.Nombre,
                    id: res.Id,
                    nombre: res.Nombre,
                  }
                })
              )
            )
          }

          contextoCargarDatosAliado.setEmpresaPadreSeleccionada(
            await Promise.resolve(
              ObtenerEmpresaPorId(res.data.empresaId).then((res) => {
                return {
                  filtro: res.Nit + ' - ' + res.Nombre,
                  id: res.Id,
                  nit: res.Nit,
                  razonSocial: res.Nombre,
                  nombre: res.Nit + ' - ' + res.Nombre,
                }
              })
            )
          )
          if (res.data.empresaHijaId) {
            contextoCargarDatosAliado.setEmpresaHijaSeleccionada(
              await Promise.resolve(
                ObtenerEmpresaPorId(res.data.empresaHijaId).then((res) => {
                  return {
                    filtro: res.Nit + ' - ' + res.Nombre,
                    id: res.Id,
                    nit: res.Nit,
                    razonSocial: res.Nombre,
                  }
                })
              )
            )
          }
          const serviciosIds = res?.data?.servicios?.map(
            (servicio) => servicio?.servicioId
          )
          const validarImprimeCertificado =
            await ObtenerImprimeCertificadoPorServiciosIds(
              [...serviciosIds].join(',')
            )

          const tarifarioEmpresa = (
            await ObtenerTarifarioPorEmpresaId(res.data.empresaId)
          ).data

          let paquetePrecioAsignado = false

          let todosLosServicios = await Promise.all(
            res.data.servicios.map(async (servicio) => {
              let infoServicio = await ObtenerServicioPorId(servicio.servicioId)
              let infopaquete = (await ObtenerPaquetePorId(servicio.paqueteId))
                .data

              const listaDePrecios = infopaquete
                ? tarifarioEmpresa.paquetes.find(
                    (elemento) => elemento.paqueteId === servicio.paqueteId
                  )
                : tarifarioEmpresa.servicios.find(
                    (elemento) => elemento.servicioId === servicio.servicioId
                  )

              const esPaquete = Boolean(infopaquete)

              let precio =
                listaDePrecios?.preciosAliados?.find(
                  ({aliadoId}) => aliadoId === res.data.aliadoId
                )?.precio ?? 0

              if (esPaquete && paquetePrecioAsignado) {
                precio = ''
              } else if (esPaquete) {
                paquetePrecioAsignado = true
              }

              return {
                id: infoServicio.Id,
                filtro: `${infoServicio.CodigoCompleto} - ${infoServicio.Nombre}`,
                codigo: infoServicio.CodigoCompleto,
                nombre: servicio.alias ?? infoServicio.Nombre,
                paqueteAsignado: infopaquete?.nombre ?? '',
                clasificacion: infoServicio.ClasificacionId,
                historiaMedica: servicio.urlHistoriaClinica,
                certificado: servicio.urlCertificado,
                paqueteId: servicio.paqueteId || 0,
                tipo: esPaquete
                  ? CLASIFICACION_SERVICIO.PAQUETE
                  : CLASIFICACION_SERVICIO.SERVICIO,
                nombreHistoria: servicio.urlHistoriaClinica
                  ? AsginarNombreArchivo(servicio.urlHistoriaClinica)
                  : null,
                nombreCertificado: servicio.urlCertificado
                  ? AsginarNombreArchivo(servicio.urlCertificado)
                  : null,
                imprimeCertificado:
                  validarImprimeCertificado?.data?.find(
                    (elemento) => elemento.servicioId === infoServicio?.Id
                  )?.imprimeCertificado ?? false,
                precio,
              }
            })
          )

          let laboratoriosParaAgregar = todosLosServicios.filter(
            (servicio) => servicio.clasificacion === 6
          )

          let serviciosParaAgregar = todosLosServicios.filter(
            (servicio) => servicio.clasificacion !== 6
          )

          contextoCargarDatosAliado.setLaboratorios(laboratoriosParaAgregar)

          contextoCargarDatosAliado.setServicios(serviciosParaAgregar)

          contextoCargarDatosAliado.setUrlLaboratorios({
            url: res.data.urlLaboratorio,
            nombre:
              res.data.urlLaboratorio !== ''
                ? AsginarNombreArchivo(res.data.urlLaboratorio)
                : '',
          })

          contextoCargarDatosAliado.setActualizar(
            !contextoCargarDatosAliado.actualizar
          )
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const generarPayload = async () => {
    let serviciosSeleccinados = contextoCargarDatosAliado.servicios.map(
      (servicio) => {
        return {
          servicioId: servicio.id,
          paqueteId: servicio.paqueteId,
          urlHistoriaClinica: servicio.historiaMedica,
          urlCertificado: servicio.certificado,
        }
      }
    )
    let laboratoriosSeleccionados = contextoCargarDatosAliado.laboratorios.map(
      (laboratorio) => {
        return {
          servicioId: laboratorio.id,
          paqueteId: laboratorio.paqueteId,
          urlHistoriaClinica: laboratorio.historiaMedica,
          urlCertificado: laboratorio.certificado,
        }
      }
    )

    let todosLosServicios = serviciosSeleccinados.concat(
      laboratoriosSeleccionados
    )

    let payloadCargarResultadosAliado = {
      id: contextoCargarDatosAliado.idResultados,
      paciente: {
        tipoDocumentoId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.tipoDocumento,
        numeroDocumento:
          contextoCargarDatosAliado.informacionCargarDatosAliado
            .numeroDocumento,
        cargo: contextoCargarDatosAliado.informacionCargarDatosAliado
          .cargoEmpresa
          ? contextoCargarDatosAliado.informacionCargarDatosAliado.cargoEmpresa.toUpperCase()
          : null,
        nombres:
          contextoCargarDatosAliado.informacionCargarDatosAliado.nombres.toUpperCase(),
        apellidos:
          contextoCargarDatosAliado.informacionCargarDatosAliado.apellidos.toUpperCase(),
        celular: contextoCargarDatosAliado.informacionCargarDatosAliado.celular
          ? contextoCargarDatosAliado.informacionCargarDatosAliado.celular
          : null,
        fechaNacimiento:
          contextoCargarDatosAliado.informacionCargarDatosAliado
            .fechaNacimiento,
        sexoId: contextoCargarDatosAliado.informacionCargarDatosAliado.sexo,
        estadoCivilId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.estadoCivil,
        estratoId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.estrato,
        escolaridadId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.escolaridad,
        grupoSanguineoId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.grupoSanguineo,
        lugarNacimiento:
          contextoCargarDatosAliado.informacionCargarDatosAliado
            .lugarNacimiento,
        nacionalidad:
          contextoCargarDatosAliado?.informacionCargarDatosAliado?.nacionalidad,
        tipoPoblacionId:
          contextoCargarDatosAliado.informacionCargarDatosAliado.tipoPoblacion,
      },
      aliadoId: contextoCargarDatosAliado.aliadoId,
      fechaAtencion:
        contextoCargarDatosAliado.informacionCargarDatosAliado.fechaAtencion,
      empresaId: contextoCargarDatosAliado.empresaPadreSeleccionada.id,
      empresaHijaId: contextoCargarDatosAliado.empresaHijaSeleccionada.id
        ? contextoCargarDatosAliado.empresaHijaSeleccionada.id
        : null,
      urlLaboratorio: contextoCargarDatosAliado.urlLaboratorios.url,
      cargo:
        contextoCargarDatosAliado.informacionCargarDatosAliado.cargoEmpresa,
      creadoPor: nombreUsuario,
      servicios: todosLosServicios,
      conceptoAptitud:
        contextoCargarDatosAliado?.informacionCargarDatosAliado
          ?.conceptoAptitud,
      detalleAtencionAliado: {
        diagnosticoUnoId: contextoCargarDatosAliado.diagnosticoSeleccionado.id,
      },
    }
    return payloadCargarResultadosAliado
  }

  const actualizarResultadosAliado = async () => {
    let payload = await generarPayload()
    FachadaActualizarResultadoAliado(payload)
      .then((res) => {
        if (res.data) {
          contextoAplicacion.setModal({
            abierto: true,
            titulo: '',
            contenido: (
              <>
                <span className="text-cendiatra w-full font-bold mb-1">
                  CÓDIGO ATENCIÓN ALIADO:{' '}
                  {contextoCargarDatosAliado.idResultados}
                </span>
                <span className="text-cendiatra w-full font-bold mt-1">
                  EL RESULTADO HA SIDO ACTUALIZADO
                </span>
                <span className="text-cendiatra w-full font-bold mt-1">
                  EXITOSAMENTE
                </span>
              </>
            ),
            botones: [
              {
                nombre: 'Regresar',
                click: manejarIrModificarResultado,
              },
              {
                nombre: 'Finalizar',
                click: manejarFinalizar,
              },
            ],
          })
          contextoCargarDatosAliado.setAliadoParaModificar({
            id: '',
            editable: true,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarIrModificarResultado = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/editarresultadosaliado')
  }

  const manejarFinalizar = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  return (
    <ContenedorPagina
      tituloPagina={
        contextoCargarDatosAliado.aliadoParaModificar.id === ''
          ? TEXTO_VISUAL.TITULO_ADMINISTRADOR_ALIADO
              .VER_MODIFICAR_RESULTADOS_ALIADO
          : !contextoCargarDatosAliado.deshabilitarIputs
          ? TEXTO_VISUAL.TITULO_ADMINISTRADOR_ALIADO.VER_RESULTADOS_ALIADO
          : TEXTO_VISUAL.TITULO_ADMINISTRADOR_ALIADO.MODIFICAR_RESULTADO_ALIADO
      }
    >
      {contextoCargarDatosAliado.aliadoParaModificar.id === '' ? (
        <SeleccionarAliado funcionClick={obtenerAliado} />
      ) : (
        <FormularioCargarResultadoAliado
          funcionClick={actualizarResultadosAliado}
          aliadoParaModificar={contextoCargarDatosAliado.aliadoParaModificar}
        />
      )}
    </ContenedorPagina>
  )
}

export default EditarResultadosAliado
