import React, {useContext, useEffect, useState, useRef} from 'react'
import {BrowserRouter} from 'react-router-dom'
import Router from './Router.js'
import {ContextApplication} from './contexto/ApplicationContext.js'
import Spinner from './componentes/Spinner.jsx'
import {BarraNavegacion} from './componentes/Navegacion'
import {useMsal} from '@azure/msal-react'
import OfflineWarning from './componentes/OffLineWarning.jsx'
import ModalCendiatra from './componentes/Modales/ModalCendiatra.jsx'
import {PoblarCacheComun} from './cache/servicios-cache/Comun.js'
import {ObtenerTodasLasEps} from './cache/servicios-cache/Eps.js'
import {ObtenerTodasLasArl} from './cache/servicios-cache/Arl.js'
import {ObtenerFondoDePensiones} from './cache/servicios-cache/FondoPension.js'
import {
  ObtenerEstadosCiviles,
  ObtenerEscolaridades,
  ObtenerRazas,
  ObtenerSexos,
  ObtenerZonas,
  ObtenerEstratos,
  ObtenerIndicativos,
  ObtenerFacturarA,
  ObtenerTiposDeExamen,
  ObtenerActividadEconomica,
  ObtenerTiposPoblacion,
} from './cache/servicios-cache/Referencia.js'
import {ObtenerTiposDeAtencion} from './cache/servicios-cache/TipoAtencion'
import {ObtenerGruposSanguineos} from './cache/servicios-cache/GrupoSanguineo'
import Seleccion from './paginas/compartida/Seleccion.jsx'
import {ROLES_APP} from './constantes/roles'
import {ContextoNavegacion} from './contexto'
import {rolRequiereConfiguracionSede} from './utilidades/funcionesComunes.js'
import {AgregarFuentesDeDatosACache} from './cache/servicios-cache/FuentesDeDatos.js'
import {obtenerVersionApp} from './config.js'
import ModalCendiatra2 from './componentes/Modales/ModalCendiatra2.jsx'
import {AgregarCupsSACache} from './cache/servicios-cache/CUPSCache.js'
import {AgregarTarifariosACache} from './cache/servicios-cache/Tarifarios.js'
import {AgregarEmpresasACache} from './cache/servicios-cache/Empresas.js'
import {AgregarServiciosACache} from './cache/servicios-cache/Servicios.js'
import {AgregarPaquetesACache} from './cache/servicios-cache/Paquetes.js'
import {MiniJuegoReaccion} from './MiniGameSpinner.js'

const Home = () => {
  const {accounts} = useMsal()
  const [numeroVersion, setNumeroVersion] = useState('Cargando versión...')
  const rolUsuario = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const totalModulos = 7 // PoblarCacheComun, Empresas, Tarifarios, Servicios, Cups, Paquetes, Fuentes
  const progresoPorModulo = useRef({})
  const ctx = useContext(ContextApplication)
  const contextoNavegacion = useContext(ContextoNavegacion)
  const [moduloActual, setModuloActual] = useState('')

  useEffect(() => {
    obtenerVersionApp()
      .then((version) => {
        setNumeroVersion(`4.4.${version}`)
      })
      .catch((error) => {
        console.error('Error al obtener la versión de la aplicación:', error)
        setNumeroVersion('Error al cargar la versión')
      })
  }, [])

  useEffect(() => {
    isOnline() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isOnline = () => {
    if (navigator.onLine) {
      ctx.setOfflineMode(false)
    } else {
      ctx.setOfflineMode(true)
    }
  }

  window.addEventListener('online', isOnline)
  window.addEventListener('offline', isOnline)

  const actualizarProgresoGlobal = (modulo, porcentajeParcial) => {
    progresoPorModulo.current[modulo] = porcentajeParcial
    setModuloActual(modulo)

    const totalAcumulado = Object.values(progresoPorModulo.current).reduce(
      (acc, val) => acc + val,
      0
    )

    const porcentajeGlobal = totalAcumulado / totalModulos
    ctx.setProgresoCarga(porcentajeGlobal)
  }

  const nombresVisibles = {
    PoblarCacheComun: 'Datos comunes',
    Fuentes: 'Fuentes de datos',
    Empresas: 'Empresas',
    Tarifarios: 'Tarifarios',
    Cups: 'CUPS',
    Paquetes: 'Paquetes',
    Servicios: 'Servicios',
  }

  useEffect(() => {
    const cargarTodo = async () => {
      ctx.setMostrarSpinner(true)

      try {
        // Cargar solo la primera vez
        const esCargaInicial = sessionStorage.getItem('cargaInicial')
        if (!esCargaInicial) {
          // Cargar siempre los datos comunes
          await PoblarCacheComun((progreso) =>
            actualizarProgresoGlobal('PoblarCacheComun', progreso)
          )
        }

        // Actualizar contexto (siempre)
        await Promise.all([
          ObtenerGruposSanguineos().then(ctx.setGruposSanguineos),
          ObtenerEstadosCiviles().then(ctx.setEstadosCiviles),
          ObtenerEscolaridades().then(ctx.setEscolaridades),
          ObtenerRazas().then(ctx.setRazas),
          ObtenerSexos().then(ctx.setSexos),
          ObtenerZonas().then(ctx.setZonas),
          ObtenerEstratos().then(ctx.setEstratos),
          ObtenerIndicativos().then(ctx.setIndicativos),
          ObtenerFacturarA().then(ctx.setFacturarA),
          ObtenerTiposDeExamen().then(ctx.setTipoExamen),
          ObtenerTodasLasEps().then(ctx.setEps),
          ObtenerTodasLasArl().then(ctx.setArl),
          ObtenerTiposDeAtencion().then(ctx.setTipoAtencion),
          ObtenerFondoDePensiones().then(ctx.setFondoPension),
          ObtenerActividadEconomica().then(ctx.setActividadesEconomicas),
          ObtenerTiposPoblacion().then(ctx.setTiposPoblacion),
        ])

        if (!esCargaInicial) {
          sessionStorage.setItem('cargaInicial', 'true')

          await Promise.all([
            AgregarFuentesDeDatosACache((progreso) =>
              actualizarProgresoGlobal('Fuentes', progreso)
            ),
            AgregarEmpresasACache(false, (progreso) =>
              actualizarProgresoGlobal('Empresas', progreso)
            ).then(() =>
              AgregarTarifariosACache(true, (progreso) =>
                actualizarProgresoGlobal('Tarifarios', progreso)
              )
            ),
            AgregarCupsSACache(false, (progreso) =>
              actualizarProgresoGlobal('Cups', progreso)
            ).then(() =>
              Promise.all([
                AgregarPaquetesACache((progreso) =>
                  actualizarProgresoGlobal('Paquetes', progreso)
                ),
                AgregarServiciosACache((progreso) =>
                  actualizarProgresoGlobal('Servicios', progreso)
                ),
              ])
            ),
          ])
        }
      } catch (error) {
        console.error('Error al cargar los datos de la app:', error)
      } finally {
        ctx.setMostrarSpinner(false)
        ctx.setProgresoCarga(0)
      }
    }

    cargarTodo()
  }, []) // Ejecuta solo al montar

  const seleccionarComponente = (rol) => {
    const componentes = [
      {
        roles: [ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO],
        componente: <Seleccion SeleccionSede="Recepción" />,
      },
      {
        roles: [
          ROLES_APP.AUDITOR,
          ROLES_APP.JEFE_SEDE,
          ROLES_APP.AUXILIAR_CONTABLE,
          ROLES_APP.TESORERO,
          ROLES_APP.FACTURADOR,
          ROLES_APP.AUXILIAR_TESORERO,
          ROLES_APP.ADMINISTRADOR_ALIADO,
        ],
        componente: <Seleccion SeleccionSede={''} />,
      },
    ]

    return (
      componentes.find((item) => item.roles.includes(rol))?.componente ?? (
        <Seleccion SeleccionSede="Consultorio" />
      )
    )
  }

  //#region temporally comment out, please DO NOT remove
  // useEffect(() => {

  //     const uploadCreateUserForm = async () => {
  //         if (!ctx.offlineMode) {

  //             const cendiatraDbName = 'cendiatra-db'
  //             let db = await new Dexie(cendiatraDbName).open();
  //             Promise.resolve(db.table('CreateUserForm').toArray()).then((res) => {
  //                 if (res.length > 0) {
  //                     res.forEach(async (item) => {
  //                         await registerNewPatient(item.data).then((response) => {
  //                             if (response.status === 200) {
  //                                 console.log('Se ha registrado el paciente')
  //                             }
  //                         })
  //                             .catch((error) => {
  //                                 console.log(`Ocurió un error: ${error}`)
  //                             })
  //                     })
  //                 }
  //             }).then(() => {
  //                 db.table('CreateUserForm').clear()
  //             })
  //                 .catch((error) => {
  //                     console.log(`Ocurió un error: ${error}`)
  //                 })
  //         }
  //     }

  //     const uploadCreateOrderForm = async () => {
  //         if (!ctx.offlineMode) {

  //             const cendiatraDbName = 'cendiatra-db'
  //             let db = await new Dexie(cendiatraDbName).open();
  //             Promise.resolve(db.table('CreateOrderForm').toArray()).then((res) => {
  //                 if (res.length > 0) {

  //                     console.log(res)
  //                     res.forEach(async (item) => {

  //                         await postNewOrderService(item.data).then((response) => {
  //                             if (response.status === 200) {

  //                                 console.log('Se ha Creado la orden de servicio')

  //                             }
  //                         })
  //                             .catch((error) => {
  //                                 console.log(`Ocurió un error: ${error}`)
  //                             })
  //                     })
  //                 }
  //             }).then(() => {
  //                 db.table('CreateOrderForm').clear()
  //             })
  //                 .catch((error) => {
  //                     console.log(`Ocurió un error: ${error}`)
  //                 })
  //         }
  //     }

  //     const uploadCreateMedicForm = async () => {
  //         if (!ctx.offlineMode) {

  //             const cendiatraDbName = 'cendiatra-db'
  //             let db = await new Dexie(cendiatraDbName).open();
  //             Promise.resolve(db.table('CreateMedicForm').toArray()).then((res) => {
  //                 if (res.length > 0) {

  //                     res.forEach(async (item) => {

  //                         await postNewPatientHistory(item.data).then((response) => {
  //                             if (response.status === 200) {

  //                                 console.log('Se ha Creado el formulario medico')

  //                             }
  //                         })
  //                             .catch((error) => {
  //                                 console.log(`Ocurió un error: ${error}`)
  //                             })
  //                     })
  //                 }
  //             }).then(() => {
  //                 db.table('CreateMedicForm').clear()
  //             })

  //                 .catch((error) => {
  //                     console.log(`Ocurió un error: ${error}`)
  //                 })
  //         }
  //     }
  //     Dexie.exists('cendiatra-db').then((exist) => {
  //         if (exist) {

  //             uploadCreateUserForm()
  //             uploadCreateOrderForm()
  //             uploadCreateMedicForm()
  //         }
  //     });

  // }, [ctx.offlineMode])
  //#endregion
  return (
    <>
      <ModalCendiatra />
      <ModalCendiatra2 />
      <BrowserRouter>
        {ctx.isLoading || ctx.countFetching > 0 || ctx.mostrarSpinner ? (
          <Spinner
            mostrarTexto={ctx.mostrarSpinner}
            message={`Cargando ${
              nombresVisibles[moduloActual] ?? 'datos'
            }... ${ctx.progresoCarga.toFixed(1)}%`}
          />
        ) : null}
        {ctx.offlineMode ? <OfflineWarning /> : null}
        {contextoNavegacion.mostrar && <BarraNavegacion />}
        {!!!ctx.sede.sedeId && rolRequiereConfiguracionSede(rolUsuario) ? (
          seleccionarComponente(rolUsuario)
        ) : (
          <>
            <Router />
            <div className="text-cendiatra fixed text-sm bottom-0 right-0 p-2">
              {' '}
              {numeroVersion}
            </div>
          </>
        )}
      </BrowserRouter>
    </>
  )
}

export default Home
