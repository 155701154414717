import Dexie from 'dexie'
import {PoblarCacheComun} from '../servicios-cache/Comun'
export const dbComun = new Dexie('CendiatraCacheComun')

dbComun.version(1).stores({
  Departamentos: 'Id, Nombre',
  Ciudades: 'Id, Nombre, DepartamentoId',
  Cups: 'Id, Codigo, Nombre',
  Sedes: 'Id , Name, CiudadId',
  Aliados: 'Id, Nombre, CiudadId',
  Version: 'Numero, Fecha',
})

dbComun.version(2).stores({
  Departamentos: 'Id, Nombre',
  Ciudades: 'Id, Nombre, DepartamentoId',
  Cups: 'Id, Codigo, Nombre',
  Sedes: 'Id , Name, CiudadId',
  SedesCendiatra:
    'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
  Aliados: 'Id, Nombre, CiudadId',
  Version: 'Numero, Fecha',
})

dbComun.version(3).stores({
  Departamentos: 'Id, Nombre',
  Ciudades: 'Id, Nombre, DepartamentoId',
  Cups: 'Id, Codigo, Nombre',
  Sedes: 'Id , Name, CiudadId',
  SedesCendiatra:
    'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
  Aliados: 'Id, Nombre, CiudadId',
  Version: 'Numero, Fecha',
  GrupoSanguineo: 'Id, Nombre',
  EstadoCivil: 'Id, Nombre',
  Escolaridad: 'Id, Nombre',
  Raza: 'Id, Nombre',
  MediosPago: 'Id, Nombre',
  Sexo: 'Id, Nombre',
  Zona: 'Id, Nombre',
  Estrato: 'Id, Nombre',
  IndicativoTelefono: 'Id, Nombre',
  FacturarA: 'Id, Nombre',
  TipoExamen: 'Id, Nombre',
  Eps: 'Id, Nombre',
  Arl: 'Id, Nombre',
  TipoAtencion: 'Id, Nombre',
  TipoDocumento: 'Id, Nombre',
  FondoPension: 'Id, Nombre',
})

dbComun
  .version(4)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(5)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(6)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(7)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    ComposicionFamiliar: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(8)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(9)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(10)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(11)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(12)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(13)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(14)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(15)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(16)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(17)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie10: 'Id, Nombre, Cie10',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(18)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie10: 'Id, Nombre, Cie10',
    CuentaDeIngreso: 'Id, Codigo, Nombre',
    TipoCampo: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(19)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie10: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(20)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie10: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(21)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie10: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(22)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(23)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    EstadoFactura: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(24)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    EstadoFactura: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
    CausaNotaCredito: 'Id, Nombre',
    MotivoModificacionAtencion: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(25)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    EstadoFactura: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
    CausaNotaCredito: 'Id, Nombre',
    MotivoModificacionAtencion: 'Id, Nombre',
    CuentaDeIngreso: 'Id, Codigo, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(26)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    EstadoFactura: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
    CausaNotaCredito: 'Id, Nombre',
    MotivoModificacionAtencion: 'Id, Nombre',
    CuentaDeIngreso: 'Id, Codigo, Nombre',
    ActividadEconomica: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(27)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    EstadoFactura: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
    CausaNotaCredito: 'Id, Nombre',
    MotivoModificacionAtencion: 'Id, Nombre',
    CuentaDeIngreso: 'Id, Codigo, Nombre',
    ActividadEconomica: 'Id, Nombre',
    TipoPoblacion: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(28)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    EstadoFactura: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
    CausaNotaCredito: 'Id, Nombre',
    MotivoModificacionAtencion: 'Id, Nombre',
    CuentaDeIngreso: 'Id, Codigo, Nombre',
    ActividadEconomica: 'Id, Nombre',
    TipoPoblacion: 'Id, Nombre',
    TipoRetencion: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

dbComun
  .version(29)
  .stores({
    Departamentos: 'Id, Nombre',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Cups: 'Id, Codigo, Nombre',
    Sedes: 'Id , Name, CiudadId',
    SedesCendiatra:
      'Id , Nombre, CiudadId, Ciudad, Grupo, Direccion, UrlLogo, Telefono, Recepciones, Consultorios',
    Aliados: 'Id, Nombre, CiudadId',
    Version: 'Numero, Fecha',
    GrupoSanguineo: 'Id, Nombre',
    EstadoCivil: 'Id, Nombre',
    Escolaridad: 'Id, Nombre',
    Raza: 'Id, Nombre',
    MediosPago: 'Id, Nombre',
    Sexo: 'Id, Nombre',
    Zona: 'Id, Nombre',
    Estrato: 'Id, Nombre',
    IndicativoTelefono: 'Id, Nombre',
    FacturarA: 'Id, Nombre',
    TipoExamen: 'Id, Nombre',
    Eps: 'Id, Nombre',
    Arl: 'Id, Nombre',
    TipoAtencion: 'Id, Nombre',
    TipoDocumento: 'Id, Nombre',
    FondoPension: 'Id, Nombre',
    Parentesco: 'Id, Nombre',
    Fondo1: 'Id, Nombre',
    Localidades: 'Id, Nombre',
    TipoTurno: 'Id, Nombre',
    EstadoTurno: 'Id, Nombre',
    EstadoServicioAtencion: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    EstadoFactura: 'Id, Nombre',
    TipoFacturacion: 'Id, Nombre',
    TurnoTrabajo: 'Id, Nombre',
    RiesgosFisicos: 'Id, Nombre',
    RiesgosBiologicos: 'Id, Nombre',
    RiesgosErgonomicos: 'Id, Nombre',
    RiesgosLocativos: 'Id, Nombre',
    RiesgosNaturales: 'Id, Nombre',
    RiesgosSeguridad: 'Id, Nombre',
    OtrosRiesgos: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    Antecedentes: 'Id, Nombre',
    AntecedentesToxicologicos: 'Id, Nombre',
    OrganosSistema: 'Id, Nombre',
    EstadosOrganoSistema: 'Id, Nombre',
    EstadosTunelCarpiano: 'Id, Nombre',
    Recomendaciones: 'Id, Nombre',
    VigilanciasEpidemiologicas: 'Id, Nombre',
    Restricciones: 'Id, Nombre',
    ClasificacionExamenFisico: 'Id, Nombre',
    Cie11: 'Id, Nombre, Cie10',
    TipoCampo: 'Id, Nombre',
    ClasificacionServicio: 'Id, Nombre',
    DuracionCita: 'Id, Nombre',
    GrupoLaboratorio: 'Id, Nombre',
    ObservacionesPreparacion: 'Id, Nombre',
    Estructura: 'Id, Nombre',
    CausaNotaCredito: 'Id, Nombre',
    MotivoModificacionAtencion: 'Id, Nombre',
    CuentaDeIngreso: 'Id, Codigo, Nombre',
    ActividadEconomica: 'Id, Nombre',
    TipoPoblacion: 'Id, Nombre',
    TipoRetencion: 'Id, Nombre',
    TipoFactura: 'Id, Nombre',
    EstadoRips: 'Id, Nombre',
  })
  .upgrade(async (trans) => {
    return PoblarCacheComun(true)
  })

// ..
// Agregar versionamiento en esta sección si se desea hacer cambios en el esquema.
// Todas las versiones deben mantenerse como historial y para dar soporte a todos los clientes y los upgrades que sean necesarios desde cualquier versión vieja.
//
// Ejemplo:
//
// dbComun.version(2).stores({
//     Departamentos: "Id, Nombre",
//     Ciudades: "Id, Nombre, DepartamentoId",
//     Cups: "Id, Codigo, Nombre",
//     Sedes: "Id , Name, CiudadId",
//     Aliados: "Id, Nombre, CiudadId",
//     Version: "Numero, Fecha",
//     NuevaTabla: "Columna1, Columna2"
// }).upgrade(trans => {
//    return PoblarCacheComun(true);
// });
//
// El método PoblarCacheComun también debería ser modificado para agregar la información de la nueva tabla
// ..

dbComun.open().catch(function (err) {
  console.error('Fallo al abrir el cache común: ' + (err.stack || err))
})
