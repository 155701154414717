import React, {useEffect, useContext} from 'react'
import FilaPrecios from './filas/FilaPrecios'
import {
  ValidarOrdenesPorTarifarioIdYServicioId,
  ValidarAtencionesPorTarifarioIdYServicioId,
} from '../../microservicios'
import {ContextApplication} from '../../contexto'

export default function TablaServicios({contexto, esPortafolio = false}) {
  const contextoApp = useContext(ContextApplication)

  useEffect(() => {
    contexto.listaServicios.forEach((servicio, index, listaServicios) => {
      listaServicios[index].precios_sedes = contexto.sedesSeleccionadas
        .filter((x) => x.Activo)
        .map((sede) => ({
          id: 'servicio:' + sede.Id,
          nombre: sede.Nombre,
          grupo: sede.Grupo,
          precio:
            servicio.precios_sedes.find((x) => x.id == 'servicio:' + sede.Id) ==
            undefined
              ? 0
              : servicio.precios_sedes.find(
                  (x) => x.id == 'servicio:' + sede.Id
                ).precio,
          idPrecio:
            servicio.precios_sedes.find((x) => x.id == 'servicio:' + sede.Id) ==
            undefined
              ? 0
              : servicio.precios_sedes.find(
                  (x) => x.id == 'servicio:' + sede.Id
                ).idPrecio,
          tipo: 'servicio',
          idGeneral: servicio.servicio_id,
          sedeId: sede.Id,
          nuevoPrecio:
            servicio.precios_sedes.find((x) => x.id == 'servicio:' + sede.Id) ==
            undefined
              ? 0
              : servicio.precios_sedes.find(
                  (x) => x.id == 'servicio:' + sede.Id
                ).nuevoPrecio,
        }))
    })
  }, [contexto.sedesSeleccionadas])

  useEffect(() => {
    contexto.listaServicios.forEach((servicio, index, listaServicios) => {
      listaServicios[index].precios_aliados = contexto.aliadosSeleccionados
        .filter((x) => x.Activo)
        .map((aliado) => ({
          id: 'aliado:' + aliado.Id,
          nombre: aliado.Nombre,
          grupo: 'ALIADOS',
          precio:
            servicio.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : servicio.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).precio,
          idPrecio:
            servicio.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : servicio.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).idPrecio,
          tipo: 'servicio',
          idGeneral: servicio.servicio_id,
          aliadoId: aliado.Id,
          nuevoPrecio:
            servicio.precios_aliados.find(
              (x) => x.id == 'aliado:' + aliado.Id
            ) == undefined
              ? 0
              : servicio.precios_aliados.find(
                  (x) => x.id == 'aliado:' + aliado.Id
                ).nuevoPrecio,
        }))
    })
  }, [contexto.aliadosSeleccionados])

  const manejarCerrarModalEliminar = () => {
    contextoApp.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap mx-20">
          <span className="w-full text-cendiatra text-3xl font-bold">
            NO SE APLICARÁN LOS CAMBIOS
          </span>
          <span className="w-full text-cendiatra text-lg mt-16 mb-10 normal-case">
            No se continúa con el proceso y no se actualiza el tarifario.
          </span>
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: cerrarModal,
        },
      ],
      clasesAdicioneles:
        'w-5/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5 rounded-xl',
    })
  }

  const cerrarModal = () => {
    contextoApp.setModal({abierto: false, botones: []})
  }
  const confirmarEliminarServicio = (servicio) => {
    if (Boolean(contexto.portafolioSeleccionadoId)) {
      contexto.setSeguroDeCambio(true)
      return
    }
    contextoApp.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap mx-20">
          <span className="w-full text-cendiatra text-3xl font-bold">
            ¿ESTA SEGURO DE QUITAR EL SERVICIO Y/O PAQUETE?
          </span>
          <span className="w-full text-cendiatra text-lg mt-16 ">
            Nombre del servicio : {servicio.nombre}
          </span>
          {servicio.alias ? (
            <span className="w-full text-cendiatra text-lg mt-1 mb-10">
              Alias : {servicio.alias}
            </span>
          ) : null}
        </div>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => {
            validarServicioTieneAtenciones(servicio.servicio_id)
            cerrarModal()
          },
        },
        {
          nombre: 'No',
          click: cerrarModal,
        },
      ],
      clasesAdicioneles:
        'w-5/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5 rounded-xl',
    })
  }

  const validarServicioTieneAtenciones = async (idServicio) => {
    let eliminarLocalmente = true

    const id = contexto.portafolioId || contexto.idTarifario

    const {data: ordenConRegistros} =
      await ValidarOrdenesPorTarifarioIdYServicioId(id, idServicio)

    if (ordenConRegistros) {
      eliminarLocalmente = false

      contextoApp.setModal({
        abierto: true,
        titulo: '',
        contenido: (
          <div className="w-full flex justify-center items-center flex-wrap mx  -5">
            <span className="w-full text-cendiatra-rojo-1 text-3xl font-bold">
              EXISTEN ÓRDENES DE SERVICIO PENDIENTES DE ATENCIÓN
            </span>
            <span className="w-full text-cendiatra-rojo-1 text-3xl font-bold">
              PARA ESTE TARIFARIO
            </span>
            <span className="w-full text-cendiatra text-lg mt-16 mb-10 font-bold">
              ¿DESEA APLICAR LOS CAMBIOS?
            </span>
          </div>
        ),
        botones: [
          {
            nombre: 'Si',
            click: () => {
              eliminarServicioPorId(idServicio)
              cerrarModal()
            },
          },
          {
            nombre: 'No',
            click: manejarCerrarModalEliminar,
          },
        ],
        clasesAdicioneles:
          'w-4/12 pt-0 pb-0 pr-0 pl-0 bg-cendiatra-gris-5 rounded-xl',
      })
    }

    if (eliminarLocalmente) {
      eliminarServicioPorId(idServicio)
    }
  }
  const eliminarServicioPorId = (idServicio) => {
    var filtro = contexto.listaServicios.filter((servicio) => {
      return servicio.servicio_id !== idServicio
    })

    contexto.setListaServicios(filtro)
  }

  const validarElementoNuevo = (id) => {
    return Boolean(
      contexto.serviciosIniciales.find((elemento) => elemento === id)
    )
  }
  const validarColumnaAEditar = (paqueteId, datos) => {
    const sedeOAliadoNuevo = validacionGeneral(datos)
    if (sedeOAliadoNuevo) {
      const nombrePropiedad =
        contexto.modoEdicion && validarElementoNuevo(paqueteId)
          ? 'nuevoPrecio'
          : 'precio'
      return nombrePropiedad
    }
    return 'precio'
  }

  const validacionGeneral = (data) => {
    const resultado = Boolean(
      contexto?.[
        data?.grupo === 'ALIADOS' ? 'aliadosIniciales' : 'sedesIniciales'
      ].find((x) =>
        data?.grupo === 'ALIADOS'
          ? x === data?.aliadoId ?? 0
          : x === data?.sedeId ?? 0
      )
    )
    return resultado
  }

  const actualizarPrecios = (idSede, value, idFila) => {
    contexto.setListaServicios(
      contexto.listaServicios.map((servicio) => {
        if (servicio.servicio_id === idFila) {
          return {
            ...servicio,
            precios_sedes: servicio.precios_sedes.map((elementoPrecio) => {
              if (elementoPrecio.id === idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(servicio.servicio_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
            precios_aliados: servicio.precios_aliados.map((elementoPrecio) => {
              if (elementoPrecio.id === idSede) {
                return {
                  ...elementoPrecio,
                  [validarColumnaAEditar(servicio.servicio_id, elementoPrecio)]:
                    value,
                }
              }
              return elementoPrecio
            }),
          }
        }
        return servicio
      })
    )
  }

  const validarCampoAlias = (caracter) => {
    let Validacion = new RegExp(
      /[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,\[/\]/)(+-//\s*]/,
      ''
    )

    return !Validacion.test(caracter)
  }

  const actualizarAlias = (idFila, valor) => {
    if (validarCampoAlias(valor.slice(-1) || valor === ' ')) {
      window.alert('Se aceptan letras y estos caracteres ( ) - . , [ ] / *')
      return
    }
    contexto.setListaServicios(
      contexto.listaServicios.map((servicio) => {
        if (servicio.servicio_id == idFila) {
          return {
            ...servicio,
            alias: valor,
          }
        }
        return servicio
      })
    )
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center">
      {contexto.listaServicios.map((servicio, posicion) => (
        <FilaPrecios
          contexto={contexto}
          idFila={servicio.servicio_id}
          nombre={servicio.nombre}
          alias={servicio.alias}
          precios={servicio.precios_sedes.concat(servicio.precios_aliados)}
          actualizarPrecios={actualizarPrecios}
          eliminarFila={() => confirmarEliminarServicio(servicio)}
          actualizarAlias={actualizarAlias}
          posicion={posicion}
          key={posicion}
          tipoElemento={'Servicio'}
          esPortafolio={esPortafolio}
        />
      ))}
    </div>
  )
}
