import {useContext, useEffect, useState} from 'react'
import BotonConImagen from '../../../BotonConImagen'
import {Boton, BotonSimple} from '../../../Botones'
import {ContextoRegistro} from '../../../../contexto'
import imprimirVerde from '../../../../imagenes/iconoImprimirVerde.svg'
import imprimirGris from '../../../../imagenes/iconoImprimirGris.svg'
import {useForm} from 'react-hook-form'
import {CLASES_DE_FACTURACION} from '../../../../constantes'
import {ListaDesplegable} from '../../../inputs'
import clsx from 'clsx'
import {ObtenerTiposFactura} from '../../../../cache/servicios-cache'

const Botones = ({
  clickGuardar,
  clickComprobante,
  clickComprobanteCredito,
  imprimirComprobante,
  emitirFactura,
  imprimirFacturaBoton,
  deshabilitarComprobante = false,
  tipoFacturacion,
  establecerTipoFacturacion,
}) => {
  const contextoRegistro = useContext(ContextoRegistro)

  const {
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  })
  const [tiposFactura, setTiposFactura] = useState([])

  const manejarTioFacturacionSeleccionado = (tipoFacturacion) => {
    establecerTipoFacturacion(tipoFacturacion)
  }
  useEffect(() => {
    const obtenerDatosLista = async () => {
      const tiposFactura = await ObtenerTiposFactura()
      setTiposFactura(tiposFactura)
    }
    obtenerDatosLista()
  }, [])

  return (
    <>
      <div className="w-full flex justify-center items-center mb-2">
        <Boton
          titulo={'Guardar'}
          funcionCLick={clickGuardar}
          habilitado={
            Object.keys(errors).length === 0 &&
            !contextoRegistro.atencionCreada &&
            !!contextoRegistro.foto &&
            !!contextoRegistro.firma &&
            !!contextoRegistro.examenesSeleccionados.length
          }
          colorPorDefecto={'bg-grayColor'}
          colorActivo={'bg-btnBg'}
          tipo={'submit'}
        />
      </div>
      <div className="w-full flex justify-center items-center">
        {contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
          CLASES_DE_FACTURACION.CREDITO ||
        !contextoRegistro.atencionCreada ||
        !contextoRegistro.validacionBoton ? null : (
          <ListaDesplegable
            estilosContenedor={'w-2/12'}
            estilosLista={
              'appearance-none rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            titulo={'Tipo de factura'}
            opciones={tiposFactura}
            onChange={(e) => manejarTioFacturacionSeleccionado(e.target.value)}
            valor={null}
            deshabilitado={
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBoton
            }
          />
        )}
      </div>
      <div className="w-full flex justify-center items-center my-16 ">
        <div className="w-3/12 flex items-center justify-center flex-wrap">
          <BotonSimple
            texto={'Emitir comprobante de atención'}
            funcion={clickComprobante}
            estilosBoton={clsx('w-48 h-12 text-white rounded-lg m-1 mb-5', {
              'bg-cendiatra-gris-4':
                !contextoRegistro.atencionCreada ||
                !contextoRegistro.validacionBoton ||
                contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                  CLASES_DE_FACTURACION.CREDITO ||
                deshabilitarComprobante ||
                tipoFacturacion,
              'bg-btnBg bg-center bg-cover bg-no-repeat text-14px': !(
                !contextoRegistro.atencionCreada ||
                !contextoRegistro.validacionBoton ||
                contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                  CLASES_DE_FACTURACION.CREDITO ||
                deshabilitarComprobante ||
                tipoFacturacion
              ),
            })}
            deshabilitado={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBoton ||
              deshabilitarComprobante ||
              tipoFacturacion
            }
            tipoDeBoton={'button'}
          />
          <BotonConImagen
            imagen={
              contextoRegistro.validacionBoton ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante ||
              tipoFacturacion
                ? imprimirGris
                : imprimirVerde
            }
            estilosContenedor="w-full"
            estilosImagen={'w-8 rounded-full -mt-1'}
            textoAlternativo={'botonImprimir'}
            funcionAEjecutar={imprimirComprobante}
            desabilitado={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante ||
              tipoFacturacion
            }
          />
        </div>

        <div className="w-3/12 flex items-center justify-center flex-wrap">
          <BotonSimple
            texto={'Emitir factura'}
            funcion={emitirFactura}
            estilosBoton={clsx('w-48 h-12 text-white rounded-lg m-1 mb-5', {
              'bg-cendiatra-gris-4':
                !contextoRegistro.atencionCreada ||
                !contextoRegistro.validacionBoton ||
                contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                  CLASES_DE_FACTURACION.CREDITO ||
                !tipoFacturacion,
              'bg-btnBg bg-center bg-cover bg-no-repeat text-14px': !(
                !contextoRegistro.atencionCreada ||
                !contextoRegistro.validacionBoton ||
                contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                  CLASES_DE_FACTURACION.CREDITO ||
                !tipoFacturacion
              ),
            })}
            deshabilitado={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBoton ||
              !tipoFacturacion
            }
            tipoDeBoton={'button'}
          />
          <BotonConImagen
            imagen={
              contextoRegistro.validacionBoton ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !contextoRegistro.emitioFactura ||
              !tipoFacturacion
                ? imprimirGris
                : imprimirVerde
            }
            estilosContenedor="w-full"
            estilosImagen={'w-8 rounded-full -mt-1'}
            textoAlternativo={'botonImprimir'}
            funcionAEjecutar={imprimirFacturaBoton}
            desabilitado={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ===
                CLASES_DE_FACTURACION.CREDITO ||
              !contextoRegistro.emitioFactura ||
              !tipoFacturacion
            }
          />
        </div>
        <div className="w-3/12 flex items-center justify-center flex-wrap">
          <BotonSimple
            texto={'Soporte Atención De Crédito'}
            funcion={clickComprobanteCredito}
            estilosBoton={clsx('w-48 h-12 text-white rounded-lg m-1 mb-5', {
              'bg-cendiatra-gris-4':
                !contextoRegistro.atencionCreada ||
                !contextoRegistro.validacionBotonCredito ||
                contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !==
                  CLASES_DE_FACTURACION.CREDITO ||
                deshabilitarComprobante,
              'bg-btnBg bg-center bg-cover bg-no-repeat text-14px': !(
                !contextoRegistro.atencionCreada ||
                !contextoRegistro.validacionBotonCredito ||
                contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !==
                  CLASES_DE_FACTURACION.CREDITO ||
                deshabilitarComprobante
              ),
            })}
            deshabilitado={
              !contextoRegistro.atencionCreada ||
              !contextoRegistro.validacionBotonCredito ||
              deshabilitarComprobante
            }
            tipoDeBoton={'button'}
          />
          <BotonConImagen
            imagen={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !==
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante
                ? imprimirGris
                : imprimirVerde
            }
            estilosContenedor="w-full"
            estilosImagen={'w-8 rounded-full -mt-1'}
            textoAlternativo={'botonImprimir'}
            funcionAEjecutar={imprimirComprobante}
            desabilitado={
              !contextoRegistro.atencionCreada ||
              contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() !==
                CLASES_DE_FACTURACION.CREDITO ||
              !Boolean(contextoRegistro.urlComprobante) ||
              deshabilitarComprobante
            }
          />
        </div>
      </div>
    </>
  )
}

export default Botones
