import {ObtenerEmpresasDB} from '../../microservicios/Empresa'
import {dbComercial} from '../bases-datos/dbComercial'
import EmpresaWorker from './Empresas.worker.js'

export const AgregarEmpresasACache = async (sinfecha = false, onProgress) => {
  await dbComercial.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbComercial.table('FechasActualizacion').get({Tabla: 'Empresas'})
    )?.FechaUltimaActualizacion

    const res = await ObtenerEmpresasDB(
      sinfecha ? undefined : fechaUltimaActualizacion
    )

    if (!Array.isArray(res.data)) return

    try {
      const worker = new EmpresaWorker()

      worker.addEventListener('message', (e) => {
        if (e.data.type === 'progreso') {
          onProgress?.(parseFloat(e.data.porcentaje))
        } else if (e.data.type === 'completo') {
          console.log('Carga de empresas finalizada')
        } else if (e.data.type === 'error') {
          console.error('Error desde el Worker:', e.data.mensaje)
        }
      })

      await worker.cargarEmpresas(res.data, sinfecha)
    } catch (err) {
      console.error('Error desde el worker:', err)
    }
  })
}

export const ObtenerEmpresasPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComercial
      .table('Empresas')
      .filter(
        ({EmpresaPadreId}) => EmpresaPadreId == null || EmpresaPadreId == 0
      )
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerEmpresasPorIds = async (ids = []) => {
  return Promise.resolve(
    dbComercial
      .table('Empresas')
      .filter(({Id}) => ids.includes(Id))
      .toArray()
  )
}

export const ObtenerEmpresaUnicaPorNombre = async (nombre) => {
  return Promise.resolve(dbComercial.table('Empresas').get({Nombre: nombre}))
}

export const ObtenerEmpresasYEmpresasHijasPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComercial
      .table('Empresas')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1
      )
      .toArray()
  )
}

export const ObtenerEmpresaPorId = async (id) => {
  return Promise.resolve(dbComercial.table('Empresas').get({Id: id}))
}

export const ObtenerEmpresas = async () => {
  return Promise.resolve(
    dbComercial
      .table('Empresas')
      .filter(
        ({EmpresaPadreId}) => EmpresaPadreId == null || EmpresaPadreId == 0
      )
      .toArray()
  )
}

export const ObtenerEmpresasPorNitONombre = async (nit, nombre) => {
  let empresas = dbComercial
    .table('Empresas')
    .filter(({EmpresaPadreId}) => EmpresaPadreId == null || EmpresaPadreId == 0)
  if (nit && nit != '') {
    empresas = empresas.filter(
      ({Nit}) => Nit.toUpperCase().indexOf(nit.toUpperCase()) > -1
    )
  }
  if (nombre && nombre != '') {
    empresas = empresas.filter(
      ({Nombre}) => Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1
    )
  }
  return Promise.resolve(empresas.toArray())
}

export const ObtenerEmpresasPorNitONombreFiltro = async (valor) => {
  let empresas = await dbComercial
    .table('Empresas')
    .filter(({EmpresaPadreId}) => EmpresaPadreId == null || EmpresaPadreId == 0)
    .toArray()
  let empresasPorNit = empresas
    .filter(({Nit}) => Nit.toUpperCase().indexOf(valor?.toUpperCase()) > -1)
    .map((empresa) => ({
      id: empresa.Id,
      filtro: empresa.Nit + ' - ' + empresa.Nombre,
      nit: empresa.Nit,
      razonSocial: empresa.Nombre,
    }))

  let empresasPorNombre = empresas
    .filter(
      ({Nombre}) => Nombre.toUpperCase().indexOf(valor.toUpperCase()) > -1
    )
    .map((empresa) => ({
      id: empresa.Id,
      filtro: empresa.Nit + ' - ' + empresa.Nombre,
      nit: empresa.Nit,
      razonSocial: empresa.Nombre,
    }))
  return Promise.resolve(empresasPorNit.concat(empresasPorNombre))
}

export const ObtenerEmpresasHijasPorNitONombreFiltro = async (
  idEmpresaPadre,
  valor
) => {
  let empresas = await dbComercial
    .table('Empresas')
    .filter(
      ({EmpresaPadreId, EstaActiva}) =>
        EmpresaPadreId === +idEmpresaPadre && EstaActiva
    )
    .toArray()
  let empresasPorNit = empresas
    .filter(({Nit}) => Nit.toUpperCase().indexOf(valor?.toUpperCase()) > -1)
    .map((empresa) => ({
      id: empresa.Id,
      filtro: empresa.Nit + ' - ' + empresa.Nombre,
      nit: empresa.Nit,
      razonSocial: empresa.Nombre,
    }))

  let empresasPorNombre = empresas
    .filter(
      ({Nombre}) => Nombre.toUpperCase().indexOf(valor.toUpperCase()) > -1
    )
    .map((empresa) => ({
      id: empresa.Id,
      filtro: empresa.Nit + ' - ' + empresa.Nombre,
      nit: empresa.Nit,
      razonSocial: empresa.Nombre,
    }))
  return Promise.resolve(empresasPorNit.concat(empresasPorNombre))
}

export const ObtenerEmpresasHijasPorIdPadre = async (
  idPadre,
  soloActivas = false
) => {
  let empresas = []
  let empresasPadre = await ObtenerEmpresaPorId(idPadre)
  if (soloActivas) {
    empresas = await dbComercial
      .table('Empresas')
      .filter(
        ({EmpresaPadreId, EstaActiva}) =>
          EmpresaPadreId === +idPadre && EstaActiva
      )
      .toArray()
  } else {
    empresas = await dbComercial
      .table('Empresas')
      .filter(({EmpresaPadreId}) => EmpresaPadreId === +idPadre)
      .toArray()
  }
  const empresasHijas = empresas.map((empresaHija) => ({
    id: empresaHija.Id,
    nit: empresaHija.Nit,
    razonSocial: empresaHija.Nombre,
    empresaPadre: empresasPadre?.Nombre ?? '',
    activa: empresaHija.EstaActiva ? 'ACTIVA' : 'INACTIVA',
    observaciones: empresaHija.Observaciones,
  }))
  return Promise.resolve(empresasHijas)
}

export const ObtenerEmpresasHijasPorCodigoInternoONombre = async (
  idPadre,
  codigoInterno,
  nombre
) => {
  let empresasPadre = await dbComercial.table('Empresas').toArray()

  let empresas = dbComercial
    .table('Empresas')
    .filter(({EmpresaPadreId}) => EmpresaPadreId == idPadre)

  if (codigoInterno && codigoInterno != '') {
    empresas = empresas.filter(
      ({CodigoInterno}) =>
        CodigoInterno.toUpperCase().indexOf(codigoInterno.toUpperCase()) > -1
    )
  }
  if (nombre && nombre != '') {
    empresas = empresas.filter(
      ({Nombre}) => Nombre.toUpperCase().indexOf(nombre.toUpperCase()) > -1
    )
  }

  let empresasFiltradas = (await empresas.toArray()).map((empresaHija) => ({
    id: empresaHija.Id,
    nit: empresaHija.Nit,
    razonSocial: empresaHija.Nombre,
    empresaPadre: empresasPadre.find((x) => x.Id == empresaHija.EmpresaPadreId)
      .Nombre,
    activa: empresaHija.EstaActiva == true ? 'ACTIVA' : 'INACTIVA',
  }))

  return Promise.resolve(empresasFiltradas)
}

export const ObtenerEmpresasPorNit = async (nit) => {
  let empresas = dbComercial
    .table('Empresas')
    .filter(({EmpresaPadreId}) => EmpresaPadreId == null || EmpresaPadreId == 0)
  if (nit && nit != '') {
    empresas = empresas.filter(
      ({Nit}) => Nit.toUpperCase().indexOf(nit.toUpperCase()) > -1
    )
  }
  return Promise.resolve(empresas.toArray())
}

export const ObtenerEmpresaPorNit = async (nit) => {
  return Promise.resolve(dbComercial.table('Empresas').get({Nit: nit}))
}

export const ObtenerEmpresaPorCorreo = async (correo) => {
  return Promise.resolve(dbComercial.table('Empresas').get({Correo: correo}))
}
