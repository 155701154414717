
				var addMethods = require("../../../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["cargarCups"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "worker-Cups.worker.6fff91a04019b83a133b.js.worker.js", { name: "worker-[name].[hash].js.worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "worker-Cups.worker.6fff91a04019b83a133b.js.worker.js");
					addMethods(w, methods)
					
					return w
				}
			