import React, {useState, useEffect} from 'react'

// Lista ampliada de frases con datos relevantes
const frasesBase = [
  'Cendiatra cuenta con más de 28 sedes en toda Colombia, brindando cobertura nacional.',
  'La sede principal de Cendiatra se encuentra en Bogotá, ofreciendo servicios especializados en salud ocupacional.',
  'En Colombia, durante 2022, se diagnosticaron y calificaron cerca de 32,000 casos de enfermedades laborales.',
  'Cendiatra tiene más de 25 años de trayectoria en el mercado de la salud en Colombia, especialmente en el área de la Seguridad y Salud en el Trabajo.',
  'En 2022, Colombia presentó un promedio de 1,488 accidentes laborales por día.',
  'Cendiatra ofrece exámenes médicos ocupacionales periódicos, obligatorios según el Decreto 1072 de 2015.',
  'En 2023, se reportaron 2,184 consultas por problemas de salud mental relacionados con el trabajo en Colombia.',
  'Cendiatra opera en múltiples ciudades de Colombia, incluyendo Bogotá, Medellín y Cali.',
  'El Dr. Enrique Castellanos fundó Cendiatra para transformar la salud ocupacional en Colombia.',
  'Más de 50 profesionales médicos hacen parte del equipo de Cendiatra.',
  'Cendiatra es líder en la prestación de servicios de salud ocupacional en Bogotá.',
  'En 2024, se registraron 796 muertes en accidentes laborales en España, aumentando un 11% respecto al año anterior.',
  'Cendiatra ofrece soluciones integrales para empresas de todos los sectores en Colombia.',
  'En 2022, las cifras de trabajadores que tuvieron una calificación de pérdida de capacidad laboral igual o mayor al 50% evidenciaron una disminución sostenida.',
  'Cendiatra brinda asesoramiento personalizado a cada uno de sus clientes en sus múltiples sucursales.',
  'La red de sedes de Cendiatra es un testimonio de su compromiso con la excelencia y su deseo de estar cerca de sus clientes.',
  'En 2024, las cifras relacionadas con los accidentes laborales en Colombia resaltan la necesidad de implementar medidas preventivas.',
  'Cendiatra ayuda a las empresas a cumplir con la normatividad vigente para proteger la integridad de sus empleados.',
  'En 2022, Colombia adoptó el manual de procedimientos del programa de rehabilitación integral para la reincorporación laboral y ocupacional.',
  'Cendiatra está comprometida con brindar la mejor atención a nivel nacional a sus clientes.',
]

// Función para mezclar aleatoriamente las frases
const mezclarFrases = (frases) => {
  let frasesMezcladas = [...frases]
  for (let i = frasesMezcladas.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[frasesMezcladas[i], frasesMezcladas[j]] = [
      frasesMezcladas[j],
      frasesMezcladas[i],
    ]
  }
  return frasesMezcladas
}

const DatosCuriosos = ({progreso, mensaje}) => {
  const [index, setIndex] = useState(0)
  const [frases, setFrases] = useState([])

  useEffect(() => {
    setFrases(mezclarFrases(frasesBase))
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % frasesBase.length)
    }, 6000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex flex-col items-center justify-center mt-6 space-y-4 text-white">
      <img
        src="https://prodcendiatra.blob.core.windows.net/banner-portalcliente/GATO.gif"
        alt="Doctor"
        className="w-24 h-24 mx-auto mb-4"
      />
      {/* <p className="w-full text-center text-4xl text-white font-extrabold">
        {frases[index]}
      </p> */}
      <div className="w-64 h-3 bg-white/20 rounded-full overflow-hidden">
        <div
          className="h-full bg-green-400 transition-all duration-500 ease-out"
          style={{width: `${progreso.toFixed(1)}%`}}
        ></div>
      </div>
      <p className="max-w-md text-center text-base transition-opacity duration-500 ease-in-out">
        {mensaje}
      </p>
    </div>
  )
}

export default DatosCuriosos
